import {createGlobalStyle, css} from 'styled-components';
import {hover, text, title} from './globalStyleVars';

function createCSS() {
    let styles = '';

    for (let i = 2; i < 20; i += 1) {
        styles += `
        .anim-active.fade-up:nth-child(${i}) {
          transition-delay: ${i * .12}s;
        }
     `
    }

    for (let a = 2; a < 100; a += 1) {
        styles += `
        .anim-active.fade-right span:nth-child(${a}) {
          transition-delay: ${a * .03}s;
        }
     `
    }
    return css`${styles}`;
}

export default createGlobalStyle`

  ${createCSS()}
  #root {
    overflow: hidden !important;
  }

  @font-face {
    font-family: 'Suisse Intl';
    src: url('/fonts/SuisseIntl-Black.woff2') format('woff2'),
    url('/fonts/SuisseIntl-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: block;
  }

  @font-face {
    font-family: 'Suisse Intl';
    src: url('/fonts/SuisseIntl-Bold.woff2') format('woff2'),
    url('/fonts/SuisseIntl-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: block;
  }

  @font-face {
    font-family: 'Suisse Intl';
    src: url('/fonts/SuisseIntl-Light.woff2') format('woff2'),
    url('/fonts/SuisseIntl-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: block;
  }

  @font-face {
    font-family: 'Suisse Intl';
    src: url('/fonts/SuisseIntl-Medium.woff2') format('woff2'),
    url('/fonts/SuisseIntl-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: block;
  }

  @font-face {
    font-family: 'Suisse Intl';
    src: url('/fonts/SuisseIntl-Book.woff2') format('woff2'),
    url('/fonts/SuisseIntl-Book.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: block;
  }

  @font-face {
    font-family: 'Suisse Intl';
    src: url('/fonts/SuisseIntl-Semibold.woff2') format('woff2'),
    url('/fonts/SuisseIntl-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: block;
  }

  @font-face {
    font-family: 'Suisse Intl';
    src: url('/fonts/SuisseIntl-Regular.woff2') format('woff2'),
    url('/fonts/SuisseIntl-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }

  @font-face {
    font-family: 'Suisse Intl';
    src: url('/fonts/SuisseIntl-Thin.woff2') format('woff2'),
    url('/fonts/SuisseIntl-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: block;
  }


  body {
    font-family: "Suisse Intl", Helvetica, freesans, sans-serif !important;
    font-style: normal;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    //background-color: #f9f9f9;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100vh;


    /* Hide scrollbar for IE, Edge and Firefox /
    -ms-overflow-style: none; / IE and Edge /
    scrollbar-width: none; / Firefox */
  }

  html {
    scrollbar-color: rgba(0, 0, 0, .5) transparent;
    scrollbar-width: thin;
  }

  &.menu-open {
    width: 100%;
  }

  a {
    transition: color .3s ease;
    text-decoration: none;

    &:hover {
      color: ${hover} !important;
      text-decoration: none;
      outline: none;
      box-shadow: none;
    }

    &:focus {
      text-decoration: none;
      outline: none;
      box-shadow: none;
      color: ${hover};
    }
  }

  ::selection {
    background: ${hover};
    color: #FFF;
  }

  p, a, h1, h2, h4, h3, h5, h6 {
    color: ${text};
    font-weight: 400;
    margin: 0;
  }

  h1 {
    font-size: 120px;
    font-weight: 700;
    line-height: 155px;
    font-family: ${title};
    @media (max-width: 767px) {
      font-size: 70px;
      font-weight: 700;
      line-height: 90px;
    }
  }

  h2 {
    font-size: 40px;
    font-weight: 500;
    line-height: 48px;
    font-family: ${title};
    @media (max-width: 767px) {
      font-size: 30px;
      font-weight: 500;
      line-height: 38px;
    }
  }

  h3 {
    font-size: 32px;
    font-weight: 500;
    line-height: 40px;
    font-family: ${title};
    @media (max-width: 767px) {
      font-size: 28px;
      font-weight: 500;
      line-height: 34px;
    }
  }

  h4 {
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
    font-family: ${title};
    @media (max-width: 767px) {
      font-size: 24px;
      font-weight: 400;
      line-height: 36px;
    }
  }

  h5 {
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    font-family: ${title};
    @media (max-width: 767px) {
      font-size: 20px;
      font-weight: 400;
      line-height: 24px;
    }
  }

  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    font-family: ${title};
    @media (max-width: 767px) {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }

  .button {
    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 16px;
      font-family: ${title};
      @media (max-width: 767px) {
        font-size: 16px;
        font-weight: 400;
        line-height: 16px;
      }
    }
  }

  .button {
    p {
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      font-family: ${title};
      @media (max-width: 767px) {
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }

  ul {
    margin: 0;
    padding: 0
  }

  li {
    list-style: none;
  }

  img {
    max-width: 100%;
    object-fit: contain;
  }

  .btn:focus, button:focus, button:active:focus, .btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus {
    outline: none;
    box-shadow: none;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 1px solid rgba(0, 0, 0, 0);
    -webkit-text-fill-color: #f9f9f9;
    -webkit-box-shadow: 0 0 0px 1000px rgba(0, 0, 0, 0) inset;
    transition: background-color 5000s ease-in-out 0s;
  }


  table {
    width: 100%;
  }

  //page transition
  .page-transition {
    position: fixed;
    height: 0;
    width: 100%;
    top: 0;
    left: 0;
    background-color: #002839;
    z-index: 999999999;
    //display: none;
    opacity: 1;
    //display: flex;
    align-items: center;
    justify-content: center;

    .logo {
      width: 250px;
      height: 100px;
      opacity: 0;
    }
  }

  form div {
    position: relative;
  }

  .form-control {
    box-shadow: none;
    outline: 0;
    border-radius: 0;

    &:focus {
      box-shadow: none;
    }
  }

  .p-0 {
    padding: 0 !important;
  }

  .pl-0 {
    padding-left: 0;
  }

  .pr-0 {
    padding-right: 0;
  }

  .pt-160 {
    padding-top: 160px;
    @media (max-width: 767px) {
      padding-top: 80px;
    }
  }

  .pb-160 {
    padding-bottom: 160px;
    @media (max-width: 767px) {
      padding-bottom: 80px;
    }
  }

  .pb-130 {
    padding-bottom: 130px;
    @media (max-width: 767px) {
      padding-bottom: 60px;
    }
  }

  .pt-100 {
    padding-top: 100px;
    @media (max-width: 767px) {
      padding-top: 80px;
    }
  }

  .pb-100 {
    padding-bottom: 100px;
    @media (max-width: 767px) {
      padding-bottom: 60px;
    }
  }

  .pt-200 {
    padding-top: 200px;
    @media (max-width: 767px) {
      padding-top: 90px;
    }
  }


  .pb-200 {
    padding-bottom: 200px;
    @media (max-width: 767px) {
      padding-bottom: 90px;
    }
  }

  .pt-80 {
    padding-top: 80px;
    @media (max-width: 767px) {
      padding-top: 60px;
    }
  }


  .pb-80 {
    padding-bottom: 80px;
    @media (max-width: 767px) {
      padding-bottom: 60px;
    }
  }

  .pt-120 {
    padding-top: 120px;
    @media (max-width: 767px) {
      padding-top: 60px;
    }
  }

  .pb-120 {
    padding-bottom: 120px;
    @media (max-width: 767px) {
      padding-bottom: 60px;
    }
  }


  .pt-150 {
    padding-top: 150px;
    @media (max-width: 767px) {
      padding-top: 80px!important;
    }
  }

  .pb-150 {
    padding-bottom: 150px;
    @media (max-width: 767px) {
      padding-bottom: 80px;
    }
  }


  .pt-230 {
    padding-top: 230px;
    @media (max-width: 767px) {
      padding-top: 120px;
    }
  }

  .pb-230 {
    padding-bottom: 230px;
    @media (max-width: 767px) {
      padding-bottom: 100px;
    }
  }

  .mb-40 {
    margin-bottom: 40px;
  }

  .MuiDrawer-paper {
    width: 500px !important;
    padding: 20px;
    @media (max-width: 767px) {
      width: 100% !important;
    }
  }

  .swiper-button-disabled {
    opacity: 0 !important;
  }

  @media (min-width: 1500px) {
    .container {
      //min-width: 1366px;
      min-width: 85%;
      margin: auto;
    }
  }

  @media (max-width: 1199px) and (min-width: 768px) {
    .container, .container-lg, .container-md, .container-sm {
      max-width: 90%;
      margin: auto;
    }
  }


  @media (max-width: 767px) {
    .container, .container-sm {
      max-width: 100%;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  #root {
    min-height: 100vh;
    overflow-x: hidden;
  }

  .MuiDialog-paperWidthSm {
    width: calc(100% - 98px) !important;
    max-width: calc(100% - 98px) !important;
  }

  @media (max-width: 959px) {
    .MuiDialog-paper {
      margin: 0px !important;
    }

    .MuiDialog-paperScrollPaper {
      position: fixed;
      bottom: 0px;
    }

    .MuiDialog-paperWidthSm {
      width: 100% !important;
      position: fixed !important;
      bottom: 0px;
      max-width: 100% !important;
    }
  }

  //react select
  .css-yk16xz-control, .css-1pahdxg-control {
    height: 50px;
    border-radius: 0 !important;
    padding-left: 5px;
    font-size: 16px;
    outline: none !important;
    border-color: #D9D9D9 !important;
    box-shadow: none !important;

    .css-1wa3eu0-placeholder {
      font-weight: 300;
      line-height: 21px;
      color: rgba(0, 0, 0, 0.5);
      outline: none;
    }

    .css-1okebmr-indicatorSeparator {
      display: none;
    }

    .css-tlfecz-indicatorContainer, .css-1gtu0rj-indicatorContainer {
      margin-right: 10px;
    }
  }

  .css-2613qy-menu {
    border-radius: 0 !important;
    margin-top: 0 !important;
  }

  //animation class


  .info-window {
    max-width: 200px;
  }

  .gm-style-iw {
    border-radius: 0 !important;
  }

  .swiper-pagination-bullet {
    outline: none;
  }

  .css-nmuc1a-menu {
    z-index: 5 !important;
  }


  .map-info__img {
    img {
      height: 100px;
      margin-bottom: 12px;
      object-fit: cover;
    }
  }

  .map-info__content {
    h4 {
      font-size: 20px;
    }

    p {
      margin-bottom: 5px;
    }
  }

  .overlay {
    position: fixed;
    height: 100vh;
    width: 100%;
    //background-color: rgba(0,0,0,0.5);
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 9;
    display: none;

    &.show {
      display: block;
    }
  }

  .form-control.has-error {
    border-color: #dc004e !important;
  }

  .has-error .find-retainer-filter__control {
    border-color: #dc004e !important;
  }

  //preloader
  .content-loader {
    position: absolute;
    height: 70%;
    width: 70%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .loading-before-submit {
    position: fixed;
    height: 100vh;
    width: 100%;
    bottom: 0;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.65);
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 40px;
    }
  }


  .swiper-slide {
    height: auto;
  }

  .slick-slide {
    div {
      outline: none !important
    }
  }

  button, button:active, button:focus, button:focus-visible {
    outline: none !important;
    box-shadow: none !important;
  }


  .hover {
    position: relative;
    overflow: hidden;

    span, {
      z-index: 2;
    }

    &:after {
      content: '';
      position: absolute;
      height: 0;
      width: 0;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      background-color: ${hover};
      transition: all .5s ease;
      border-radius: 19px;
    }

    &:hover {
      &:after {
        height: 100%;
        width: 100%;
      }
    }
  }


  .modal-backdrop {
    background-color: rgb(34 31 31 / 90%);
    min-width: 100%;
    //z-index: 9999;

    &.show {
      opacity: 1;
    }
  }


  .valid {
    color: ${hover};
    position: absolute;
    font-size: 12px;
    top: 44px;
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  .slick-slide {
    -webkit-transform: translate3d(0, 0, 0);
  }


  //modal for bod version 1
  .modal-backdrop {
    //background-color: #fff;
    opacity: 1 !important;
    z-index: 9999999;
    background-color: rgba(34, 31, 31, 0.9);
    min-width: 100%;
  }

  .modal {
    height: 100vh;
    overflow: hidden !important;
    z-index: 9999999;

    &.modal {
      .modal-close {

      }

      .modal-body {
        //padding-top: 50px;
      }
    }

    .modal-body {
      padding: 0;
    }

    .modal-content {
      border: 0;
    }

    .modal-body {
      .modal-close {
        position: absolute;
        height: 50px;
        width: 50px;
        background-color: #E1E4E6;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        //top: -30px;
        right: 60px;
        top: 60px;

        svg {
          position: relative;
          z-index: 2;
          font-size: 25px;
          transition: color .3s ease;
        }

        &:hover {
          svg {
            color: #ffffff;
          }
        }
      }

      .hover:after {
        border-radius: 50%;
      }

      .for-close {
        align-items: flex-end;
        justify-content: flex-end;

      }

      .modal-data {
        margin-top: 40px;
        width: 100%;
        display: flex !important;
        flex-direction: row;
        padding-left: 0;

        &__img {
          img {
            object-fit: contain;
            width: 100%;
          }
        }

        &__content {
          h4 {
            font-size: 26px;
            line-height: 32px;
            font-weight: bold;
            margin-bottom: 20px;
          }

          ul {

            padding-bottom: 20px;
            border-bottom: 1px solid #221F1F;
            margin-bottom: 40px;

            li {
              position: relative;
              padding-left: 25px;
              font-size: 16px;
              line-height: 22px;
              margin-bottom: 10px;

              &:after {
                content: '';
                height: 10px;
                width: 10px;
                background-color: ${hover};
                border-radius: 50%;
                left: 0;
                top: 7px;
                //bottom: 0;
                //margin: auto;
                position: absolute;
              }
            }

          }

          p {
            font-size: 16px;
            line-height: 22px;
            margin-bottom: 20px;

            &:nth-last-child(1) {
              margin-bottom: 0;
            }
          }
        }

        .scroll-div {
          overflow: hidden;
          padding-right: 10px;
          padding-bottom: 10px;
        }
      }
    }

    .modal-dialog {
      width: 90%;
      max-width: unset;
      margin: 1.75rem auto;
      padding: 0;
    }

    .simplebar-track {
      right: -12px;
    }

    .simplebar-track.simplebar-vertical {
      width: 6px;
    }
  }

  @media (max-width: 991px) {
    .modal-dialog {
      width: 95%;
    }
  }

  @media (max-width: 767px) {
    &.modal {
      .modal-close {
        right: 15px !important;
        top: 20px !important;
      }

      .modal-body {
        //padding-top: 50px;
      }
    }

    .modal {
      height: 100% !important;
      overflow-y: auto !important;
      min-height: 100%;

      .col-sm-7 {
        margin: 0;
      }
    }

    .modal-data__img {
      min-width: 100%;

      img {
        max-width: 100%;
      }
    }

    .modal-data__content {
      min-width: 100%;
      margin-top: 40px !important;
    }

    .modal-dialog {
      width: 100%;
      margin: 0;
    }

    .modal-content {
      padding: 50px 0;
    }

    .modal-body {
      flex: 0;
      padding: 0;

      .modal-data {
        flex-wrap: wrap;
        margin-top: 80px;
      }

      .modal-close {
        right: 15px;
        top: -10px;
      }
    }


  }


  // modal for bod version 2
  .gph_modal {
    background: #F4F4F4;
    overflow: hidden !important;

    .slider-nav {
      //position: absolute;
      //top: 7px;
      //right: 15px;
      position: absolute;
      left: 0;
      right: 0;
      width: calc(100% - 15px);
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;

      ul {
        display: flex;
        justify-content: space-between;
        display: flex;
        padding: 0 20px;
      }

      li {
        height: 50px;
        width: 50px;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;
        border: 1px solid white;

        &:nth-of-type(1) {
          margin-right: 20px;
        }

        svg {
          color: #ffffff;
          z-index: 2;
          font-size: 20px;
        }
      }
    }

    .hover {
      height: 40px;
      width: 40px;

      svg {
        font-size: 17px;
      }
    }

    .modal-dialog {
      margin: 0;
      width: 100%;
      height: 100%;
      background: #F4F4F4;

      .btn-close {
        display: none;
      }

      .modal-header {
        padding: 0;
        margin-bottom: 0;
        background: #F4F4F4;

        .h4 {
          color: #222222;
          font-weight: 500;
        }

        .header_wrap {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 30px 15px 20px;

          .close_button {
            cursor: pointer;

            &:hover {
              opacity: 0.7;
            }
          }

        }

      }

      .custombar {
        position: relative;

        &:after {
          z-index: 1;
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 100px;
          background: transparent;
          background: linear-gradient(to bottom, rgba(244, 244, 244, 0) 0%,
          rgba(244, 244, 244, 1) 100%);
        }
      }

      .modal-content {
        height: 100vh;
        padding: 0;
        background: #F4F4F4;
        border: none;

        .left_col {
          padding: 60px 15px 60px;

          h3 {
            font-size: 20px;
            font-weight: 500;
            line-height: 30px;
            color: #222222;
            margin: 0 0 10px;
          }

          p {
            font-weight: 400;
            color: #222222;

            &.deg {
              font-size: 14px;
              font-weight: 500;
              line-height: 18px;
              color: #FB030C;
              margin: 0 0 40px;
            }
          }
        }

        .right_col {
          padding: 60px 15px 60px;

          .custombar {
            &:after {
              display: none;
            }
          }

          .right_col_img {
            position: relative;
            padding-top: calc(550 / 500 * 100%);
          }
        }

      }
    }

    .simplebar-track.simplebar-vertical {
      display: none;
    }

    @media (max-width: 767px) {
      overflow-y: scroll !important;
      .custombar {
        position: relative;
        height: auto !important;

        .simplebar-content-wrapper {
          overflow: auto;
        }
      }

      .modal-content {
        overflow: hidden !important;
      }

      .row {
        flex-direction: column-reverse;

        .left_col {
          padding: 0 15px 40px !important;
        }

        .right_col {
          padding: 40px 15px !important;
        }
      }

    }

    @media (min-width: 767px) {
      .main_scroll {
        position: relative;
        height: auto !important;

        .simplebar-content-wrapper {
          overflow: auto;
        }
      }
    }
  }


  //modal for bod version 3
  .custom_modal {

    @media (max-width: 767px) {
      .modal-data__content {
        padding: 0;
        height: 100% !important;
        overflow-x: inherit;
        overflow-y: hidden;

        h4 {
          font-size: 24px;
          font-weight: 400;
          line-height: 28px;
          color: #4F616B;
          margin-bottom: 40px;
        }

        p {
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          color: #4F616B
        }
      }

    }

    .modal-content {
      border-radius: 0 !important;
    }

    .modal-close {
      position: absolute;
      top: 20px;
      right: 30px;
      display: inline-flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      height: 45px;
      width: 45px;
      border: 1px solid rgb(196, 196, 196);
      border-radius: 50%;
      cursor: pointer;
      z-index: 9;
    }

    .modal-dialog {
      max-width: 75%;
      margin: 0px 0px 0px auto;
      height: 100%;
      padding: 0px;
      @media (max-width: 992px) {
        max-width: 100%;

      }
    }

    .modal-data {
      margin-top: 0 !important;
    }

    .bod_img {
      position: relative;
      padding-top: calc(310 / 270 * 100%);
    }

    .modal-data__content {
      padding: 0;
      height: 70vh;
      overflow-x: hidden;
      overflow-y: scroll;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      h4 {
        font-size: 24px;
        font-weight: 400;
        line-height: 28px;
        color: #4F616B;
        margin-bottom: 40px;
      }

      p {
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        color: #4F616B
      }
    }


    .modal-data.d-flex {
      flex-direction: column;
      display: block !important;
      //padding-left: 55px;
      @media (max-width: 992px) {
        padding-left: 0px;
        margin-top: 40px;


      }

      h4 {
        font-size: 24px;
        font-weight: 400;
        line-height: 28px;
        color: #4F616B;
        margin-bottom: 10px !important;

      }

      .deg {
        margin-bottom: 40px !important;

        color: rgba(79, 97, 107, 0.5) !important;
      }
    }

    .modal-body {
      position: relative;
      //padding: 0px;
      padding: 80px 60px;
      height: 100vh;
      overflow: hidden;
      @media (max-width: 992px) {
        overflow: inherit;
        height: 100%;

        background: white;
      }
      @media (max-width: 992px) {
        padding: 80px 30px;
      }
      @media (max-width: 767px) {
        padding: 30px 15px;
      }

      &::-webkit-scrollbar, &::-webkit-scrollbar-track {
        display: none;
      }

      .modal-data__content {
        padding: 0;
        height: 70vh;
        overflow-x: hidden;
        overflow-y: scroll;

        h4 {
          font-size: 24px;
          font-weight: 400;
          line-height: 28px;
          color: #4F616B;
          margin-bottom: 10px !important;
        }

        .deg {
          margin-bottom: 40px !important;

          color: rgba(79, 97, 107, 0.5) !important;
        }

        p {
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          color: #4F616B
        }
      }


    }
  }

  @media (max-width: 767px) {
    .bod_img {
      margin-bottom: 40px;
    }
  }

  //responsive modal 
  .modal--content {
    display: flex;
    padding-left: 100px;
    position: relative;
    //height: 100vh;

    &__address {
      padding-top: 50px;
      padding-bottom: 50px;

      &__single {
        margin-bottom: 50px;

        h4 {
          font-size: 24px;
          line-height: 32px;
          color: ${hover};
          text-transform: capitalize;
          margin-bottom: 24px;
          display: block;
          font-weight: bold;
        }

        a {
          font-size: 24px;
          line-height: 32px;
          margin-bottom: 8px;
          font-weight: bold;
          display: block;
        }

        p {
          font-size: 20px;
          line-height: 28px;
          display: block;
        }
      }
    }

    &__form {
      padding: 100px 110px 100px 80px;
      background-color: #34342E;
      position: relative
    }
  }

  .react-responsive-modal-root {
    z-index: 99999;

    .download__img__content {
      form {
        width: 100%;
      }

      h2 {
        font-size: 48px;
        line-height: 48px;
        color: #252E47;
        margin-bottom: 40px;
      }

      p {
        font-size: 24px;
        line-height: 32px;
        font-weight: bold;
      }


      input[type=text], input[type=number] {
        box-shadow: none;
        height: 65px;
        border: 1px solid #252E47;
        border-radius: 32px;
        margin-top: 17px;
        color: #B2A89F;
        width: 100%;
        padding-left: 40px;
        padding-right: 40px;

        ::placeholder {
          font-weight: bold;
          color: #b2a89f;
        }

        &:focus {
          outline: none !important;
          border-color: #252E47;

        }

        //@media (max-width: 991px) {
        //  margin-bottom: 40px;
        //
        //}
      }
    }

  }

  .react-responsive-modal-overlay {
    background: #ffffff !important;
  }

  .react-responsive-modal-closeButton {
    display: none;
  }

  .react-responsive-modal-modal {
    box-shadow: none;
    overflow-y: hidden;
    max-width: 1100px;
  }

  .dc-form {
    .form-control {
      background-color: transparent;
      border: none;
      border-radius: 0;
      padding-left: 0;
      border-bottom: 1px solid rgba(242, 238, 232, 0.2);
      margin-bottom: 33px;
      color: #F2EEE8;
      font-size: 20px;
      line-height: 22px;

      ::placeholder {
        color: #b2a89f !important;
        font-size: 20px;
        line-height: 22px;
      }

      :focus {
        color: #b2a89f !important;
        font-size: 20px;
        line-height: 22px;
      }
    }

    textarea {
      min-height: 60px;
      max-height: 100px;
      min-width: 100%;
      max-width: 100%;
    }
  }

  .split-child {
    overflow: hidden;
  }

  .split-parent {
    overflow: hidden;
  }

  //for shaking 
  #smooth-content {
    will-change: transform;
  }

  //menu fixed //sticky menu
  .scroll-down .main-menu {
    transform: translate3d(0, -100%, 0) !important;

    .main-menu__bar__extra {
      &__products, &__quote {
        p {
          color: #002839;
          transition: color .5s ease;
        }
      }
    }

    .main-menu__bar__menu {
      p {
        color: #002839;
        transition: color 1.5s ease;
      }
    }

    .main-menu__logo a {
      display: flex;

      svg {
        g {
          text {
            fill: black;
            transition: fill 0.5s ease;
          }
        }
      }
    }
  }

  .menu-opened {
    transform: unset !important;
  }


  .scroll-up .main-menu, .scroll-up .menu-open {
    background-color: #F5F5F5;
    box-shadow: 0 0 10px #ddddddc2;

    &__bar {
      &__extra {
        &__products, &__quote {
          p {
            color: #002839 !important;
            border: 1px solid rgb(0 40 57 / 50%) !important;
            transition: color 0.5s ease;

            &:hover {
              border: 1px solid rgb(0 40 57 / 100%) !important;
            }
          }
        }
      }

      &__menu {
        p {
          color: #002839 !important;
        }
      }
    }

    .main-menu__logo a {
      display: flex;

      svg {
        g {
          text {
            fill: black;
            transition: fill 0.5s ease;
          }
        }
      }
    }

    .slide:after {
      background-color: #D6D6D6 !important;
    }

    .hamburger {
      span {
        background-color: #08082B !important;
      }
    }

    .main-menu__bar {
      &__menu {
        p {
          color: #002839;
        }
      }
    }
  }

  //.menu-open {
  //  position: fixed !important;
  //}


  //product details

  .black-menu {
    .main-menu {
      &__bar {
        &__extra {
          &__products, &__quote {
            p {
              color: #002839 !important;
              border: 1px solid rgb(0 40 57 / 50%) !important;
              transition: color 0.5s ease;

              &:hover {
                border: 1px solid rgb(0 40 57 / 100%) !important;
              }
            }
          }
        }

        &__menu {
          p {
            color: #002839 !important;
          }
        }
      }
    }
  }

  //toastify
  .toastify {
    position: fixed;
    z-index: 999999999;
  }

  .toastify {
    z-index: 999999999;
  }


  .Toastify__toast-container {
    z-index: 99999999;
  }

  //.menu-open{
  //height: 100%!important;
  //}

  //custom scroll-bar

  //
  //body {
  //  scrollbar-width: thin;
  //}
  ////
  //html {
  //  /* For Firefox */
  //  //overflow-y: scroll;
  //  scrollbar-color: rgb(101, 185, 213);
  //  scrollbar-width: thin;
  //}
  //
  //body::-webkit-scrollbar {
  //  width: 5px;
  //  background-color: #F8F8F9;
  //}
  //
  //body::-webkit-scrollbar-thumb {
  //  background-color: #6EAFC4;
  //  transition: background-color 0.3s;
  //}
  //
  //body::-webkit-scrollbar-thumb:hover {
  //  background-color: #2B3944;
  //}


  //modal

  .modal {
    right: 0;
    left: unset;
  }

  .custom_modal {
    .modal-dialog {
      margin: 0;
      max-width: 100%;
      width: 100%;
    }

    .modal-body .row {
      flex-direction: column;
    }

    .modal-body {
      .legacy-right {
        height: fit-content;
        overflow: hidden;
        @media (max-width: 767px) {
          .legacy-right {
            padding-top: 30px;
          }
        }
      }

      .legacy-left__image-wrapper {
        position: relative;
        padding-top: calc(430 / 430 * 100%);
      }

      .legacy-left__image-text {
        padding-top: 30px;
      }

      .for-close {
        align-items: flex-end;

        .modal-close {
          width: 90px;
          cursor: pointer;
          display: flex;
          -webkit-box-pack: justify;
          justify-content: space-between;
          -webkit-box-align: center;
          align-items: center;
          padding: 5px;
          border: unset;
          background: unset;

          svg {
            transition: all 0.7s cubic-bezier(0.4, 0, 0, 1) 0s;
            margin-left: 20px;
          }

          &:hover {
            svg {
              cursor: pointer;
              transition: all 0.7s cubic-bezier(0.4, 0, 0, 1) 0s;
              transform: rotate(90deg);
            }
          }

          &:after {
            display: none;
          }
        }
      }

      @media (max-width: 767px) {
        .modal-data {
          flex-direction: column;
        }
      }
    }

    @media (max-width: 991px) {
      .simplebar-content {
        height: 100%;
      }
    }
  }

  /* Add this CSS for custom modal transition */
  .custom_modal {
    width: 40%;
    transform: translateX(100%);
    transition: all 500ms ease-in-out;
    opacity: 1 !important;

    @media (max-width: 767px) {
      width: 100%;
    }

    .body-container {
      min-height: 100%;
      height: 100%;
      display: flex;
    }

    .modal-body {
      overflow: scroll;
      height: 100vh;
    }

    .legacy-right p {
      transform: translateY(100%);
      transition-delay: 8s;
      transition: all .8s ease-in;
    }

    .legacy-left__image-wrapper {
      img {
        right: 0;
        top: 0;
        left: unset;
        bottom: unset;
        height: 0%;
        transition-delay: 8s;
        transition: height .8s ease-in-out;
      }

      //.reveal{
      //  height: 0%;
      //  transition-delay: 8s;
      //  transition: height .8s ease-in;
      //}
    }

    .legacy-left__image-text {
      overflow: hidden;

      h5, p {
        overflow: hidden;
        transition: all 1s ease-in;
      }

      h3 {
        margin-bottom: 30px;
      }
    }

    @media (max-width: 767px) {
      &.mission-vision-modal {
        .modal-content {
          padding: 0;
        }

        .body-container {
          padding: 0 15px;
        }
      }
    }
    @media(max-width: 991px) and (min-width: 768px){
      &.mission-vision-modal{
        width: 80%;
      }
    }
  }

  .modal-backdrop {
    opacity: 0 !important;
    transition: opacity 500ms ease-in;
  }

  .custom_modal.show {
    transform: translateX(0);
    transition: transform 500ms ease-in-out;

    .legacy-right p {
      transform: translateY(0);
      transition: all 1s ease-in;
    }

    .legacy-left__image-wrapper img {
      height: 100%;
      transition: height .5s ease-in-out;
    }

    .legacy-left__image-text {
      h5, p {
        transition: all 1s ease-in;
      }

    }
  }

  .modal-backdrop.show {
    opacity: .8 !important;
    transition: opacity 800ms ease-in;
  }

  .modal.fade .modal-dialog {
    transform: unset;
    transition: unset;
  }

  .modal.show .modal-dialog {
    transform: unset;
    transition: unset;
  }





`;



