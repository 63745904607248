import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import 'bootstrap/dist/css/bootstrap.min.css';
import {Accordion, Card, Button, Container, Row, Col} from 'react-bootstrap';
import BoxImageWithText from "../BoxImageWithText";
import AccordionIcon from "../AccordionIcon";
import {ApiParam as ApiParamKey} from "../../api/network/apiParams";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import { fetchProductsChild} from "../../api/redux/products";
import {useDispatch} from "react-redux";

const MyComponent = ({data,setCategory,category}) => {

const dispath = useDispatch();

    const fetchData = (item) => {
        let apiUrl = apiEndPoints.PRODUCTS;
        let api_params = {
            [ApiParamKey.page_id]: 10,
            [ApiParamKey.sections]: 'yes',
            [ApiParamKey.image]: 'yes',
            [ApiParamKey.post]: 'yes',
            [ApiParamKey.file]: 'no',
            [ApiParamKey.category]: item?.id,
            [ApiParamKey.sub_category]: '',
            [ApiParamKey.brand]: '',
        };

        dispath(fetchProductsChild([apiUrl, api_params]))
            .then((response) => {
                // Add a timeout of 500 milliseconds before updating the filtered data
                setTimeout(() => {
                    setFilteredData(response?.data);
                }, 500000);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    };


    // filter data
    const filter = data?.filter?.category;
    const filterBrand = data?.filter?.brands;
    const filterSub = data?.filter?.sub_category;
    const [filteredData, setFilteredData] = useState();
    const [categoryClick, setcategoryClick] = useState(true);
    const [subcategoryClick, setsubcategoryClick] = useState(true);

    console.log('alamin',data)

    // Filter out duplicate values
    const uniqueTypes = Array.from(new Set(filter?.map((option) => option.title)))?.map((value) => filter.find((option) => option.title === value));
    const uniqueTypesBrand = Array.from(new Set(filterBrand?.map((option) => option.brand)))?.map((value) => filterBrand.find((option) => option.brand === value));
    const uniqueTypesSub_cat = Array.from(new Set(filterSub?.map((option) => option.sub_category)))?.map((value) => filterSub.find((option) => option.sub_category === value));

    const [selected, setSelected] = useState();
    const [selectedsub, setSelectedsub] = useState();
    const [selectedbrand, setSelectedbrand] = useState();
    // Update handleClick function to pass sub_category and brand values
    const handleClick = (item) => {
        setSelected(selected === item?.title ? null : item?.title);
        if (categoryClick) {
            fetchData(item);
            setcategoryClick(false);
        } else {
            fetchData('');
            setcategoryClick(true);
        }
    };

    useEffect(() => {
            setFilteredData(data?.data);
    }, [data])


    const filterData = (subCategory, brand) => {
        // Filter the data based on the selected sub-category and brand
        let FinalfilteredData = data?.data?.filter((item) => {
            const matchesSubCategory = subCategory ? item?.product_data?.sub_category === subCategory : true;
            const matchesBrand = brand ? item?.product_data?.brand === brand : true;
            return matchesSubCategory && matchesBrand;
        });
        // Update the state with the filtered data

        setFilteredData(FinalfilteredData);

    };

    const handleClicksub = (itemsub) => {
        // Toggle the selected state
        setSelectedsub(selectedsub === itemsub?.sub_category ? null : itemsub?.sub_category);
        filterData(itemsub?.sub_category);
    };

    const handleClickbrand = (itembrand) => {
        // Toggle the selected state
        setSelectedbrand(selectedbrand === itembrand?.brand ? null : itembrand?.brand);
        filterData(itembrand?.brand);
    };

    return (
        <StyledComponent className={'pt-150 pb-150'}>
            <Container>
                <Row>
                    <Col lg={3} className={'filter'}>
                        <div className="momento__left">
                            <div className="momento__left__content-table">
                                <Accordion defaultActiveKey="1">
                                    <Accordion.Item eventKey={'1'} key={1} defaultActiveKey="0">
                                        <Accordion.Header>
                                            <h3>Categories</h3>
                                            {/*<AccordionIcon/>*/}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <ul className={'scroll-list'}>
                                                {uniqueTypes.map((item, index) => (
                                                    <li key={index} className={selected === item?.title ? 'selected' : ''}
                                                        onClick={() => handleClick(item)}>{item?.title}</li>
                                                ))}
                                            </ul>


                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey={'2'} key={2}>
                                        <Accordion.Header>
                                            <h3>Subcategories</h3>
                                            {/*<AccordionIcon/>*/}
                                        </Accordion.Header>
                                        <Accordion.Body>

                                            <ul className={'scroll-list'}>
                                                {uniqueTypesSub_cat.map((itemsub, index) => (
                                                    <li key={index} className={selectedsub === itemsub?.sub_category ? 'selected' : ''}
                                                        onClick={() => handleClicksub(itemsub)}>{itemsub?.sub_category}</li>
                                                ))}
                                            </ul>


                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey={'3'} key={3}>
                                        <Accordion.Header>
                                            <h3>Brand</h3>
                                            {/*<AccordionIcon/>*/}
                                        </Accordion.Header>
                                        <Accordion.Body>

                                            <ul className={'scroll-list'}>
                                                {uniqueTypesBrand.map((itembrand, index) => (
                                                    <li key={index} className={selectedbrand === itembrand?.brand ? 'selected' : ''}
                                                        onClick={() => handleClickbrand(itembrand)}>{itembrand?.brand}</li>
                                                ))}
                                            </ul>


                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>

                        </div>
                    </Col>
                    <Col lg={9}>
                        <div className="momento__right">
                            <Row>
                                {filteredData && filteredData?.length > 0 && filteredData?.map((i,index)=>(
                                    <Col lg={4} md={6} key={index}>
                                        <BoxImageWithText title={i?.product_data?.subtitle}
                                                          desc={i?.product_data?.short_desc}
                                                          img={i?.images?.list[0]?.full_path}
                                                          link={i?.product_data?.slug}/>
                                    </Col>
                                ))
                                }

                            </Row>


                        </div>
                    </Col>
                </Row>


            </Container>
        </StyledComponent>

    );
};

const StyledComponent = styled.section`

  background: #F2F2F2;

  .filter {
    padding: 0;
  }

  .momento__left {
    position: sticky;
    top: 70px;
    padding: 0;

    &__content-table {
      //background: #F9F9F9;
      //padding: 20px 0 20px 20px;

      h3 {
        font-size: 24px;
        line-height: 32px;
        font-weight: 400;
        color: #002839;
        border-bottom: 1px solid #E8E8E8;
        padding: 20px 0;

      }
    }

  }

  .box-image__single {
    border-radius: unset;
  }

  .momento__right {
    //z-index: -1;
    position: relative;

    .row {
      padding-left: 7.5px;
    }

    .col-lg-4 {
      padding: 0 7.5px;
    }

    .box-image__single {
      margin-bottom: 30px;
      margin-top: 0;

      //&:nth-child(3n+1){
      //  margin-bottom: 0;
      //}
      //&:nth-last-child(3n+2){
      //  margin-bottom: 0;
      //}
      //&:nth-last-child(3n+3){
      //  margin-bottom: 0;
      //}

      @media (max-width: 767px) {
        margin: 20px;
        padding: 0;
        &__text {
          padding: 0 15px;
        }
      }
    }
  }

  //Navigations

  .pagination {
    align-items: center;
  }

  .page-link {
    border: none;
    color: #989898;
  }

  .page-num {
    border-radius: 50%;
    background: #F9F9F9;
    height: 30px;
    margin-right: 5px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    //&:last-child{
    //  margin-right: 0;
    //}
  }

  .css-yk16xz-control {
    border-radius: 30px !important;
  }

  .css-1pahdxg-control {
    border-radius: 30px !important;
  }

  .css-1hb7zxy-IndicatorsContainer {
    padding: 0 30px;
  }

  .css-1wa3eu0-placeholder {
    color: #92278F !important;
  }

  //.css-1nn07jk-menu{
  //  //position: relative;
  //  z-index: 99999;
  //}

  .css-1cuxnry-menu {
    background-color: #fff !important;
    z-index: 999999;
    position: relative;
  }


  @media (max-width: 767px) {
    margin-top: 0;
    padding-top: 80px;

    .col-md-3, .col-md-9 {
      margin: 0 15px;
    }

    .col-md-9 {
      margin-top: 60px;
    }

    .col-lg-4 {
      padding: 0 !important;
    }

    .row {
      padding-left: 0 !important;
    }

    .momento__right {
      .box-image__single {
        margin-bottom: 20px;
      }

    }


    .momento__left {

      .momento__left__content-table__mobile {
        margin: 40px 0;
        display: block;
      }

    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    .col-lg-9 {
      padding: 80px 0 0;
    }

    .momento__right .row {
      padding-right: 7.5px;
    }
  }


  //accordion

  .accordion-item {
    background: #ffffff;
    padding: 20px;
    opacity: 1 !important;
    transition: unset !important;
    border-radius: unset !important;
    transform: unset !important;
    border: unset !important;

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    .accordion-header {
      .accordion-button {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: unset;
        border: unset;

        svg {
          #Line_10754 {
            opacity: 0;
            transition: opacity .3s ease;
          }
        }

        h3 {
          padding: unset;
          border-bottom: unset;
          font-weight: 500;
        }

        &.collapsed {
          svg {
            #Line_10754 {
              opacity: 1;
              transition: opacity .3s ease;
            }
          }
        }
      }
    }

    .momento__left__content-table {
      background-color: unset;
    }

    .accordion-body {
      ul {
        padding-top: 30px;

        li {
          cursor: pointer;
          position: relative;
          padding-left: 30px;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          color: rgb(0 40 57 / 60%);

          &:not(:last-child) {
            margin-bottom: 10px;
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
          }

          &:before {
            content: "";
            position: absolute;
            height: 15px;
            width: 15px;
            border: 1px solid rgb(194, 194, 194);
            left: 0;
            top: 3.5px;
            bottom: 0;
          }

          &.selected {
            :after {
              content: "";
              position: absolute;
              left: 1px;
              top: 0px;
              bottom: 0px;
              margin: auto;
              height: 12px;
              width: 12px;
              background-size: 13px 9px;
              background-repeat: no-repeat;
              background-image: url('/images/static/tick.png');
            }
          }
        }

        @media (max-width: 767px) {
          padding-top: 0;
        }
      }

    }

    @media (max-width: 767px) {
      padding: 0;
      margin: 20px;
    }
  }

`;

export default MyComponent;
