import {Route, Switch, useHistory, useLocation, useNavigate, useParams} from 'react-router-dom';
import Error from './pages/404';
import GlobalStyle from "./styles/globalStyle";
import {Parallax, ParallaxImg} from "./components/animations/Parallax";
import {gsap} from "gsap";
import {ScrollSmoother} from "gsap/ScrollSmoother";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {SmootherContext} from "./components/SmootherContext";

import {SplitText} from "gsap/SplitText";

// page imports
import About from './pages/about';
import Components from './pages/components';
import Home from './pages/home';
import {SplitUp} from "./components/animations/TextAnimation";
import {useEffect, useLayoutEffect, useRef, useState} from "react";
import PageTransition from "./components/animations/PageTransition";
import Menu from "./components/Menu";
import Footer from "./components/Footer";
import styled from "styled-components";
import StickyMessage from "./components/StickyMessage";
import ProductDetailsIndex from "./pages/product/single";
import Services from "./pages/services";
import MediaCenter from "./pages/mediacenter";
import ProductIndex from "./pages/product";
import MediaDetailsIndex from "./pages/mediacenter/single";
import ContactUs from "./pages/contact-us";
import {useSelector} from "react-redux";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {SectionReveal} from "./components/animations/SectionReveal";
import {Loading} from "./components/Loading";


function App() {

    const location = useLocation();
    gsap.registerPlugin(ScrollTrigger, ScrollSmoother);
    const main = useRef();
    const store = useSelector(store => store)
    const isDesktop = window.innerWidth > 991;
    let getPost = useSelector((state) => state);

    const FloatingMessage = styled.div`
      position: fixed;
      top: calc(100% - 16vh);
      right: 0;
      z-index: 1;
      p {
        color: #fff;
      }
    `;


    const [isLoadingContent, setIsLoadingContent] = useState(true);
    // Simulate loading your content (e.g., data fetching)
    useEffect(() => {
        setIsLoadingContent(true); // Show preloader when route changes

        // Simulate a delay (e.g., fetching data from an API)
        setTimeout(() => {
            setIsLoadingContent(false); // Set isLoadingContent to false when content is ready
        }, 1500); // Adjust the delay duration as needed
    }, [location?.pathname]);


    // menu color change on pathname

    const [menuColor, setMenuColor] = useState('#F7F3F0');
    const { slug } = useParams();

    useEffect(() => {
        if (
            location.pathname.startsWith(`/products/`) ||
            location.pathname === '/refund-policy'
        ) {
            setMenuColor('#002839');
        } else {
            setMenuColor('#fff');
        }
    }, [location.pathname, slug]);



    // smooth scroll
    useLayoutEffect(() => {
        if (location.pathname !== '/products') {
            let smoother = ScrollSmoother.create({
                smooth: window.innerWidth > 767 ? 2 : '',// how long (in seconds) it takes to "catch up" to the native scroll position
                effects: window.innerWidth > 767 ? true : '' // looks for data-speed and data-lag attributes on elements
                // speed: 2
            });
            if (location.hash) {
                setTimeout(() => {
                    smoother.scrollTo(location.hash, 3)
                }, 400)
            } else {
                window.scroll(0, 0)
            }

            return () => {
                smoother.kill();
            };
        }
    }, [location,location.pathname,location.hash,getPost]);

    useLayoutEffect(() => {
        if (location.pathname === '/products') {
            let smoother = ScrollSmoother.create({
                smooth: window.innerWidth > 991 ? 2 : '',// how long (in seconds) it takes to "catch up" to the native scroll position
                effects: window.innerWidth > 991 ? true : '' // looks for data-speed and data-lag attributes on elements
                // speed: 2
            });
            if (location.hash) {
                setTimeout(() => {
                    smoother.scrollTo(location.hash, 3)
                }, 400)
            } else {
                window.scroll(0, 0)
            }

            return () => {
                smoother.kill();
            };
        }
    }, [location,location.pathname,location.hash]);


    // route change scroll top & page class
    useEffect(() => {
        if (location.pathname === '/') {
            document.body.classList.add('in-home-page');
        } else {
            document.body.classList.remove('in-home-page');
        }
        window.scroll(0, 0);
        if (location.pathname !== '/products') {
            window.scroll(0, 0);
        }
    }, [location.pathname]);




    // animation functions
    SectionReveal()
    // Parallax()
    // ParallaxImg()
    SplitUp()
    // page transition
    PageTransition()

    return (
        <>
            {(store?.homeReducer?.loading || store?.contactReducer?.loading || store?.productReducer?.loading)  && <Loading/>}
            <GlobalStyle/>
            <ToastContainer position="top-right" autoClose={4000} closeOnClick hideProgressBar={true}/>
            {/*page transition */}

            <div className="page-transition">
                <img className={'logo'} src="/images/static/logo.svg" alt=""/>
            </div>
            <Menu color={menuColor}/>


            { isDesktop && location.pathname === '/' && (
                <FloatingMessage>
                    <StickyMessage/>
                </FloatingMessage>
            )}


                <div className="App" ref={main} id="smooth-wrapper">
                    <div id="smooth-content">
                        <Switch location={location} key={'location.pathname'}>
                            <Route exact path="/" component={Home}/>
                            <Route exact path="/components" component={Components}/>
                            <Route exact path="/about" component={About}/>
                            <Route exact path="/contact-us" component={ContactUs}/>
                            <Route exact path="/media-center" component={MediaCenter}/>
                            <Route exact path="/media-center/:slug" component={MediaDetailsIndex}/>
                            <Route exact path={`/products`} component={ProductIndex}/>
                            <Route exact path={`/products/:slug`} component={ProductDetailsIndex}/>
                            <Route exact path={`/:slug`} component={Services}/>
                            <Route component={Error}/>
                        </Switch>

                        {!isLoadingContent && (
                            <Footer/>
                        )}
                    </div>
                </div>
        </>


    );
}

export default App;
