import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Swiper, SwiperSlide} from "swiper/react";
import {Pagination, Navigation, Scrollbar, A11y, EffectCreative, Autoplay} from "swiper";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import {background} from "../../styles/globalStyleVars";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({data}) => {

    // data filter
    const slider = data?.posts?.list;

    const [offset, setOffset] = useState(90)


    useEffect(() => {

        window.addEventListener('load', function () {
            setOffset(document.querySelector(' .container').offsetLeft)

        })
        window.addEventListener('resize', function () {
            setOffset(document.querySelector(' .container').offsetLeft)

        })
        setOffset(document.querySelector(' .container').offsetLeft)
    }, [])

    return (
        <StyledComponent offset={offset}>
            <Container>
                <div className={'navigation'}>
                    <div id={'nextButton'}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="12.828" height="22.828"
                             viewBox="0 0 12.828 22.828">
                            <path id="Path_14651" data-name="Path 14651" d="M-2094.329-2066.741l-10,10,10,10"
                                  transform="translate(-2092.915 -2045.327) rotate(180)" fill="none" stroke="#002839"
                                  stroke-linecap="round" stroke-width="2"/>
                        </svg>
                    </div>
                    <div id={'prevButton'}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="12.828" height="22.828"
                             viewBox="0 0 12.828 22.828">
                            <path id="Path_14650" data-name="Path 14650" d="M-2094.329-2066.741l-10,10,10,10"
                                  transform="translate(2105.743 2068.155)" fill="none" stroke="#002839"
                                  stroke-linecap="round" stroke-width="2"/>
                        </svg>

                    </div>

                </div>
                {slider &&
                    <Swiper
                        data-speed={0.9}
                        // install Swiper modules
                        modules={[Navigation, Pagination, Scrollbar, A11y]}
                        spaceBetween={50}
                        slidesPerView={3}
                        navigation={{
                            prevEl: '#prevButton',
                            nextEl: '#nextButton',
                        }}
                        breakpoints={{
                            320: {
                                slidesPerView: 1,
                                spaceBetween: 15,
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 15,
                            },
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 30,
                            }

                        }}
                        pagination={{clickable: true}}
                        scrollbar={{draggable: true}}
                        onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={() => console.log('slide change')}
                    >

                        {slider?.length > 0 && slider?.map((i, index) => (
                            <SwiperSlide>
                                <div className={'location-body'}>
                                    <div className={'location-body__inner'}>
                                        <div className={'location-body__inner__body'}>
                                            <h4 className={'split-up'}>{reactHtmlParser(i?.data?.subtitle)}</h4>
                                            <div className={'split-up'}><p>{reactHtmlParser(i?.data?.description)}</p></div>
                                        </div>

                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                }
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: unset;
  position: relative;
  padding: 100px 0;

  &:after {
    content: '';
    top: 0;
    left: 0;
    position: absolute;
    height: 50%;
    width: 100%;
    background-color: ${background};
    z-index: -5;
  }

  &:before {
    content: '';
    bottom: 0;
    left: 0;
    position: absolute;
    height: 50%;
    width: 100%;
    background-color: #EEEEEE;
    z-index: -5;
  }

  .location-body {
    background-color: #F9F9F9;
    border-radius: 10px;
    position: relative;

    h4 {
      padding-bottom: 10px;
      border-bottom: 1px solid rgb(0 40 57 / 30%);
      margin-bottom: 20px;
    }

    p {
      font-weight: 300;
    }

    &__inner {
      height: 100%;
      width: 100%;

      &__body {
        padding: 30px;
      }

    }
  }

  .navigation {
    #nextButton {
      position: absolute;
      right: calc(${props => props.offset || '100'}px - 30px);
      top: calc(50% - 12px);
      z-index: 10;
    }

    #prevButton {
      position: absolute;
      left: calc(${props => props.offset || '100'}px - 30px);
      top: calc(50% - 12px);
      z-index: 10;
    }
  }

  .swiper {
    .swiper-scrollbar, .swiper-pagination {
      display: none;
    !important;
    }
  }

  @media (max-width: 767px) {

    .swiper {
      overflow: visible;
    }

    .navigation {
      display: none;
    }

    .swiper {
      .swiper-pagination {
        display: block;
      !important;
        bottom: -40px;
      }
    }

    .swiper-pagination-bullet-active {
      height: 10px;
      width: 10px;
      background: #002839 !important;
    }

    .swiper-pagination-bullet {
      background: #EEEEEE;
      border: 1px solid #002839;
    }
  }

  @media (min-width: 767px) and (max-width: 1024px) {
    .navigation {
      #nextButton {
        right: calc(${props => props.offset || '100'}px - 30px);
      }

      #prevButton {
        left: calc(${props => props.offset || '100'}px - 30px);
      }
    }
  }

`;

export default MyComponent;
