import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";

const SliderInnerIcon = () => {
    return (
        <StyledComponent>
            <svg xmlns="http://www.w3.org/2000/svg" width="19.243" height="19.242" viewBox="0 0 19.243 19.242">
                <g id="Icon_feather-arrow-up-right" data-name="Icon feather-arrow-up-right" transform="translate(-8.379 -8.379)">
                    <path id="Path_10966" data-name="Path 10966" d="M10.5,25.5l15-15" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                    <path id="Path_10967" data-name="Path 10967" d="M10.5,10.5h15v15" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                </g>
            </svg>

        </StyledComponent>
    );
};

const StyledComponent = styled.section`
svg{
  width: 16px;
  height: 16px;
}
`;

export default SliderInnerIcon;
