import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {get} from "../../network/axiosServices";


const initialState = {
    loading: false,
    posts: [],
    error: '',
    child:[],
    detail: [],
    detailLoading: false,
    detailError: ''
}

export const fetchMediaPosts = createAsyncThunk("fetchMediaPosts", (params) => {
    return get(params);
});

export const fetchMediaChild = createAsyncThunk("fetchMediaChild", (params) => {
    return get(params);
});

export const fetchMediaPostDetail = createAsyncThunk("fetchMediaPostDetail", (params) => {
    return get(params);
});


const postSlice = createSlice({
    name: 'media',
    initialState,
    extraReducers: builder => {
        builder.addCase(fetchMediaPosts.pending, (state) => {
            state.loading = true
            state.posts = []
            state.error = ''
        })
        builder.addCase(fetchMediaPosts.fulfilled, (state, action) => {
            state.loading = false
            state.posts = action.payload
            state.error = ''
        })
        builder.addCase(fetchMediaPosts.rejected, (state, action) => {
            state.loading = false
            state.posts = []
            state.error = action.error
        })
        builder.addCase(fetchMediaChild.pending, (state) => {
            state.loading = true
            state.child = []
            state.error = ''
        })
        builder.addCase(fetchMediaChild.fulfilled, (state, action) => {
            state.loading = false
            state.child = action.payload
            state.error = ''
        })
        builder.addCase(fetchMediaChild.rejected, (state, action) => {
            state.loading = false
            state.child = []
            state.error = action.error
        })

        builder.addCase(fetchMediaPostDetail.fulfilled, (state, action) => {
            state.detailLoading = false
            state.detail = action.payload
            state.detailError = ''
        })
        builder.addCase(fetchMediaPostDetail.rejected, (state, action) => {
            state.detailLoading = false
            state.detail = []
            state.detailError = action.error
        })
        builder.addCase(fetchMediaPostDetail.pending, (state, action) => {
            state.detailLoading = true
            state.detail = []
            state.detailError = ''
        })
    }
})


export default postSlice.reducer
