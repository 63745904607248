import React, {useEffect, useState, useRef} from "react";
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore, {Autoplay, Navigation, Pagination} from "swiper";
import {BsPlus} from 'react-icons/bs';
import 'swiper/swiper.min.css';
// import 'swiper/components/navigation/navigation.min.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import Title from "../Title";
import {Img} from "../Img";
import {body, hover} from "../../styles/globalStyleVars";
import NavigationIcon from "../NavigationIcon";
import reactHtmlParser from "react-html-parser";
// SwiperCore.use([Autoplay, Pagination, Navigation]);

const Strength = ({title, id, padding, getAfterHeight, bg, img, full, project, nolnk, remove, data, after_bg}) => {


    const awards = data?.posts?.list

    const [getAfter, setgetAfter] = useState(90)
    const [offset, setOffset] = useState(90)
    useEffect(() => {

        // ScrollTrigger.refresh();
        gsap.registerPlugin(ScrollTrigger);


        window.addEventListener('load', function () {
            setOffset(document.querySelector(' .container').offsetLeft)

        })
        window.addEventListener('resize', function () {
            setOffset(document.querySelector(' .container').offsetLeft)

        })
        setOffset(document.querySelector(' .container').offsetLeft)
    }, [data])


    const NewsPrev13 = () => {
        document.querySelector('.swiper-button-prev').click();
    };

    const NewsNext13 = () => {
        document.querySelector('.swiper-button-next').click();
    };


    // height control

    const sourceDivReff = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            const fullWidth = sourceDivReff.current.offsetWidth;
            const adjustedWidth = fullWidth / 2;
            document.documentElement.style.setProperty('--target-height', `${adjustedWidth - 200}px`);
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <StyledStrength getAfterHeight={getAfter} remove={remove} id={`${id ? id : 'Strength'}`} offset={offset}
                        className={`pionneering parallax-inner-box pt-150 pb-150 `}>
            <Container>
                <Row>
                    <Col md={12}>
                        <div className="d-flex navigation_wrapper">
                            <div className="title">
                                <Title text={reactHtmlParser(data?.section_data?.subtitle)}/>
                            </div>
                            <NavigationIcon prev_id={'prev_swipper11'} next_id={'next_swipper12'}/>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container className='about-section__bottom' ref={sourceDivReff}>
                <Row>

                    {awards &&
                        <Swiper
                            spaceBetween={30}
                            slidesPerView={4}
                            slideNextClass={'.next'}
                            allowSlideNext={true}
                            slidePrevClass={'.prev'}
                            allowSlidePrev={true}
                            loop={false}
                            // allowTouchMove={true}
                            longSwipesMs={900}
                            speed={900}
                            breakpoints={{
                                320: {
                                    slidesPerView: 1,
                                    spaceBetween: 15,
                                },
                                768: {
                                    slidesPerView: 2,
                                    spaceBetween: 30,
                                },
                                1024: {
                                    slidesPerView: 4,
                                    spaceBetween: 30,
                                },
                            }}
                            navigation={{
                                prevEl: '#prev_swipper11',
                                nextEl: '#next_swipper12  ',
                            }}
                            modules={[Navigation]}
                            onSwiper={(swiper) => console.log(swiper)}
                        >
                            { awards?.length > 0 && awards?.map((i,index)=>(
                            <SwiperSlide key={''}>
                                <Col sm={4} className='about-section__bottom__single p-0'>
                                    <div className={'single'}>
                                        <div className="single_wrp">
                                            <div className={'test'}>
                                                <div className="content">
                                                    <div className={'top-text'}>
                                                        <p className={'split-up'}>{reactHtmlParser(i?.data?.subtitle)}</p>
                                                        <span>{reactHtmlParser(i?.data?.short_desc)}</span>
                                                    </div>
                                                    <div>
                                                        <Img src={i?.images[0]?.full_path ? i?.images[0]?.full_path : ''}/>
                                                    </div>
                                                    <div className={'bottom-text'}>
                                                        <p>{reactHtmlParser(i?.data?.description)}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </SwiperSlide>
                            ))}
                        </Swiper>
                    }

                </Row>
            </Container>
        </StyledStrength>
    );
};

const StyledStrength = styled.section`

  position: relative;
  //background: #E9E9E9;
  overflow: hidden;

  .view_btn {
    margin-top: 80px;
  }

  .title_wrapper {
    display: flex;
  }

  .swiper-slide {
    @media (max-width: 767px) {
      margin-right: 15px !important;
    }
  }

  .swiper-initialized {
    width: 100%;
    margin: 0 15px;
    @media (max-width: 767px) {
      padding-right: 80px;
    }
    @media (min-width: 992px) {
      height: var(--target-height) !important;
    }
  }

  .swiper-button-prev, .swiper-button-next {
    opacity: 0;
    visibility: hidden;
  }

  .navigation_wrapper {
    justify-content: space-between;
    margin-bottom: 80px;


    @media (max-width: 767px) {
      margin-bottom: 40px;
      flex-direction: column;
    }

    .title {
      h2 {
        font-weight: 500;
        font-size: 40px;
        line-height: 48px;
        color: ${body};
        text-transform: unset;
      }
    }

    .d-flex {
      justify-content: space-between;
    }

    .navigation {
      display: flex;
      align-items: center;

      li {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #404040;

        &.hover:after {
          background-color: ${hover};
        }

        &:first-child {
          margin-right: 20px;
        }

        position: relative;
        border-radius: 50%;
        height: 52px;
        width: 52px;

        svg {
          position: relative;

          #Ellipse_4378 {
            transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
            r: 0;
          }

          &:hover {
            #Ellipse_4378 {
              transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
              r: 26px;
            }
          }
        }
      }
    }
  }

  .swiper-wrapper {
    height: auto !important;
    @media (max-width: 767px) {
      //transform: translate3d(-1875px, 0px, 0px) !important;

    }
  }

  .about-section__bottom {
      // padding-left: ${props => props.offset ? props.offset + 30 + 'px' : '90px'};
    position: relative;


    .single_wrp {
      position: relative;
      overflow: hidden;
      padding-top: calc(372 / 270 * 100%);

      p {
        color: ${body};
      }

      &:after {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 50vh;
        z-index: 1;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
      }

      .content {
        position: absolute;
        z-index: 2;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 30px;
        height: 100%;
        width: 100%;
        justify-content: space-between;
        background: #F6F6F6;
        padding: 30px 35px;

        .top-text {
          width: 100%;

          p {
            font-size: 16px;
            line-height: 20px;
            font-weight: 700;

          }

          span {
            font-size: 12px;
            line-height: 17px;
            font-weight: 500;
          }
        }

        .bottom-text {
          overflow: hidden;
          p {
            font-size: 14px;
            line-height: 20px;
            font-weight: 500;
            opacity: 40%;
          }
        }

        .global-image {
          //top: 50%;
          //left: 50%;
          //transform: translate(-50%,-50%) !important;
          //position: absolute !important;
          width: 82px;
          position: relative;
          height: 82px;

          img {
            object-fit: contain !important;
          }
        }


        span {
          font-size: 12px;
          font-weight: 500;
          line-height: 17px;
          color: rgba(0, 40, 57, 0.4);
        }
      }

      .global-image {
        img {
          transition: 0.7s all cubic-bezier(0.4, 0, 0, 1);
          //transform: scale(1.01);
        }
      }
    }

    //
    // &:after {
    //   height: 50%;
    //   position: absolute;
    //   bottom: 0;
    //   left: 0;
    //   right: 0;
    //   background: #F6F6F6;
    //   content: "";
      //   display: ${props => props.remove ? 'none' : 'block'};
      //   opacity: ${props => props.remove ? '0' : '1'};
    //   //z-index: -1;
    // }

    @media (max-width: 767px) {
      padding-left: 15px !important;
      //padding-right: 30px !important;
    }

    &__single {
      max-width: 100%;
      box-shadow: inset 0px -10px 10px -10px rgba(255, 255, 255, 0.5);

      //@media (max-width: 767px) {
      //  min-height: 370px;
      //}

      &__inner {
        position: relative;
        padding-top: calc(372 / 270 * 100%);
        overflow: hidden;
        //border-right: 1px solid #FFF;

        &:after {
          content: '';
          background-color: #E9E9E9;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 0%;
          width: 100%;
          transition: .4s cubic-bezier(0.4, 0, 0, 1);
          //mix-blend-mode: multiply;
        }

        a {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0px;
          z-index: 5;
        }

        &__img {
          &:after {
            content: '';
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ECECEC;
            mix-blend-mode: multiply;
            opacity: 1;
            transition: .8s cubic-bezier(0.4, 0, 0, 1);
            transition-delay: .3s;
          }
        }

        .hover-top {
          position: absolute;
          left: 30px;
          right: 30px;
          top: 40px;
          transition: all .5s cubic-bezier(0.4, 0, 0, 1);
          opacity: 0;
          transform: translateY(-100%);
          z-index: 2;

          h6 {
            font-size: 20px;
            font-weight: 500;
            line-height: 28px;
            color: #404040;
            margin-bottom: 20px;
          }

          ul {
            li {
              position: relative;
              padding-left: 20px;

              &:after {
                position: absolute;
                content: '';
                height: 10px;
                width: 10px;
                background-color: #C1C1C1;
                left: 0;
                top: 0;
                bottom: 0;
                margin: auto;
              }
            }
          }
        }

        .img-top {
          position: absolute;
          top: 40px;
          left: 30px;
          z-index: 2;
          opacity: 1;
          transition: .3s cubic-bezier(0.4, 0, 0, 1);
          height: 60px;
        }

        .circle {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          height: 50px;
          width: 50px;
          border: 1px solid #fff;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 2;
          opacity: 1;
          transition: .3s cubic-bezier(0.4, 0, 0, 1);

          svg {
            color: #ffffff;
            font-size: 25px;
            transition: .3s cubic-bezier(0.4, 0, 0, 1);
          }
        }

        h4 {
          position: absolute;
          bottom: 30px;
          left: 30px;
          right: 30px;
          font-size: 28px;
          line-height: 36px;
          font-weight: 500;
          color: #FFF;
          z-index: 2;
          transition: all .5s cubic-bezier(0.4, 0, 0, 1);
        }

        .icon {
          position: absolute;
          top: 30px;
          right: 30px;
          z-index: 1;
          transition: all .8s cubic-bezier(0.4, 0, 0, 1);
          transform: rotate(0deg);

          svg {
            li {
              transition: all .8s cubic-bezier(0.4, 0, 0, 1);

            }
          }

        }

        span {
          position: absolute;
          z-index: 1;
          top: 30px;
          left: 30px;
          font-size: 80px;
          line-height: 48px;
          color: #F9F9F9;
          transition: all .5s cubic-bezier(0.4, 0, 0, 1);

        }

      }

      &:hover {
        .about-section__bottom__single__inner {
          &:after {
            height: 100%;
          }

          .icon {
            transform: rotate(-45deg);

            svg {
              line {
                stroke: #061524;
                fill: #061524;
              }
            }
          }

          span {
            top: -10px;
            opacity: 0;
          }


          .hover-top {
            transform: none;
            opacity: 1;
            color: #4F616B;
          }


          &__img:after {
            opacity: 1;
            transition-delay: 0s;
          }

          .img-top {
            transform: translateY(-20px);
            opacity: 0;
          }

          .circle {
            transform: scale(1.9);
            opacity: 0;

            svg {
              transform: rotate(50deg);
            }
          }

          h4 {
            color: #061524;
          }

        }

      }
    }
  }

  @media (max-width: 767px) {
    .view_btn {
      display: none;
    }

    .navigation_wrapper {
      flex-wrap: wrap;
    }

    .navigation {
      min-width: 100%;
      margin-top: 40px;
      margin-bottom: 20px;
    }

    .about-section__bottom__single__inner span {
      top: 55px;
      left: 55px;
    }
  }


`;

export default Strength;