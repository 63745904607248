import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {background} from "../../styles/globalStyleVars";
import {Img} from "../Img";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({data}) => {
    return (
        <StyledComponent>
            <Container>
                <Row>
                    <Col lg={12} className={'top'}>
                        <div className={'top-nav'}>
                            <div className={'top-nav__subtitle'}>
                                <h2 className={'split-up'}>{reactHtmlParser(data?.section_data?.subtitle)}</h2>
                            </div>
                        </div>
                        <div className={'map'}>
                            <Img src={data?.images?.list[0]?.full_path ? data?.images?.list[0]?.full_path : ''}/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  margin-top: -2px;
  background-color: ${background};
  .top-nav {
    position: relative;
    margin-bottom: 60px;
    &:after {
      content: '';
      bottom: 0;
      left: 0;
      position: absolute;
      padding-right: 15px;
      height: 1px;
      width: 100%;
      background: #C4C4C4;
    }
  }
  .top-nav{
    &__subtitle{
      h2{
        color: #ffffff;
        padding-bottom: 10px;
      }
    }
  }
  .map{
    position: relative;
    padding-top: calc(854 / 1030 * 100%);
    overflow: hidden;
    img{
      object-fit: contain;
    }
  }

  
`;

export default MyComponent;
