import React, {useEffect, useLayoutEffect, useState} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import styled from "styled-components";
import Banner from "../../components/Home/Banner";
import About from "../../components/Home/About";
import ElectricCarSlider from "../../components/Home/ElectricCarSlider";
import HomeMap from "../../components/Home/HomeMap";
import LocationSlider from "../../components/Home/LocationSlider";
import QueryForm from "../../components/Home/QueryForm";
import ExperienceSlider from "../../components/Home/ExperienceSlider";
import AwardsSlider from "../../components/Home/AwardsSlider";
import NewsandEvents from "../../components/Home/NewsandEvents";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {ApiParam as ApiParamKey} from "../../api/network/apiParams";
import {fetchHome} from "../../api/redux/home";
import * as getPost from "../../api/redux/global";
import {useLocation} from "react-router-dom";
import {gsap} from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollToPlugin);

const Home = () => {

    const dispatch = useDispatch()
    const getData = useSelector(state => state.homeReducer)


    // api call
    useEffect(() => {
        let apiUrl = apiEndPoints.SECTIONS
        let api_params = {
            [ApiParamKey.type]: 'slug',
            [ApiParamKey.value]: 'home',
            [ApiParamKey.get_section]: 'yes',
            [ApiParamKey.image]: 'yes',
            [ApiParamKey.post]: 'yes',
            [ApiParamKey.file]: 'no',
        }
        dispatch(fetchHome([apiUrl, api_params]))


    }, [])

    // filter data
    const homeBanner = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'banner');
    const homeOverview = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'overview');
    const featuresOverviewBody = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'feature-project');
    const globalOperation = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'global-operation');
    const query = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'query');
    const Services = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'service-slider');
    const Awards = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'awards');
    const News = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'news-slider');
    const featuresOverview = getData?.posts?.feature_products?.list;
    const ServicesBody = getData?.posts?.service_list?.list;
    const NewsSlider = getData?.posts?.news_list?.list;
    const page_data = getData?.posts?.data?.page_data;




    return (
        <HelmetProvider>

            <Helmet>
                <title>{`${page_data?.title ? page_data?.title + ' | ' : ''}`}Pacific Blue</title>
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"/>

                {
                    page_data?.meta_title &&
                    <meta name="meta:title" content={page_data?.meta_title}/>

                }
                {
                    page_data?.meta_description &&
                    <meta name="description" content={page_data?.meta_description}/>

                }
                {
                    page_data?.og_title &&
                    <meta property="og:title" content={page_data?.og_title}/>

                }
                {
                    page_data?.og_description &&
                    <meta property="og:description" content={page_data?.og_description}/>

                }
            </Helmet>


            <StyledSection>
                {
                    homeBanner &&
                    <Banner data={homeBanner}/>
                }
                {
                    homeOverview &&
                    <About data={homeOverview}/>
                }
                {
                    featuresOverview &&
                    <ElectricCarSlider data={featuresOverview} dataTwo={featuresOverviewBody}/>
                }
                {
                    globalOperation &&
                    <HomeMap data={globalOperation}/>
                }
                {
                    globalOperation &&
                    <LocationSlider data={globalOperation}/>
                }
                {
                    query &&
                    <QueryForm id={'quote'} data={query}/>
                }
                {
                    Services &&
                    <ExperienceSlider data={Services} dataTwo={ServicesBody}/>
                }
                {/*{*/}
                {/*    Awards &&*/}
                {/*    <AwardsSlider data={Awards}/>*/}
                {/*}*/}
                {
                    News &&
                    <NewsandEvents data={News} dataTwo={NewsSlider}/>
                }
            </StyledSection>
        </HelmetProvider>
    );
};

const StyledSection = styled.section`

`;

export default Home;
