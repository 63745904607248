import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import styled from "styled-components";
import InnerBanner from "../../components/InnerBanner";
import {Helmet, HelmetProvider} from "react-helmet-async";
import Overview from "../../components/Services/Overview";
import Testimonial from "../../components/Services/Testimonial";
import {ApiParam as ApiParamKey} from "../../api/network/apiParams";
import {fetchServices} from "../../api/redux/services";
import OurExpertise from "../../components/Services/OurExpertise";
import {useLocation, useParams} from "react-router-dom";


const Services = () => {

    const dispatch = useDispatch()
    const getData = useSelector(state => state.servicesReducer)
    const location = useLocation()

    let {slug} = useParams();

    // api call
    useEffect(() => {
        let apiUrl = apiEndPoints.SECTIONS
        let api_params = {
            [ApiParamKey.type]: 'slug',
            [ApiParamKey.value]: `${slug}`,
            [ApiParamKey.get_section]: 'yes',
            [ApiParamKey.image]: 'yes',
            [ApiParamKey.post]: 'yes',
            [ApiParamKey.file]: 'no',
        }
        dispatch(fetchServices([apiUrl, api_params]))

    }, [location.pathname])

    const bannerData = getData?.posts?.data;
    const overView = getData?.posts?.data?.sections?.[0];
    const expertise = getData?.posts?.data?.sections?.[1];
    const testomonial = getData?.posts?.data?.sections?.[2];
    const page_data = getData?.posts?.data?.page_data;

    return (
        <HelmetProvider>
            <Helmet>
                <title>{`${page_data?.title ? page_data?.title + ' | ' : ''}`}Pacific Blue</title>

                {
                    page_data?.meta_title &&
                    <meta name="meta:title" content={page_data?.meta_title}/>

                }
                {
                    page_data?.meta_description &&
                    <meta name="description" content={page_data?.meta_description}/>

                }
                {
                    page_data?.og_title &&
                    <meta property="og:title" content={page_data?.og_title}/>

                }
                {
                    page_data?.og_description &&
                    <meta property="og:description" content={page_data?.og_description}/>

                }
            </Helmet>
            <StyledSection>
                {
                    bannerData &&
                    <InnerBanner text={bannerData?.page_data?.subtitle} img={bannerData?.page_images?.list?.[0]?.full_path}/>
                }
                {
                    overView &&
                    <Overview data={overView}/>
                }
                {
                    expertise &&
                    <OurExpertise data={expertise}/>
                }
                {/*{*/}
                {/*    testomonial &&*/}
                {/*    <Testimonial data={testomonial}/>*/}
                {/*}*/}

            </StyledSection>
        </HelmetProvider>
    );
};

const StyledSection = styled.section`
`;

export default Services;
