import {gsap, Power4,Cubic, TimelineLite} from "gsap";
import { useLocation } from "react-router-dom";
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { loading } from "../../api/redux/global";
import {ScrollSmoother} from "gsap/ScrollSmoother";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {DrawSVGPlugin} from "gsap/dist/DrawSVGPlugin";

const PageTransition = () => {
    gsap.registerPlugin(ScrollTrigger, ScrollSmoother, DrawSVGPlugin);
    const location = useLocation();
    const dispatch = useDispatch();
    const MainStore = useSelector(state => state);
    const isDesktop = window.innerWidth > 991;

    useEffect(() => {
        const tl = new TimelineLite();

        // preloader start on page/router change
        dispatch(loading());
        tl.to('.page-transition', {
            opacity: 1,
            height: '100%', // Set height to '100%' for the bottom to top effect
            duration: 0.001,
            // ease: Power4.easeOut,
            display: 'flex'
        }).to('.page-transition .logo', {
            duration: 0.3,
            opacity: 1
        }, '-=0.7').fromTo('.page-transition svg line', {
            // drawSVG: "0%",
        }, {
            duration: 0.2,
            // drawSVG: "100%",
            ease: 'Cubic.easeOut',
            stagger: 0.02
        });

        // Cleanup function
        return () => {
            // Add cleanup logic if needed
        };
    }, [location.pathname]);

    useEffect(() => {
        const tl = new TimelineLite();

        // preloader end after page load
        if (!MainStore?.globalReducer?.globalLoader) {
            tl.to('.page-transition', {
                delay: 1.5,
                // opacity: 0,
                height: 0, // Set height to 0 for the bottom to top effect
                duration: 1,
                // ease: Cubic.easeOut,
                // display: 'none',
            }).to('.page-transition .logo', {
                duration: .3,
                opacity: 0
            }, '-=.8')
        }

        // Cleanup function
        return () => {
            // Add cleanup logic if needed
        };
    }, [MainStore,location.pathname]);

};

export default PageTransition;
