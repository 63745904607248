import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {fetchProductsChild} from "../../api/redux/products";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import styled from "styled-components";
import QueryForm from "../../components/Home/QueryForm";
import ListingPage from "../../components/Product/ListingPage";
import InnerBanner from "../../components/InnerBanner";
import {ApiParam as ApiParamKey} from "../../api/network/apiParams";
import {Helmet, HelmetProvider} from "react-helmet-async";


const ProductIndex = () => {

    const dispath = useDispatch()

    const getData = useSelector(state => state.productReducer)

    // api call
    useEffect(() => {
        let apiUrl = apiEndPoints.PRODUCTS
        let api_params = {
            [ApiParamKey.page_id]: 10,
            [ApiParamKey.sections]: 'yes',
            [ApiParamKey.image]: 'yes',
            [ApiParamKey.post]: 'yes',
            [ApiParamKey.file]: 'no',
            [ApiParamKey.category]: '',
        }
        dispath(fetchProductsChild([apiUrl, api_params]))
    }, [])

    const banner = getData?.child?.project_data;
    const productsData = getData?.child;
    const page_data = getData?.child?.project_data?.page_data;

    return (
        <HelmetProvider>
            <Helmet>
                <title>{`${page_data?.title ? page_data?.title + ' | ' : ''}`}Pacific Blue</title>

                {
                    page_data?.meta_title &&
                    <meta name="meta:title" content={page_data?.meta_title}/>

                }
                {
                    page_data?.meta_description &&
                    <meta name="description" content={page_data?.meta_description}/>

                }
                {
                    page_data?.og_title &&
                    <meta property="og:title" content={page_data?.og_title}/>

                }
                {
                    page_data?.og_description &&
                    <meta property="og:description" content={page_data?.og_description}/>

                }
            </Helmet>
            <StyledSection>
                <InnerBanner text={banner?.page_data?.subtitle} img={banner?.page_images?.list[0]?.full_path}/>
                <ListingPage data={productsData}/>
            </StyledSection>
        </HelmetProvider>
    );
};

const StyledSection = styled.section`
`;

export default ProductIndex;
