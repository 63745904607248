import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";

const MyComponent = () => {
    return (
        <StyledComponent>
            <div className={'social__icon'}>
                <div className={'social__icon__icons'}>
                    <div className={'social__icon__icons__facebook'}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                            <g id="Group_18597" data-name="Group 18597" transform="translate(-98 -848)">
                                <g id="Rectangle_1998" data-name="Rectangle 1998" transform="translate(98 848)" fill="none" stroke="#002839" stroke-width="1">
                                    <rect width="25" height="25" rx="12.5" stroke="none"/>
                                    <rect x="0.5" y="0.5" width="24" height="24" rx="12" fill="none"/>
                                </g>
                                <path id="Path_26" data-name="Path 26" d="M1012.361,3918.425v-.9a1.965,1.965,0,0,1,.019-.315.612.612,0,0,1,.086-.219.351.351,0,0,1,.216-.149,1.551,1.551,0,0,1,.4-.041h.9V3915h-1.441a2.344,2.344,0,0,0-1.8.594,2.5,2.5,0,0,0-.546,1.75v1.086h-1.08v1.8h1.079v5.233h2.166v-5.233h1.442l.19-1.8Z" transform="translate(-901.116 -3059.731)" fill="#002839"/>
                            </g>
                            <g id="Group_18598" data-name="Group 18597" transform="translate(-98 -848)">
                                <g id="Rectangle_1999" data-name="Rectangle 1998" transform="translate(98 848)" fill="none" stroke="#002839" stroke-width="1">
                                    <rect width="25" height="25" rx="12.5" stroke="none"/>
                                    <rect x="0.5" y="0.5" width="24" height="24" rx="12" fill="none"/>
                                </g>
                                <path id="Path_27" data-name="Path 26" d="M1012.361,3918.425v-.9a1.965,1.965,0,0,1,.019-.315.612.612,0,0,1,.086-.219.351.351,0,0,1,.216-.149,1.551,1.551,0,0,1,.4-.041h.9V3915h-1.441a2.344,2.344,0,0,0-1.8.594,2.5,2.5,0,0,0-.546,1.75v1.086h-1.08v1.8h1.079v5.233h2.166v-5.233h1.442l.19-1.8Z" transform="translate(-901.116 -3059.731)" fill="#002839"/>
                            </g>
                        </svg>



                    </div>
                </div>

                <div className={'social__icon__icons'}>
                    <div className={'social__icon__icons__twitter'}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                            <g id="Group_18596" data-name="Group 18596" transform="translate(-98 -893)">
                                <g id="Rectangle_1998" data-name="Rectangle 1998" transform="translate(98 893)" fill="none" stroke="#002839" stroke-width="1">
                                    <rect width="25" height="25" rx="12.5" stroke="none"/>
                                    <rect x="0.5" y="0.5" width="24" height="24" rx="12" fill="none"/>
                                </g>
                                <path id="Path_5915" data-name="Path 5915" d="M453.489,20.125a6.955,6.955,0,0,0,7-7q0-.16-.007-.318a5.011,5.011,0,0,0,1.228-1.274,4.912,4.912,0,0,1-1.413.387,2.47,2.47,0,0,0,1.082-1.362,4.933,4.933,0,0,1-1.563.6,2.464,2.464,0,0,0-4.195,2.245,6.988,6.988,0,0,1-5.073-2.571,2.463,2.463,0,0,0,.762,3.286A2.444,2.444,0,0,1,450.2,13.8c0,.01,0,.021,0,.031a2.462,2.462,0,0,0,1.975,2.413,2.459,2.459,0,0,1-1.111.042,2.464,2.464,0,0,0,2.3,1.71,4.938,4.938,0,0,1-3.057,1.054,5,5,0,0,1-.587-.034,6.968,6.968,0,0,0,3.773,1.106" transform="translate(-344.716 890.625)" fill="#002839"/>
                            </g>
                            <g id="Group_18595" data-name="Group 18596" transform="translate(-98 -893)">
                                <g id="Rectangle_1998" data-name="Rectangle 1998" transform="translate(98 893)" fill="none" stroke="#002839" stroke-width="1">
                                    <rect width="25" height="25" rx="12.5" stroke="none"/>
                                    <rect x="0.5" y="0.5" width="24" height="24" rx="12" fill="none"/>
                                </g>
                                <path id="Path_5915" data-name="Path 5915" d="M453.489,20.125a6.955,6.955,0,0,0,7-7q0-.16-.007-.318a5.011,5.011,0,0,0,1.228-1.274,4.912,4.912,0,0,1-1.413.387,2.47,2.47,0,0,0,1.082-1.362,4.933,4.933,0,0,1-1.563.6,2.464,2.464,0,0,0-4.195,2.245,6.988,6.988,0,0,1-5.073-2.571,2.463,2.463,0,0,0,.762,3.286A2.444,2.444,0,0,1,450.2,13.8c0,.01,0,.021,0,.031a2.462,2.462,0,0,0,1.975,2.413,2.459,2.459,0,0,1-1.111.042,2.464,2.464,0,0,0,2.3,1.71,4.938,4.938,0,0,1-3.057,1.054,5,5,0,0,1-.587-.034,6.968,6.968,0,0,0,3.773,1.106" transform="translate(-344.716 890.625)" fill="#002839"/>
                            </g>
                        </svg>


                    </div>
                </div>

                <div className={'social__icon__icons'}>
                    <div className={'social__icon__icons__insta'}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                            <g id="Group_18595" data-name="Group 18595" transform="translate(-98 -938)">
                                <g id="Rectangle_1998" data-name="Rectangle 1998" transform="translate(98 938)" fill="none" stroke="#002839" stroke-width="1">
                                    <rect width="25" height="25" rx="12.5" stroke="none"/>
                                    <rect x="0.5" y="0.5" width="24" height="24" rx="12" fill="none"/>
                                </g>
                                <g id="Group_18368" data-name="Group 18368" transform="translate(-8230 -228)">
                                    <path id="Path_5912" data-name="Path 5912" d="M5.593,1.008c1.493,0,1.67.006,2.26.033a3.093,3.093,0,0,1,1.038.193,1.733,1.733,0,0,1,.643.418,1.733,1.733,0,0,1,.418.643,3.1,3.1,0,0,1,.193,1.038c.027.59.033.767.033,2.26s-.006,1.67-.033,2.26a3.1,3.1,0,0,1-.193,1.038A1.852,1.852,0,0,1,8.891,9.953a3.093,3.093,0,0,1-1.038.193c-.59.027-.767.033-2.26.033s-1.67-.006-2.26-.033a3.093,3.093,0,0,1-1.038-.193,1.733,1.733,0,0,1-.643-.418,1.733,1.733,0,0,1-.418-.643A3.093,3.093,0,0,1,1.04,7.853c-.027-.59-.033-.767-.033-2.26s.006-1.67.033-2.26a3.093,3.093,0,0,1,.193-1.038,1.733,1.733,0,0,1,.418-.643,1.733,1.733,0,0,1,.643-.418A3.093,3.093,0,0,1,3.333,1.04c.59-.027.767-.033,2.26-.033M5.593,0C4.074,0,3.883.006,3.287.034a4.106,4.106,0,0,0-1.358.26A2.741,2.741,0,0,0,.939.939a2.741,2.741,0,0,0-.645.991,4.106,4.106,0,0,0-.26,1.358C.006,3.883,0,4.074,0,5.593S.006,7.3.034,7.9a4.106,4.106,0,0,0,.26,1.358,2.741,2.741,0,0,0,.645.991,2.743,2.743,0,0,0,.991.645,4.106,4.106,0,0,0,1.358.26c.6.027.787.034,2.306.034S7.3,11.179,7.9,11.152a4.106,4.106,0,0,0,1.358-.26,2.86,2.86,0,0,0,1.636-1.636,4.106,4.106,0,0,0,.26-1.358c.027-.6.034-.787.034-2.306s-.006-1.709-.034-2.306a4.106,4.106,0,0,0-.26-1.358,2.743,2.743,0,0,0-.645-.991A2.741,2.741,0,0,0,9.256.294,4.106,4.106,0,0,0,7.9.034C7.3.006,7.112,0,5.593,0" transform="translate(8335 1173)" fill="#002839"/>
                                    <path id="Path_5913" data-name="Path 5913" d="M23.548,20.676a2.872,2.872,0,1,0,2.872,2.872,2.872,2.872,0,0,0-2.872-2.872m0,4.736a1.864,1.864,0,1,1,1.864-1.864,1.864,1.864,0,0,1-1.864,1.864" transform="translate(8317.045 1155.045)" fill="#002839"/>
                                    <path id="Path_5914" data-name="Path 5914" d="M61.429,15.384a.671.671,0,1,1-.671-.671.671.671,0,0,1,.671.671" transform="translate(8282.82 1160.224)" fill="#002839"/>
                                </g>
                            </g>
                            <g id="Group_18594" data-name="Group 18595" transform="translate(-98 -938)">
                                <g id="Rectangle_1990" data-name="Rectangle 1998" transform="translate(98 938)" fill="none" stroke="#002839" stroke-width="1">
                                    <rect width="25" height="25" rx="12.5" stroke="none"/>
                                    <rect x="0.5" y="0.5" width="24" height="24" rx="12" fill="none"/>
                                </g>
                                <g id="Group_18368" data-name="Group 18368" transform="translate(-8230 -228)">
                                    <path id="Path_5912" data-name="Path 5912" d="M5.593,1.008c1.493,0,1.67.006,2.26.033a3.093,3.093,0,0,1,1.038.193,1.733,1.733,0,0,1,.643.418,1.733,1.733,0,0,1,.418.643,3.1,3.1,0,0,1,.193,1.038c.027.59.033.767.033,2.26s-.006,1.67-.033,2.26a3.1,3.1,0,0,1-.193,1.038A1.852,1.852,0,0,1,8.891,9.953a3.093,3.093,0,0,1-1.038.193c-.59.027-.767.033-2.26.033s-1.67-.006-2.26-.033a3.093,3.093,0,0,1-1.038-.193,1.733,1.733,0,0,1-.643-.418,1.733,1.733,0,0,1-.418-.643A3.093,3.093,0,0,1,1.04,7.853c-.027-.59-.033-.767-.033-2.26s.006-1.67.033-2.26a3.093,3.093,0,0,1,.193-1.038,1.733,1.733,0,0,1,.418-.643,1.733,1.733,0,0,1,.643-.418A3.093,3.093,0,0,1,3.333,1.04c.59-.027.767-.033,2.26-.033M5.593,0C4.074,0,3.883.006,3.287.034a4.106,4.106,0,0,0-1.358.26A2.741,2.741,0,0,0,.939.939a2.741,2.741,0,0,0-.645.991,4.106,4.106,0,0,0-.26,1.358C.006,3.883,0,4.074,0,5.593S.006,7.3.034,7.9a4.106,4.106,0,0,0,.26,1.358,2.741,2.741,0,0,0,.645.991,2.743,2.743,0,0,0,.991.645,4.106,4.106,0,0,0,1.358.26c.6.027.787.034,2.306.034S7.3,11.179,7.9,11.152a4.106,4.106,0,0,0,1.358-.26,2.86,2.86,0,0,0,1.636-1.636,4.106,4.106,0,0,0,.26-1.358c.027-.6.034-.787.034-2.306s-.006-1.709-.034-2.306a4.106,4.106,0,0,0-.26-1.358,2.743,2.743,0,0,0-.645-.991A2.741,2.741,0,0,0,9.256.294,4.106,4.106,0,0,0,7.9.034C7.3.006,7.112,0,5.593,0" transform="translate(8335 1173)" fill="#002839"/>
                                    <path id="Path_5913" data-name="Path 5913" d="M23.548,20.676a2.872,2.872,0,1,0,2.872,2.872,2.872,2.872,0,0,0-2.872-2.872m0,4.736a1.864,1.864,0,1,1,1.864-1.864,1.864,1.864,0,0,1-1.864,1.864" transform="translate(8317.045 1155.045)" fill="#002839"/>
                                    <path id="Path_5914" data-name="Path 5914" d="M61.429,15.384a.671.671,0,1,1-.671-.671.671.671,0,0,1,.671.671" transform="translate(8282.82 1160.224)" fill="#002839"/>
                                </g>
                            </g>
                        </svg>


                    </div>
                </div>

                <div className={'social__icon__icons'}>
                    <div className={'social__icon__icons__youtube linkedin'}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                            <g id="Group_18594" data-name="Group 18594" transform="translate(-98 -983)">
                                <g id="Rectangle_1998" data-name="Rectangle 1998" transform="translate(98 983)" fill="none" stroke="#002839" stroke-width="1">
                                    <rect width="25" height="25" rx="12.5" stroke="none"/>
                                    <rect x="0.5" y="0.5" width="24" height="24" rx="12" fill="none"/>
                                </g>
                                <path id="LinkedIn" d="M1096.5,3921.554v3.313h-1.942v-3.091c0-.776-.281-1.306-.984-1.306a1.061,1.061,0,0,0-1,.7,1.3,1.3,0,0,0-.065.469v3.226h-1.943s.026-5.234,0-5.777h1.943v.82l-.013.018h.013v-.018a1.932,1.932,0,0,1,1.751-.955C1095.539,3918.955,1096.5,3919.781,1096.5,3921.554Zm-7.964-5.249a1,1,0,1,0-.026,2h.013a1,1,0,1,0,.013-2Zm-.984,8.561h1.943v-5.777h-1.943Z" transform="translate(-981.635 -2925.55)" fill="#002839"/>
                            </g>
                            <g id="Group_18589" data-name="Group 18594" transform="translate(-98 -983)">
                                <g id="Rectangle_1999" data-name="Rectangle 1998" transform="translate(98 983)" fill="none" stroke="#002839" stroke-width="1">
                                    <rect width="25" height="25" rx="12.5" stroke="none"/>
                                    <rect x="0.5" y="0.5" width="24" height="24" rx="12" fill="none"/>
                                </g>
                                <path id="LinkedIn" d="M1096.5,3921.554v3.313h-1.942v-3.091c0-.776-.281-1.306-.984-1.306a1.061,1.061,0,0,0-1,.7,1.3,1.3,0,0,0-.065.469v3.226h-1.943s.026-5.234,0-5.777h1.943v.82l-.013.018h.013v-.018a1.932,1.932,0,0,1,1.751-.955C1095.539,3918.955,1096.5,3919.781,1096.5,3921.554Zm-7.964-5.249a1,1,0,1,0-.026,2h.013a1,1,0,1,0,.013-2Zm-.984,8.561h1.943v-5.777h-1.943Z" transform="translate(-981.635 -2925.55)" fill="#002839"/>
                            </g>
                        </svg>


                    </div>
                </div>
            </div>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  .social {
    width: 100%;
    position: absolute;
    bottom: 40px;

    &__icon {
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      gap: 20px;

      &__icons:not(:last-child) {
        padding-right: 20px;
      }

      &__icons {
        &__facebook {
          svg {
            cursor: pointer;
            border-radius: 50px;

            #Group_18598 {
              #Rectangle_1999 {
                fill: #002839;
                opacity: 1;
                transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
                transform: translate(98px, 878px) !important;
              }

              #Path_27 {
                fill: #002839;
                transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
              }
            }

            &:hover {
              #Group_18598 {
                #Rectangle_1999 {
                  fill: #002839;
                  opacity: 1;
                  transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
                  transform: translate(98px, 848px) !important;
                  //path{
                  //  fill:yellow;
                  //}
                }

                #Path_27 {
                  fill: white;
                  transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
                }
              }
            }
          }


        }

        &__twitter {
          svg {
            cursor: pointer;
            border-radius: 50px;

            #Group_18595 {
              #Rectangle_1998 {
                fill: #002839;
                opacity: 1;
                transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
                transform: translate(98px, 923px) !important;
              }
              #Path_5915{
                fill: #002839;
                transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
              }
            }
            
            &:hover{
              #Group_18595 {
                #Rectangle_1998 {
                  transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
                  transform: translate(98px, 893px) !important;
                }
                #Path_5915 {
                  fill: white;
                  transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
                }
              }
            }
          }
        }

        &__insta {
          svg {
            cursor: pointer;
            border-radius: 50px;

            #Group_18594{
              #Rectangle_1990{
                fill: #002839;
                transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
                transform: translate(98px, 965px)!important;
              }
              #Group_18368{
                path{
                  fill: #002839;
                  transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
                }
              }
            }
            &:hover{
              #Group_18594{
                #Rectangle_1990{
                  transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
                  transform: translate(98px, 938px)!important;
                }
                #Group_18368{
                  path{
                    fill: white;
                    transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
                  }
                }
              }
            }
          }
        }
        
        &__youtube {
          svg {
            cursor: pointer;
            border-radius: 50px;

            #Group_18589 {
              #Rectangle_1999{
                fill: #002839;
                transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
                transform: translate(98px, 1010px)!important;
              }
              #LinkedIn{
                fill: #002839;
                transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
              }
            }
            
            &:hover{
              #Group_18589 {
                #Rectangle_1999{
                  transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
                  transform: translate(98px, 983px)!important;
                }
                #LinkedIn{
                  fill: white;
                  transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default MyComponent;
