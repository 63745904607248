import React, {useState, useEffect,useRef} from 'react';
import styled from 'styled-components';
import {Col, Container, Form, Row} from "react-bootstrap";
import Select from "react-select";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import {background, body} from "../../styles/globalStyleVars";
import Button from "../Button";
import reactHtmlParser from "react-html-parser";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {postForm} from "../../api/redux/contact";
import {useForm} from "react-hook-form";

const ListWithForm = ({padding,data,id}) => {
    //Form Validation & Submit
    const dispatch = useDispatch();
    const responseData = useSelector(state => state.contactReducer);

    const {register, handleSubmit, formState, reset} = useForm({mode: 'all'});

    //--- form submit
    const success = (msg) => toast.success(msg, {
        position: "top-right", autoClose: 4000, closeOnClick: true, progress: undefined,

    });

    const error = (msg) => toast.error(msg, {
        position: "top-right", autoClose: 4000, closeOnClick: true, progress: undefined,

    });

    const handleBlur = () => {
        // Perform onBlur logic here
    };

    const handleChange = () => {
        // Perform onChange logic here
    };


    const onSubmit = (e) => {

        let api_services = apiEndPoints.FORM_SUBMIT;

        var formData = new FormData();
        formData.append('form_id', 'contact-form');
        formData.append('name', e?.name);
        formData.append('email', e?.email);
        formData.append('phone', e?.phone);
        formData.append('message', e?.message);

        if (e.email !== '' && e.name !== '' && e.phone !== '') {
            dispatch(postForm([api_services, formData]));
            // success('Successfully Submitted')
            reset();
        }
    };
    let count = 0;
    const onError = (errors) => {
        Object.values(errors).forEach((error) => {
            count++;
        });
        if (count > 0) {
            toast.error('please fill out the correct inputs');
        }
        count = 0;
    };
    useEffect(() => {
        if (responseData && responseData?.error !== '') {
            error(responseData?.error?.message)
        }
        if (responseData && responseData?.success !== '') {
            success(responseData?.success)

        }
        reset();

    }, [responseData])


    return (
        <StyledListWithForm id={`${id ? id : 'ListWithForm'}`} className={`list_with_form specific-section ${padding ? padding : 'pt-150 pb-150'} `}>
            <Container>
                <Row>
                    <Col md={5}>
                        <div className="list_with_form__list">
                            <div className="flex-box-wrapper">
                                <div className="sub_header">
                                    <p className={'split-up'}>{'Drop your Query'}</p>
                                </div>
                                <div className="content_left_left">
                                    <h2 className={'split-up'}> {reactHtmlParser(data?.section_data?.short_desc)}</h2>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md={7}>
                        <div className="form_wrapper">
                            <h4 className="title split-up">
                                {reactHtmlParser(data?.section_data?.subtitle)}
                            </h4>
                            <Form className={'form'} onSubmit={handleSubmit(onSubmit, onError)}>

                                <div className="form-group">
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Control className={formState?.errors?.name?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                                      {...register("name", {
                                                          required: 'Name is required',
                                                      })}
                                                      type="text"
                                                      placeholder="Name*"
                                        />
                                    </Form.Group>
                                </div>
                                <div className="form-group">
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Control className={formState?.errors?.email?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                                      {...register("email", {
                                                          required: {
                                                              value: true, message: 'please enter your email'
                                                          }, pattern: {
                                                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                              message: 'please enter a valid email address'
                                                          }
                                                      })}
                                                      type="email"
                                                      placeholder="Email*"/>
                                    </Form.Group>
                                </div>
                                <div className="form-group">
                                    <Form.Group controlId="formBasicPhone">
                                        <Form.Control className={formState?.errors?.phone?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                                      {...register("phone", {
                                                          required: {
                                                              value: true, message: 'please enter your phone first'
                                                          }, pattern: {
                                                              value: /^01[0-9]{9}$/,
                                                              message: 'please enter a valid 11 digit phone number'
                                                          }
                                                      })}
                                                      type="number"
                                                      placeholder="Phone Number"/>
                                    </Form.Group>
                                </div>
                                <div className="form-group">
                                    <Form.Group controlId="formBasicPhone">
                                        <Form.Control className={formState?.errors?.email?.message ? ' form-control-lg textarea form-control' : 'textarea form-control form-control-lg'}
                                                      {...register('message', {
                                                          // required: {
                                                          //     // value:true,
                                                          //     message: 'please enter your Message',
                                                          // },
                                                      })}
                                                      type="text"
                                                      placeholder="Message (Optional)"/>
                                    </Form.Group>
                                </div>

                                <div onClick={handleSubmit(onSubmit, onError)}>
                                    <Button color={'white'} text={'Submit'} background={'#6EAFC4'} border={'1px solid white'} hoverBorder={'#6EAFC4'}/>
                                </div>

                            </Form>
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledListWithForm>
    )
};

const StyledListWithForm = styled.div`
  background: #EEEEEE;
  
  .sub_header {
    border-bottom: 1px solid #C4C4C4;
    padding-bottom: 20px;

    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      color: ${body};
    }
  }
  
  .content_left_left{
    margin-top: 60px;
    h2{
      span{
        color: #6EAFC4;
      }
    }
  }

  .content_left_left {
    @media (max-width: 767px) {
      margin-left: 0;
      margin-top: 40px;
    }

    h3 {
      font-size: 40px;
      font-weight: 500;
      line-height: 48px;
      color: white;
      margin: 0;
    }
  }

  .list_with_form__list {
    height: 100%;

    ul {
      li {
        list-style: none;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding-bottom: 30px;
        border-bottom: 1px solid rgba(196, 196, 196, 0.5);
        margin-bottom: 30px;

        &:last-child {
          padding-bottom: 0;
          margin-bottom: 0;
          border: none;
        }

        span {
          display: block;
          font-size: 24px;
          font-weight: 400;
          line-height: 28px;
          flex: 0 0 20%;
          color: #D80028;
        }

        .item {
          flex: 0 0 80%;

          h4 {
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            color: rgba(79, 97, 107, 0.5);
            margin-bottom: 10px;
          }

          p {
            font-size: 24px;
            font-weight: 400;
            line-height: 28px;
            color: #4F616B;
          }
        }
      }
    }
  }
  
  @media(max-width: 767px){
    .col-md-7{
      height: 100%;
      padding-left: 15px;
      padding-right: 15px;
      flex-shrink: unset;
      max-width: unset;

    }
  }

  .form_wrapper {
    background: ${background};
    padding: 60px;
    height: 100%;
    @media (max-width: 992px) and (min-width: 768px) {
      padding: 30px;
    }
    @media (max-width: 767px) {
      padding: 60px 15px;
      margin-top: 40px;
    }

    .title {
      font-size: 24px;
      font-weight: 4000;
      line-height: 28px;
      color: #F9F9F9;
      margin: 0 0 40px;
    }

    .form-group {
      margin-bottom: 40px;

      &:last-child {
        margin-bottom: 0;
      }

      .form-control {
        box-shadow: none;
        outline: 0;
        border-radius: 0;
        background: transparent;
        height: auto;
        border-left: none;
        border-right: none;
        border-top: none;
        border-color: rgba(246, 246, 247, 0.2);
        padding: 0 0 8px;
        color: white;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;

        &::placeholder {
          color: rgb(255 255 255 / 60%);
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }
      }
    }


    .filter__placeholder {
      color: #C4C4C4;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }

    .filter__control {
      height: 30px !important;
      padding: 0 !important;
      background: transparent !important;
      border-left: none !important;
      border-right: none !important;
      border-top: none !important;
      border-color: rgba(246, 246, 247, 0.2) !important;

      .filter__single-value {
        color: #C4C4C4 !important;
        font-size: 14px !important;
        font-weight: 400 !important;
        line-height: 20px !important;
      }

      .filter__indicator-separator {
        display: none;
      }

      .filter__value-container {
        padding: 0;
      }

      .filter__dropdown-indicator, .filter__input-container, .filter__placeholder {
        margin: 0;
      }
    }
  }
`;


export default ListWithForm;
