import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import {hover} from "../../styles/globalStyleVars";
import Slider from "react-slick";
import {Img} from "../Img";

// css
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NavigationIcon from "../NavigationIcon";
import {ImgParallx} from "../ImgParallax";
import reactHtmlParser from "react-html-parser";


const Integrity = ({data}) => {

    // filter data
    const slider = data?.posts?.list;

    const sliderRef = useRef(null);

    const nextSlide = () => {
        if (sliderRef.current) {
            sliderRef.current.slickNext();
        }
    };

    const prevSlide = () => {
        if (sliderRef.current) {
            sliderRef.current.slickPrev();
        }
    };


    const settings = {
        dots: true,
        fade: true,
        infinite: true,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplaySpeed: 7500,
        autoplay: false,
        pauseOnHover: false,
    };

    return (
        <StyledIntegrity className="our-values">
            <Container>
                <div className={'navigation'}>
                    <NavigationIcon onPrev={prevSlide} onNext={nextSlide}/>
                </div>
                {slider?.length > 0 &&
                <Slider ref={sliderRef}  {...settings}>


                    {slider && slider?.map((i,index) =>(
                    <div key={index}
                        className="slider-single"
                    >
                        <Row>
                            <Col lg="5" className="left-content">
                                <h5>Our Values</h5>
                                <p>{reactHtmlParser(i?.data?.description)}</p>
                            </Col>

                            <Col lg="7" className="right-content">
                                <div className="right-content__inner">
                                    <Img
                                        src={i?.images[0]?.full_path}
                                        alt="our-values"
                                    />
                                    <h3>{reactHtmlParser(i?.data?.subtitle)}</h3>
                                </div>

                            </Col>
                        </Row>
                    </div>
                    ))
                    }
                </Slider>
                }

            </Container>
        </StyledIntegrity>
    );
};
const StyledIntegrity = styled.section`
  background-color: #E8E8E8;
  padding-bottom: 200px;
  position: relative;


  .slide-number {
    position: absolute;
    top: 131px;

    p {

      color: #002839;
    }
  }

  .container {
    position: relative;
  }

  .slick-arrow {
    display: none !important;
  }

  .left-content {
    h5 {
      padding-bottom: 20px;
      min-width: 100%;
      display: inline-block;
      color: #002839;
      border-bottom: 1px solid rgb(0 40 57 / 20%);
      margin: 0 0 60px 0;
    }

    p {
      color: #002839;
    }
  }

  .navigation_button {
    position: absolute;
    left: 0;
    bottom: 0;
    margin-left: 15px;
  }

  .right-content {
    padding-left: 130px;

    &__inner {
      padding-top: calc(513 / 570 * 100%);
      position: relative;

      h3 {
        font-size: 65px;
        line-height: 70px;
        font-weight: 600;
        color: #002839;
        position: absolute;
        //bottom: 100px;
        left: -130px;
        margin: 0;
        bottom: 40px;
        text-transform: uppercase;
        @media(max-width: 767px){
          font-size: 36px!important;
          line-height: 42px!important;
        }
      }
    }
  }


  .slick-dots {
    opacity: 0 !important;
  }

  @media (max-width: 991px) {
    .right-content {
      padding-left: 15px;

      h3 {
        left: 15px;
        font-size: 45px;
        line-height: 50px;
      }
    }

    .navigation_button {
      bottom: -80px;
    }

    @media (max-width: 768px) {
      padding-top: 0px;

      .go-left {
        left: 15px
      }

      .container {
        padding: 0;
      }

      .slick-slider {
        padding-bottom: 70px;
      }
      //.slick-slide{
      //  width: unset!important;
      //  transition: unset!important;
      //}

      .row {
        flex-direction: column-reverse;
      }

      .left-content {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0 30px;
        margin-bottom: 30px;
        margin-top: 78px;

        h3 {
          //margin: 0 0 10px 0;
          min-width: 70px;
          padding-bottom: 10px;
          margin: 0 0 0px 0;
        }

        p {
          margin-top: 20px;
        }
      }

      .right-content {
        flex: 0 0 100%;
        max-width: 100%;

        h3 {
          font-size: 60px;
          line-height: 60px;
          font-weight: bold;
          color: #002839;
          bottom: -23px;
        }
      }
      .navigation_button {
        bottom: 0px;
      }
    }

    .slick-track {
      padding-top: 0px;
    }

    .go-right {
      left: 70px;
    }

    .slide-number {
      position: absolute;
      top: 30%;
      z-index: 4;
      left: 40px;
    }
  }

  @media (max-width: 767px) {
    .slide-number {
      position: absolute;
      top: 30%;
      z-index: 4;
      left: 15px;
    }
  }
  @media (min-width: 768px) and (max-width: 991px){
    .navigation_button {
      bottom: -60px!important;
    }
    .row{
      gap:40px;
    }
  }
`;

export default Integrity;
