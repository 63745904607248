import { gsap, TweenMax, Power3 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SplitText } from "gsap/SplitText";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

export const SplitUp = () => {
    const location = useLocation();
    gsap.registerPlugin(SplitText, ScrollTrigger);
    TweenMax.default = TweenMax;
    Power3.default = Power3;
    let getPost = useSelector((state) => state);
    const isDesktop = window.innerWidth > 991;

    useEffect(() => {
        if (isDesktop) {
            const colorSplit = document.querySelectorAll('.split-up');

            document.fonts.ready.then(() => {
                colorSplit.forEach((char, i) => {
                    const text = new SplitText(char, { type: 'lines' });

                    const animation = TweenMax.from(text.lines, {
                        opacity: 0.2,
                        stagger: 0.3,
                        duration: 1,
                        scrollTrigger: {
                            trigger: char,
                            scrub: false,
                            start: 'top 85%',

                            once: true, // Run the animation only once
                        },
                    });

                    // Remove the animation when the element is out of view or no longer needed
                    animation.eventCallback('onComplete', () => {
                        animation.kill(); // This may not be necessary depending on the version of TweenMax
                    });
                });
            });
        }
    }, [location.pathname, getPost]);

    return null; // Assuming you are using this component only for side-effects
}
