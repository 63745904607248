import React, { useEffect, useRef } from 'react';

const VideoBanner = ({ videoSource, muted = true, loop = true }) => {
    const videoRef = useRef(null);

    useEffect(() => {
        const video = videoRef.current;

        const playVideo = () => {
            video.play().catch(error => {
                console.error('Autoplay was prevented:', error);
            });

            // Remove event listener after the first interaction
            document.removeEventListener('click', playVideo);
        };

        document.addEventListener('click', playVideo);

        return () => {
            document.removeEventListener('click', playVideo);
        };
    }, []);

    return (
        <div className="video-banner">
            <video ref={videoRef} src={videoSource} autoPlay muted={muted} loop={loop} playsInline />
        </div>
    );
};

export default VideoBanner;
