import React from 'react';
import styled from 'styled-components';
import {GoArrowLeft, GoArrowRight} from "react-icons/go";

const NavigationIcon = ({next_id, prev_id, color,onNext, onPrev}) => {


    return (

        <StyledNavigationIcon className={'navigation_button'}>
            {
                window.innerWidth > 767 ?
                    <ul>
                        <li className={'hover_left'} id={prev_id ? prev_id : 'service-prev'} onClick={onNext}>
                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="48"
                                 height="48" viewBox="0 0 48 48">
                                <g id="Mask_Group_337" data-name="Mask Group 337" clip-path="url(#clip-path)">
                                    <g id="Rectangle_4990" data-name="Rectangle 4990" fill="none" stroke="#002839"
                                       stroke-width="1">
                                        <rect width="48" height="48" rx="24" stroke="none"/>
                                        <rect x="0.5" y="0.5" width="47" height="47" rx="23.5" fill="none"/>
                                    </g>
                                    <rect id="Rectangle_5654" data-name="Rectangle 5654" width="48" height="48" rx="24"
                                          transform="translate(-50)" fill="#002839"/>
                                    <g id="Group_19794" data-name="Group 19794" transform="translate(7)">
                                        <g id="Group_15984" data-name="Group 15984" transform="translate(11.5 19)">
                                            <line id="Line_3865" data-name="Line 3865" x1="5" y2="5" fill="none"
                                                  stroke="#002839" stroke-linecap="round" stroke-width="2"/>
                                            <line id="Line_3866" data-name="Line 3866" x1="5" y1="5"
                                                  transform="translate(0 5)" fill="none" stroke="#002839"
                                                  stroke-linecap="round" stroke-width="2"/>
                                        </g>
                                        <line id="Line_3889" data-name="Line 3889" x2="10"
                                              transform="translate(12.5 24)" fill="none" stroke="#002839"
                                              stroke-linecap="round" stroke-width="2"/>
                                    </g>
                                </g>
                            </svg>

                        </li>

                        <li className={'hover_right'} id={next_id ? next_id : 'service-next'} onClick={onPrev}>
                            <svg id="Component_84_1" data-name="Component 84 – 1" xmlns="http://www.w3.org/2000/svg"
                                 xmlnsXlink="http://www.w3.org/1999/xlink" width="48" height="48" viewBox="0 0 48 48">
                                <g id="Mask_Group_356" data-name="Mask Group 356" clip-path="url(#clip-path)">
                                    <g id="Rectangle_5669" data-name="Rectangle 5669" fill="none" stroke="#002839"
                                       stroke-width="1">
                                        <rect width="48" height="48" rx="24" stroke="none"/>
                                        <rect x="0.5" y="0.5" width="47" height="47" rx="23.5" fill="none"/>
                                    </g>
                                    <rect id="Rectangle_5670" data-name="Rectangle 5670" width="48" height="48" rx="24"
                                          transform="translate(50)" fill="#002839"/>
                                    <g id="Group_21887" data-name="Group 21887" transform="translate(18.5 19)">
                                        <g id="Group_21886" data-name="Group 21886" transform="translate(6)">
                                            <line id="Line_10751" data-name="Line 10751" x2="5" y2="5" fill="none"
                                                  stroke="#002839" stroke-linecap="round" stroke-width="2"/>
                                            <line id="Line_10752" data-name="Line 10752" y1="5" x2="5"
                                                  transform="translate(0 5)" fill="none" stroke="#002839"
                                                  stroke-linecap="round" stroke-width="2"/>
                                        </g>
                                        <line id="Line_10753" data-name="Line 10753" x1="10" transform="translate(0 5)"
                                              fill="none" stroke="#002839" stroke-linecap="round" stroke-width="2"/>
                                    </g>
                                </g>
                            </svg>
                        </li>
                    </ul>
                :
                    <ul>
                        <li className={'hover_left'} id={prev_id ? prev_id : 'service-prev'} onClick={onNext}>
                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="48"
                                 height="48" viewBox="0 0 48 48">
                                <g id="Mask_Group_337" data-name="Mask Group 337" clip-path="url(#clip-path)">
                                    <g id="Rectangle_4990" data-name="Rectangle 4990" fill="none" stroke="#002839"
                                       stroke-width="1">
                                        <rect width="48" height="48" rx="24" stroke="none"/>
                                        <rect x="0.5" y="0.5" width="47" height="47" rx="23.5" fill="none"/>
                                    </g>
                                    <rect id="Rectangle_5654" data-name="Rectangle 5654" width="48" height="48" rx="24"
                                          transform="translate(0)" fill="#002839"/>
                                    <g id="Group_19794" data-name="Group 19794" transform="translate(7)">
                                        <g id="Group_15984" data-name="Group 15984" transform="translate(11.5 19)">
                                            <line id="Line_3865" data-name="Line 3865" x1="5" y2="5" fill="none"
                                                  stroke="#FFFFFF" stroke-linecap="round" stroke-width="2"/>
                                            <line id="Line_3866" data-name="Line 3866" x1="5" y1="5"
                                                  transform="translate(0 5)" fill="none" stroke="#FFFFFF"
                                                  stroke-linecap="round" stroke-width="2"/>
                                        </g>
                                        <line id="Line_3889" data-name="Line 3889" x2="10"
                                              transform="translate(12.5 24)" fill="none" stroke="#FFFFFF"
                                              stroke-linecap="round" stroke-width="2"/>
                                    </g>
                                </g>
                            </svg>

                        </li>

                        <li className={'hover_right'} id={next_id ? next_id : 'service-next'} onClick={onPrev}>
                            <svg id="Component_84_1" data-name="Component 84 – 1" xmlns="http://www.w3.org/2000/svg"
                                 xmlnsXlink="http://www.w3.org/1999/xlink" width="48" height="48" viewBox="0 0 48 48">
                                <g id="Mask_Group_356" data-name="Mask Group 356" clip-path="url(#clip-path)">
                                    <g id="Rectangle_5669" data-name="Rectangle 5669" fill="none" stroke="#002839"
                                       stroke-width="1">
                                        <rect width="48" height="48" rx="24" stroke="none"/>
                                        <rect x="0.5" y="0.5" width="47" height="47" rx="23.5" fill="none"/>
                                    </g>
                                    <rect id="Rectangle_5670" data-name="Rectangle 5670" width="48" height="48" rx="24"
                                          transform="translate(0)" fill="#002839"/>
                                    <g id="Group_21887" data-name="Group 21887" transform="translate(18.5 19)">
                                        <g id="Group_21886" data-name="Group 21886" transform="translate(6)">
                                            <line id="Line_10751" data-name="Line 10751" x2="5" y2="5" fill="none"
                                                  stroke="#FFFFFF" stroke-linecap="round" stroke-width="2"/>
                                            <line id="Line_10752" data-name="Line 10752" y1="5" x2="5"
                                                  transform="translate(0 5)" fill="none" stroke="#FFFFFF"
                                                  stroke-linecap="round" stroke-width="2"/>
                                        </g>
                                        <line id="Line_10753" data-name="Line 10753" x1="10" transform="translate(0 5)"
                                              fill="none" stroke="#FFFFFF" stroke-linecap="round" stroke-width="2"/>
                                    </g>
                                </g>
                            </svg>
                        </li>
                    </ul>
            }

        </StyledNavigationIcon>

    )
};


const StyledNavigationIcon = styled.div`
  position: relative;
  z-index: 10;

  ul {
    display: flex;

    li {
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      overflow: hidden;
      &.hover_right {
        svg{
          #Rectangle_5670{
            transform: translate(-50px);
            transition: all .5s ease;
            -webkit-transition: all .5s ease;
            -moz-transition: all .5s ease;
            -o-transition: all .5s ease;
          }
          #Group_21887{
            line{
              transition: stroke .5s ease;
            }
          }
        }
      }
      &.hover_left {
        svg{
          #Rectangle_5654{
            transform: translate(-50px);
            transition: all .5s ease;
            -webkit-transition: all .5s ease;
            -moz-transition: all .5s ease;
            -o-transition: all .5s ease;
          }
          #Group_19794{
            line{
              transition: stroke .5s ease;
            }
          }
        }
      }

      &:nth-of-type(1) {
        margin-right: 20px;
      }

      svg {
        border-radius: 50px;
        overflow: hidden;
        color: #3C3C3B;
        z-index: 2;
        .Hover_Prev, .Hover_Next{
          //stroke-dasharray: 0;
          //stroke-dashoffset: 0;
          transform: rotate(-90deg);
          stroke-dasharray: 0px 260px;
          transform-origin: 50% 50%;
          will-change: stroke-dashoffset,transform;
        }

        g {
          transition: .5s all cubic-bezier(.74,0,.24,.99);
        }
      }


      &:hover {
        &.hover_left {
          svg{
            #Rectangle_5654{
              transform: translate(0);
              transition: all .5s ease;
              -webkit-transition: all .5s ease;
              -moz-transition: all .5s ease;
              -o-transition: all .5s ease;
            }
            #Group_19794{
              line{
                stroke: #ffffff;
                transition: all .5s ease;
                -webkit-transition: all .5s ease;
                -moz-transition: all .5s ease;
                -o-transition: all .5s ease;
              }
            }
          }
        }

        &.hover_right {
          svg{
            #Rectangle_5670{
              transform: translate(0);
              transition: all .5s ease;
            }
            #Group_21887{
              line{
                stroke: #ffffff;
                transition: stroke .5s ease;
              }
            }
          }
        }
      }

    }
  }
  
  .swiper-button-disabled{
    opacity: 1!important;
  }



`;

export default React.memo(NavigationIcon);














