import {configureStore} from "@reduxjs/toolkit";
import postReducer from '../redux/post'
import globalReducer from '../redux/global'
import homeReducer from '../redux/home'
import aboutReducer from '../redux/about'
import contactReducer from '../redux/contact'
import mediaReducer from '../redux/mediacenter'
import productReducer from '../redux/products'
import servicesReducer from '../redux/services'

const store = configureStore({
    reducer: {
        postReducer,
        globalReducer,

        homeReducer,
        aboutReducer,
        contactReducer,
        mediaReducer,
        productReducer,
        servicesReducer,
    },
    // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger)
})

export default store

