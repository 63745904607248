import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";


const MyComponent = () => {
    return (
        <StyledComponent>
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12"
                 viewBox="0 0 12 12">
                <g id="Group_21964" data-name="Group 21964"
                   transform="translate(-419.5 -1247.5)">
                    <line id="Line_10753" data-name="Line 10753" x1="10"
                          transform="translate(420.5 1253.5)" fill="none"
                          stroke="#002839" stroke-linecap="round" stroke-width="2"/>
                    <line id="Line_10754" data-name="Line 10754" x1="10"
                          transform="translate(425.5 1248.5) rotate(90)" fill="none"
                          stroke="#002839" stroke-linecap="round" stroke-width="2"/>
                </g>
            </svg>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
