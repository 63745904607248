import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    globalLoader: true,
}

const globalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        loading: (state) => {
            state.globalLoader = true
        },
        loaded: (state) => {
            state.globalLoader = false
        }
    },
    extraReducers: {
        ['fetchHome/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchAbout/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchContact/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchMediaPosts/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchMediaChild/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchMediaPostDetail/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchProductsPosts/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchProductsChild/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchProductPostDetail/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['homeForm/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchServices/fulfilled']: (state, action) => {
            state.globalLoader = false
        }
    },

})


export default globalSlice.reducer
export const {loaded, loading} = globalSlice.actions
