import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Internal Component
import {Img} from "../Img";
import {Col, Container, Row} from "react-bootstrap";
import Button from "../Button";
import {useSelector} from "react-redux";
import reactHtmlParser from "react-html-parser";

import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    TwitterIcon,
    TwitterShareButton
} from "react-share";
import {useLocation} from "react-router-dom";
import {ImgSlider} from "../ImgSlider";


function SlickNextArrow(props) {
    const {className, style, onClick} = props;


    return (
        <div
            className={className}
            style={{...style}}
            onClick={onClick}
        >
            <svg id="Component_94_1" data-name="Component 94 – 1" xmlns="http://www.w3.org/2000/svg" width="50"
                 height="50" viewBox="0 0 50 50">
                <circle id="Ellipse_1" data-name="Ellipse 1" cx="25" cy="25" r="25" fill="#221f1f"/>
                <circle id="Ellipse_2" data-name="Ellipse 2" cx="2" cy="2" r="2" transform="translate(23 23)"
                        fill="#1E5C95" opacity="0"/>
                <g id="Group_5988" data-name="Group 5988" transform="translate(23 20)">
                    <line id="Line_366" data-name="Line 366" x2="5" y2="5" fill="none" stroke="#fff"
                          stroke-linecap="round" stroke-width="1"/>
                    <line id="Line_367" data-name="Line 367" y1="5" x2="5" transform="translate(0 5)" fill="none"
                          stroke="#fff" stroke-linecap="round" stroke-width="1"/>
                </g>
            </svg>

        </div>
    );
}

function SlickPrevArrow(props) {
    const {className, style, onClick} = props;
    return (
        <div
            className={className}
            style={{...style}}
            onClick={onClick}
        >
            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
                <g id="Component_94_2" data-name="Component 94 – 2" transform="translate(50 50) rotate(180)">
                    <circle id="Ellipse_1" data-name="Ellipse 1" cx="25" cy="25" r="25" fill="#221f1f"/>
                    <circle id="Ellipse_2" data-name="Ellipse 2" cx="2" cy="2" r="2" transform="translate(23 23)"
                            fill="#1E5C95" opacity="0"/>
                    <g id="Group_5988" data-name="Group 5988" transform="translate(23 20)">
                        <line id="Line_366" data-name="Line 366" x2="5" y2="5" fill="none" stroke="#fff"
                              stroke-linecap="round" stroke-width="1"/>
                        <line id="Line_367" data-name="Line 367" y1="5" x2="5" transform="translate(0 5)" fill="none"
                              stroke="#fff" stroke-linecap="round" stroke-width="1"/>
                    </g>
                </g>
            </svg>
        </div>
    );
}


const ProductInfo = ({data}) => {


    console.log('data',data)
    // filter data
    const slider = data?.images?.list;
const productDetails = data?.posts?.list;

const basicData = data?.posts?.list?.find(i=> i?.data?.slug === 'basic-data');
const techData = data?.posts?.list?.find(i=> i?.data?.slug === 'technical-data');
const productData = data?.posts?.list?.find(i=> i?.data?.slug === 'product-description');

    const [nav1, setNav1] = useState();
    const [nav2, setNav2] = useState();
    const [filterColor, setFilterColor] = useState('')
    const [filterColorName, setFilterColorName] = useState('')

    const getData = useSelector(state => state.productReducer),
        DetailData = getData?.detail?.[0];
    let [shareUrl, setShareUrl] = useState('')


    let shareButtonContentM = useRef()
    let shareToggle = useRef()


    const location = useLocation();


    useEffect(() => {
        document.body.classList.add('black-menu')

        return () => {
            document.body.classList.remove('black-menu')
        };
    }, [location.pathname]);


    // filter gallery
    const FilterGallery = DetailData?.project_product_details_group?.find(f => f?.color === filterColor);
    const MapGallery = FilterGallery ? FilterGallery : DetailData?.project_product_details_group?.[0]

    useEffect(() => {
        setFilterColor(MapGallery?.color)
        setFilterColorName(MapGallery?.color_name)
    }, [MapGallery])


    return (
        <>
            <StyledProductInfo className={'detail pt-150 reveal-up'}>
                <Container>
                    <Row>
                        <Col sm={6} className={'pr-0'}>
                            {slider &&
                            <Slider
                                asNavFor={nav2}
                                ref={(slider1) => setNav1(slider1)}
                                // nextArrow={<SlickNextArrow/>}
                                // prevArrow={<SlickPrevArrow/>}
                                infinite={false}
                                // fade={true}
                                speed={1200}
                                // autoPlay={true}
                            >
                                {slider?.length > 0 && slider?.map((i,index)=>(
                                    <div key={index} className={'ProductSlider__single_item'}>
                                        <ImgSlider src={i?.full_path} objectFit={'contain'} layout={'fill'}/>
                                    </div>
                                ))
                                }
                            </Slider>
                            }

                            {slider &&
                            <Slider
                                asNavFor={nav1}
                                ref={(slider2) => setNav2(slider2)}
                                slidesToShow={4}
                                swipeToSlide={true}
                                focusOnSelect={true}
                                nextArrow={<SlickNextArrow/>}
                                prevArrow={<SlickPrevArrow/>}
                                arrows={true}
                                speed={1200}
                                // autoPlay={true}
                                infinite={false}
                                className={'ProductThumbSlider'}
                            >
                                {slider?.length > 0 && slider?.map((i,index)=>(
                                <div key={index} className={'ProductThumbSlider__single_item'}>
                                    <Img src={i?.full_path}/>
                                </div>
                                ))
                                }

                            </Slider>
                            }
                        </Col>

                        {/*description*/}
                        <Col sm={{span: 5, offset: 1}} className={'pl-0 detail__desc'}>
                            <Row>
                                <Col lg={12}>
                                    <div className="detail__desc__title">
                                        <h3>{reactHtmlParser(data?.product_data?.subtitle)}</h3>
                                        <p>{reactHtmlParser(data?.product_data?.short_desc)}</p>
                                    </div>

                                    <div className="detail__desc__spec">
                                        {reactHtmlParser(basicData?.data?.description)}
                                    </div>
                                    <Button src={'#scroll-here'} margin={'60px 0 0 0'} text={'Download Brochure'}
                                            background={'transparent'} hoverBackground={'#002839'} color={'#002839'}
                                            border={'1px solid #002839'}/>
                                </Col>
                                <Col lg={12}>
                                    <div className="detail__desc__title">
                                        <h4>Technical data</h4>
                                    </div>

                                    <div className="detail__desc__spec">
                                        {reactHtmlParser(techData?.data?.description)}
                                    </div>
                                </Col>
                                <Col lg={12}>
                                    <div className="detail__desc__title">
                                        <h4>Product description</h4>
                                    </div>

                                    <div className="detail__desc__spec">
                                        <p>{reactHtmlParser(productData?.data?.description)}</p>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </StyledProductInfo>
        </>

    );
};

const StyledProductInfo = styled.section`
  padding-top: 130px;
  background-color: #F2F0F0;
  //padding-bottom: 60px;

  .share_wrapper {
    position: absolute;
    right: 0;
    top: 0;
    @media (max-width: 767px) {
      right: 15px;
    }

    .share {
      margin-left: 12px;
    }

    .social-lists {
      opacity: 0;
      display: flex;
      height: 0;
      visibility: hidden;

      &.open {
        opacity: 1;
        visibility: visible;
        height: 100%;
      }

      transition: 0.7s all ease;
      flex-direction: column;
      position: absolute;
      right: 0;
      top: 40px;

      .react-share__ShareButton {
        margin-bottom: 5px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .hover {
    cursor: pointer;

    svg {
      position: relative;
      z-index: 1;
    }

    &:hover {
      svg {
        circle {
          stroke: #1E5C95;
        }

        path {
          fill: white;
        }
      }
    }
  }

  .ProductSlider {
    &__single_item {
      position: relative;
      padding-top: calc(467 / 500 * 100%);

      img {
        object-fit: cover;
      }
    }
  }

  .slick-arrow {
    z-index: 1;
    width: 50px;
    height: 50px;

    &:before {
      content: none;
    }

    &.slick-prev {
      left: 15px;
      svg{
        #Ellipse_1{
          fill:#002839;
        }
        #Ellipse_2{
          fill:#002839;
          cx:25px;
        }
      }
      &.slick-disabled {
        svg {
          opacity: .5;
        }

        pointer-events: none;
      }
    }

    &.slick-next {
      right: 5px;

      svg{
        #Ellipse_1{
          fill:#002839;
        }
        #Ellipse_2{
          fill:#002839;
          cx:25px;
        }
      }

      &.slick-disabled {
        svg {
          opacity: .5;
        }

        pointer-events: none;
      }
    }

    svg {
      circle {
        &:nth-of-type(2) {
          cx: 0;
          cy: 0;
          r: 0;
          opacity: 0;
          transform: translate(25, 25);
          transition: all ease-in .2s;
        }
      }
    }

    &:hover {
      svg {
        circle {
          &:nth-of-type(2) {
            cx: 25;
            cy: 25;
            r: 25;
            opacity: 1;
            transform: translate(0, 0);
          }
        }
      }
    }
  }

  .ProductThumbSlider {
    margin-top: 13px;
    margin-left: -10px;
    margin-right: -10px;

    .slick-slide {
      padding: 0 10px;
    }

    &__single_item {
      position: relative;
      padding-top: calc(100 / 110 * 100%);
    }
  }

  .detail__desc {
    .col-lg-12 {
      padding-bottom: 120px;
      @media (max-width: 767px) {
        padding-bottom: 80px;
      }
    }

    &__title {
      h3 {
        color: #002839;
      }

      p {
        padding-top: 5px;
        font-size: 16px;
        line-height: 24px;
        color: rgb(0 40 57 / 70%);
      }
    }

    &__color {
      margin-top: 30px;
      padding: 26px 0;
      border-top: 1px solid #B8B8B8;
      border-bottom: 1px solid #B8B8B8;
      display: flex;
      justify-content: space-between;
      align-items: center;

      h3 {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 2px;
        text-transform: uppercase;
        color: #010a1a;
        display: flex;
        padding-right: 20px;

        span {
          color: rgba(1, 10, 26, 0.20);
          margin-right: 10px;
        }
      }

      ul {
        display: flex;

        li {
          height: 28px;
          width: 28px;
          border-radius: 50%;
          border: 1px solid #B8B8B8;
          cursor: pointer;

          &:not(:nth-last-of-type(1)) {
            margin-right: 12px;
          }

          &.active {
            border: 2px solid #010A1A;
          }
        }
      }
    }

    &__spec {
      margin-top: 30px;
      border-top: 1px solid #B8B8B8;
      padding-top: 20px;

      p {
        font-weight: 300;
      }

      table {
        margin-bottom: 0;

        tr {
          td {
            font-size: 16px;
            line-height: 24px;
            font-weight: 300;
            color: #002839;
            width: 50%;
            padding-left: 0;
            padding-right: 20px;
            padding-top: 14px;
            padding-bottom: 14px;
            border-color: rgba(184, 184, 184, 0.2);
            border-bottom: 1px solid rgba(184, 184, 184, 0.40);
          }

          &:nth-of-type(1) {
            td {
              padding-top: 0;
              border-top: 0;
            }
          }

          &:nth-last-of-type(1) {
            td {
              padding-bottom: 0;
              border-bottom: none;
            }
          }
        }
      }
    }

  }

  @media (max-width: 991px) {
    padding-top: 60px;
    .col-sm-6 {
      min-width: 100%;
      padding-right: 15px !important;
    }

    .col-sm-5 {
      min-width: 100%;
      padding-left: 15px !important;
      margin: 0;
    }

    .detail__desc {
      margin-top: 40px;
    }
  }

  @media (max-width: 600px) {
    .detail__desc__color {
      flex-wrap: wrap;

      h3 {
        min-width: 100%;
      }

      ul {
        min-width: 100%;
        margin-top: 20px;

        li:not(:nth-last-of-type(1)) {
          margin-right: 20px;
        }
      }
    }
  }


  .slick-arrow {
    opacity: 0 !important;
    transition: opacity .4s ease;
    //visibility: hidden;

    svg {
      height: 40px;
      width: 40px;
    }
  }

  .slick-slider:hover {
    .slick-arrow {
      opacity: 1 !important;
      //visibility: visible;
    }
  }

  @media (max-width: 767px) {
    .slick-arrow {
      opacity: 1 !important;
      height: 30px;
      width: 30px;

      svg {
        height: 30px;
        width: 30px;
      }
    }

    .slick-arrow.slick-next {
      right: 15px;
    }
  }


`;

export default ProductInfo;
