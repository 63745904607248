import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Img} from "../Img";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({data}) => {
    const contactData = data?.posts?.list;

    const address = contactData[0]?.data;
    const phone = contactData[1]?.data;
    const email = contactData[2]?.data;
    return (
        <StyledComponent>
           <Container className={'reveal-up'}>
               <Row>
                   <Col md={12}>
                       <h6 className={'subtitle'}>Contact Us</h6>
                   </Col>
                   <Col lg={3}>
                       <p className={'split-up'}>{reactHtmlParser(address?.subtitle)}</p>
                       {reactHtmlParser(address?.description)}
                   </Col>
                   <Col lg={{offset:1,span:3}} className={'contact-body'}>
                       <p className={'split-up'}>{reactHtmlParser(phone?.subtitle)}</p>
                       {reactHtmlParser(phone?.description)}
                   </Col>
                   <Col lg={{offset:1,span:3}}>
                       <p className={'split-up'}>{reactHtmlParser(email?.subtitle)}</p>
                       {reactHtmlParser(email?.description)}
                   </Col>
               </Row>
               <Row>
                   <Col className={'map-col'}>
                       <div className={'map'}>
                           {window.innerWidth > 767 ?
                               <a href={'https://maps.app.goo.gl/hHjHdrBTvkRmnezC6'} target={"_blank"}>
                                   <Img src={data?.images?.list[0]?.full_path}/>
                               </a>
                               :
                               <a href={'https://maps.app.goo.gl/hHjHdrBTvkRmnezC6'} target={"_blank"}>
                                   <Img src={data?.images?.list[1]?.full_path}/>
                               </a>
                           }
                       </div>
                   </Col>
               </Row>
           </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  padding-top: 80px;
  background-color: #F2F2F2;
  position: relative;

  .subtitle {
    padding-bottom: 20px;
    margin-bottom: 60px;
    position: relative;
    color: #002839;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 1px;
      width: 100%;
      background: rgba(0, 40, 57, 0.5);

    }
  }
.contact-body{
  h6{
    padding-bottom: 15px;
  }
}
  .container {
    //&:after{
    //  content: "";
    //  height: calc(50% - 20px);
    //  width: 100%;
    //  position: absolute;
    //  background: #F6F9F8;
    //  bottom: 0;
    //  left: 0;
    //}
  }

  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #002839;
    padding-bottom: 24px;
  }

  h6 {
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;
    color: #002839;
  }

  .map {
    z-index: 1;
    position: relative;
    margin-top: 80px;
    padding-top: calc(560 / 1170 * 100%);
  }

  @media (max-width: 767px) {
    padding-top: 60px;
    .map-col {
      padding: 0;
    }

    .map {
      position: relative;
      margin-top: 60px;
      padding-top: calc(250 / 345 * 100%);
    }
    .subtitle{
      margin-bottom: unset;
    }

    .container {
      &:after {
        content: "";
        height: 20%;
        width: 100%;
        position: absolute;
        background: #F6F9F8;
        bottom: 0;
        left: 0;
      }
    }

    .row {
      gap: 60px;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .container {
      &:after {
        content: "";
        height: 35%;
        width: 100%;
        position: absolute;
        background: #F6F9F8;
        bottom: 0;
        left: 0;
      }
    }
  }
`;

export default MyComponent;
