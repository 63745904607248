import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import Title from "../Title";
import Button from "../Button";
import Media from "../Media";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, Navigation, Pagination, EffectFade} from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/effect-fade';
import {whites} from "../../styles/globalStyleVars";
import NavigationIcon from "../NavigationIcon";
import {Img} from "../Img";
import reactHtmlParser from "react-html-parser";
import {ImgPreload} from "../ImgPreload";


const MyComponent = ({data, dataTwo}) => {
    const [offset, setOffset] = useState(100)

    console.log('media',dataTwo)
    useEffect(() => {

        window.addEventListener('load', function () {
            setOffset(document.querySelector(' .container').offsetLeft)

        })
        window.addEventListener('resize', function () {
            setOffset(document.querySelector(' .container').offsetLeft)

        })
        setOffset(document.querySelector(' .container').offsetLeft)
    }, [])

    return (
        <StyledComponent offset={offset} className={'insights pt-150 pb-150'}>
            <ImgPreload dataSpeed={'0.9'} src={'images/static/news-bg.jpg'}/>
            <Container>
                <Row>
                    <Col md={12} className="insights__top d-flex justify-content-between">

                        <Col md={5}>
                            <div className="insights__top__nav">
                                <NavigationIcon next_id={'slider_next'} prev_id={'slider_prev'}/>
                                {window.innerWidth > 767 ?
                                    <Button src={'/media-center'} text={'View all'} background={'#EEEEEE'} color={'#002839'}
                                            border={'1px solid #002839'}/>
                                    :
                                    ''
                                }
                            </div>
                        </Col>
                        <Col md={6}>
                            <Title text={reactHtmlParser(data?.section_data?.subtitle)}/>
                        </Col>


                    </Col>
                </Row>
            </Container>

            <Container fluid>

                <div className="insights__slider">
                    {dataTwo &&
                        <Swiper loop={false}
                                spaceBetween={30}
                                slidesPerView={1}
                                allowSlideNext={true}
                                allowSlidePrev={true}
                                allowTouchMove={false}
                                speed={900}
                            // pagination={{
                            //     type: "fraction",
                            // }}
                                navigation={{
                                    prevEl: '#slider_prev',
                                    nextEl: '#slider_next ',
                                }}
                                modules={[Autoplay, Pagination, Navigation]}

                            // autoplay={false}

                            // autoplay={{
                            //     delay: 5000,
                            //     disableOnInteraction: false,
                            // }}
                                breakpoints={{

                                    991: {
                                        slidesPerView: 3,
                                        spaceBetween: 30,
                                    },
                                    500: {
                                        slidesPerView: 2,
                                        spaceBetween: 30,
                                    },
                                }}>

                            {dataTwo?.length > 0 && dataTwo?.map((i,index)=>(

                            <SwiperSlide key={index}>
                                <Media link={i?.page_data?.slug}
                                       title={i?.page_data?.subtitle}
                                       img={i?.images?.list[0]?.full_path}
                                       date={i?.page_data?.description}
                                       category={i?.page_data?.short_desc}
                                />
                            </SwiperSlide>
                            ))}
                            {window.innerWidth < 767 ?
                                <Button src={'/media-center'} text={'View all'} background={'#EEEEEE'} color={'#002839'}
                                        border={'1px solid #002839'} margin={'40px 0 0'}/>
                                :
                                ''
                            }
                        </Swiper>
                    }
                </div>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  overflow: hidden;
  position: relative;

  .title {
    margin: 0 !important;
  }

  .insights__top {
    margin-bottom: 80px;

    .col-md-6, .col-md-5 {
      padding-left: 0;
    }

    &__nav {
      display: flex;
      align-items: center;

      ul {
        display: flex;
        margin-right: 60px;
      }
    }
  }

  .container-fluid {
    padding: 0;
  }

  .insights__slider {
    width: 100%;
  }

  .swiper {
    margin-left: calc(${props => props.offset || '100'}px + 15px);
    padding-right: calc(${props => props.offset || '100'}px + 15px);
  }

  @media (max-width: 767px) {
    .swiper {
      margin-left: unset;
      padding: 0 15px;
    }

    .insights__top {
      flex-wrap: wrap;
      margin-bottom: 40px;
      flex-direction: column-reverse;

      .col-md-5 {
        padding-left: 0;
      }

      &__nav {
        width: 100%;
        margin-top: 30px;

        ul {
          margin-right: 40px;
        }
      }
    }
  }
`;

export default MyComponent;
