import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import reactHtmlParser from "react-html-parser";


const AboutPacific = ({data}) => {
    return (
        <StyledComponent className='pt-100 pb-150 parallax-img'>
            <Container className={'reveal-up'}>
                <Row>
                        <Col md={5} className={'col-title'}>
                            <h2>{reactHtmlParser(data?.section_data?.subtitle)}</h2>
                        </Col>

                        <Col md={{offset: 5, span: 5}}>
                            <p>
                                {reactHtmlParser(data?.section_data?.description)}
                            </p>
                        </Col>
                    <Row/>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background: #F2F2F2;
  position: relative;


  img {
    position: absolute;
    top: 0;
    /* bottom: 50%; */
    right: 0;
    left: 45%;
    height: 100%;
    width: 40%;
    object-fit: unset;
  }

  .col-left-0 {
    padding-left: 0;
  }

  .col-title {
    margin-bottom: 40px;
  }

  .upper-title {
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    width: 50%;
    margin: 0 0 20px 0;
  }

  p, h2 {
    color: #002839;
    span{
      color: #6EAFC4!important;
    }
  }


  .counter-item {
    h2 {
      padding-bottom: 20px;
      color: #E72E4B;
    }

    p {
      font-weight: 700;
      margin: 0;
    }

    &:nth-child(1) {
      margin-bottom: 40px;
    }
  }

  @media (max-width: 767px) {
    img {
      top: 40%;
      bottom: 50px;
      right: 15px;
      left: 15px;
      height: unset;
      width: calc(100% - 30px);
      object-fit: unset;
    }

    p {
      margin: 0 0 40px 0;
    }

    .col-left-0 {
      padding-left: 15px;
    }

    .upper-title {
      width: 100%;
    }

    .counter-item {
      &:nth-child(n+1) {
        margin-bottom: 40px;
      }
    }
  }

`;

export default AboutPacific;
