import React, {useEffect, useState, useRef} from 'react';
import styled from "styled-components";
import {Container, Row, Col, Accordion} from "react-bootstrap";
import {Link, useHistory, useLocation,Outlet} from "react-router-dom";
import {body, ease, hover, text, title, whites} from "../styles/globalStyleVars";
import {gsap} from "gsap";
import {disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks} from 'body-scroll-lock';
import {Img} from "./Img";
import NavigationIcon from "./NavigationIcon";
import SocialIcon from "./SocialIcon";
import ProductMenu from "./Home/ProductMenu";
import CSSPlugin from 'gsap/CSSPlugin';
import {ScrollIntoView} from 'react-scroll-into-view';
import QueryForm from "./Home/QueryForm";

gsap.registerPlugin(CSSPlugin);

const MyComponent = ({color}) => {
    // offset
    const [offset, setOffset] = useState(null)
    useEffect(() => {
        setOffset(document.querySelector('.container').offsetLeft + 16)
        window.addEventListener('resize', () => {
            setOffset(document.querySelector('.container').offsetLeft + 16)
        })
    }, [])

    const location = useLocation();
    const cleanedPathname = location.pathname.replace(/\//g, ''); // This will remove all '/' characters


    const handleRouteChange = () => {
        closeMenu(); // Close the menu when the route changes
        setMenuExpanded(false);
    };



    // Product menu Gsap
    const expandedMenuRef = useRef(null);
    const [isMenuExpanded, setMenuExpanded] = useState(false);

    const expandMenu = () => {
        gsap.to(expandedMenuRef.current, {
            duration: 1.2,
            height: 'auto',
            ease: 'power4.out',
            onComplete: () => {
                expandedMenuRef.current.style.overflow = 'visible';
            }
        });
    };

    const closeMenu = () => {
        gsap.to(expandedMenuRef.current, {
            duration: 1.2,
            ease: 'power4.out',
            height: 0,
            overflow: 'hidden'
        });
    };

    const handleProductsClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (!isMenuExpanded) {
            expandMenu();
        } else {
            closeMenu();
        }
        setMenuExpanded(!isMenuExpanded);
    };

    const history = useHistory();

    useEffect(() => {
        const handleRouteChange = () => {
            closeMenu();
            setMenuExpanded(false);
        };

        // Subscribe to the history changes
        const unlisten = history.listen(handleRouteChange);

        // Cleanup the listener when the component unmounts
        return () => {
            unlisten();
        };
    }, [history]);



    useEffect(() => {
        // Cleanup GSAP timeline when component unmounts
        return () => gsap.killTweensOf(expandedMenuRef.current);
    }, []);


    const scrollToSection = (selector) => {
        gsap.to(window, {duration: 1, scrollTo: selector});
    };


    // Image URLs
    const homeImageUrl = '/images/static/menu-image-1.jpg';
    const aboutImageUrl = '/images/static/menu-image-2.jpg';

    // State variable to track the selected image
    const [selectedImage, setSelectedImage] = useState(homeImageUrl);
    const menuRef = useRef(null);
    const clickRef = useRef(null);


    // menu action
    useEffect(() => {
        const getMenu = menuRef.current;
        const getClick = clickRef.current;

        const openMenu = (e) => {
            disableBodyScroll(getMenu);
            e.stopPropagation();
            document.body.classList.add('menu-open');
            document.querySelector('.main-menu').classList.add('menu-opened');

            gsap.set(getMenu, {opacity: 1, display: 'flex'});
            gsap.to('.menu-overlay', {opacity: 1});
            gsap.to(getMenu, {x: 0, ease: 'Power4.easeInOut'});
            gsap.to('.slide-menu li, .accordion-item', {
                y: 0,
                opacity: 1,
                ease: 'Power4.easeInOut',
                stagger: 0.02,
            }, '-=.2');
        };

        const closeMenu = () => {
            if (document.body.classList.contains('menu-open')) {
                document.querySelector('.main-menu').classList.remove('menu-opened');
                gsap.to('.slide-menu li, .accordion-item', {
                    y: 20,
                    opacity: 0,
                    ease: 'Power4.easeInOut',
                    stagger: 0.01,
                    duration: 0.4,
                    onComplete: () => {
                        // Do not set display to 'none'
                    }
                });
                gsap.to(getMenu, {x: '100%', opacity: 0});
                gsap.to('.menu-overlay', {opacity: 0}, '-=.3');
                document.body.classList.remove('menu-open');
                enableBodyScroll(getMenu);
            }
        };

        const handleWindowClick = (e) => {
            if (document.body.classList.contains('menu-open')) {
                const slideMenuIcon = document.querySelector('.slide-menu__icon');
                const tabMenuLinks = document.querySelectorAll('.tab-menu ul li a');

                // Check if the clicked element is the slide menu icon or a tab menu link
                if (
                    (slideMenuIcon && slideMenuIcon.contains(e.target)) ||
                    Array.from(tabMenuLinks).some(link => link.contains(e.target))
                ) {
                    closeMenu();
                }
            }
        };


        getClick.addEventListener('click', openMenu);
        window.addEventListener('click', handleWindowClick);

        return () => {
            getClick.removeEventListener('click', openMenu);
            window.removeEventListener('click', handleWindowClick);
        };
    }, []);


    // menu fixed on scroll
    useEffect(() => {
        if (document.body.classList.contains('scroll-down')) {
            document.body.classList.remove('scroll-down');
        }
    });

    useEffect(() => {
        const body = document.body;
        const scrollUp = 'scroll-up';
        const scrollDown = 'scroll-down';
        let lastScroll = 0;
        let howMuchScroll;

        if (window.screen.width < 991) {
            howMuchScroll = 150;
        } else {
            howMuchScroll = 150;
        }

        window.addEventListener('scroll', () => {
            let currentScroll = window.pageYOffset;

            if (currentScroll <= howMuchScroll) {
                body.classList.remove(scrollUp);
                body.classList.remove(scrollDown);
                return;
            }

            if (currentScroll > lastScroll && currentScroll > howMuchScroll) {
                // down
                body.classList.remove(scrollUp);
                body.classList.add(scrollDown);
            } else if (currentScroll < lastScroll && currentScroll > howMuchScroll) {
                // up
                body.classList.remove(scrollDown);
                body.classList.add(scrollUp);
            }

            lastScroll = currentScroll;
        });
    }, []);


    return (
        <StyledComponent className={'main-menu reveal-down'}>
            <Container>
                <Row>
                    <Col>
                        <div className="main-menu__logo">
                            <Link to={'/'}>
                                <img src={'/images/static/logo.svg'}/>
                            </Link>
                        </div>
                    </Col>

                    <Col className={'main-menu-mb'}>

                        {/*menu bar */}
                        <div className="main-menu__bar">
                            {window.innerWidth > 767 ?
                                <div className={'main-menu__bar__extra'}>

                                    <div
                                        className={'main-menu__bar__extra__products'}>
                                        <div onClick={handleProductsClick} id={'menu-trigger'}><p>Products</p></div>
                                        <div
                                            className={`main-menu__bar__extra__products__product-menu ${isMenuExpanded ? 'show' : ''}`}
                                            id={'expanded-menu'} ref={expandedMenuRef}>
                                            <ProductMenu/>
                                        </div>
                                    </div>


                                    <div className={'main-menu__bar__extra__quote'}>
                                        <Link to={'/#quote'}>
                                            <p>Quote</p>
                                        </Link>
                                    </div>
                                </div>
                                :
                                ''
                            }

                            <div className='main-menu__bar__menu' ref={clickRef}>
                                <p style={{color: color}}>Menu</p>

                                <ul>
                                    <li className={'hamburger slide'}>
                                        <span style={{background: color}}/>
                                        <span style={{background: color}}/>
                                        <span style={{background: color}}/>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </Col>
                </Row>
                {/*menu item slide */}

                <Row>
                    <Col>
                        <div className="slide-menu " ref={menuRef}
                             style={{width: `calc(100% - ${offset - 4}px)`, paddingRight: offset + 'px'}}>

                            {window.innerWidth > 767 ?
                                <Col md={'8'}>
                                    <div className={`slide-menu__images ${selectedImage ? 'hovered' : ''}`}>

                                        {selectedImage && <Img src={selectedImage} alt="Selected"/>}

                                    </div>
                                </Col>
                                :
                                ''
                            }
                            <Col md={'4'} sm={'12'} className={'tab-menu'}>
                                <div className={'slide-menu__icon'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18.828" height="18.828"
                                         viewBox="0 0 18.828 18.828">
                                        <g id="Group_13312" data-name="Group 13312"
                                           transform="translate(-96.586 -30.586)">
                                            <line id="_1" data-name="1" x2="16" y2="16" transform="translate(98 32)"
                                                  fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2"/>
                                            <line id="_1-2" data-name="1" x1="16" y2="16" transform="translate(98 32)"
                                                  fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2"/>
                                        </g>
                                    </svg>

                                </div>
                                <ul style={{marginRight: offset + 'px'}}>
                                    <Accordion>
                                        <li className={location.pathname === '/' ? 'active' : ''}
                                            onMouseEnter={() => setSelectedImage(homeImageUrl)}
                                            onMouseLeave={() => setSelectedImage(homeImageUrl)}><Link
                                            to={'/'}>Home</Link></li>
                                        <li className={cleanedPathname === 'about' ? 'active' : ''}
                                            onMouseEnter={() => setSelectedImage(aboutImageUrl)}
                                            onMouseLeave={() => setSelectedImage(homeImageUrl)}><Link
                                            to={'/about'}>About Us</Link></li>
                                        <li className={cleanedPathname === 'products' ? 'active' : ''}
                                            onMouseEnter={() => setSelectedImage(homeImageUrl)}
                                            onMouseLeave={() => setSelectedImage(homeImageUrl)}><Link
                                            to={'/products'}>Products</Link></li>
                                        <Accordion.Item
                                            className={
                                                ['home-appliance-manufacturing-consultancy', 'home-appliance-product-development', 'home-appliance-factory-design-commissioning'].some(slug =>
                                                    location.pathname.includes(slug)
                                                )
                                                    ? 'active'
                                                    : ''
                                            }
                                            eventKey="3"
                                        >
                                            <Accordion.Header>
                                                <span>Home Appliance Services</span>{' '}
                                                <img src="/images/static/caret-down.svg" alt="" />
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <ul>
                                                    <li className={cleanedPathname === 'home-appliance-manufacturing-consultancy' ? 'active' : ''}>
                                                        <Link to="/home-appliance-manufacturing-consultancy">
                                                            Manufacturing Consultancy
                                                        </Link>
                                                    </li>
                                                    <li className={cleanedPathname === 'home-appliance-product-development' ? 'active' : ''}>
                                                        <Link to="/home-appliance-product-development">
                                                            Product Development
                                                        </Link>
                                                    </li>
                                                    <li className={cleanedPathname === 'home-appliance-factory-design-commissioning' ? 'active' : ''}>
                                                        <Link to="/home-appliance-factory-design-commissioning">
                                                            Factory design & Commissioning
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <li className={location?.pathname === '/media-center' ? 'active' : ''}><Link
                                            to={'/media-center'}>Media Center</Link></li>
                                        <li className={location?.pathname === '/contact-us' ? 'active' : ''}><Link
                                            to={'/contact-us'}>Contact Us</Link></li>


                                    </Accordion>
                                </ul>

                                <div className={'social-component'}>
                                    <SocialIcon/>
                                </div>

                            </Col>
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  display: flex;
  align-items: center;
  transition: all .6s ${ease};
  height: 103px;

  @media (min-width: 992px) {
    .main-menu-mb {
      position: unset;
    }
  }

  .menu-overlay {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    inset: 0;
    opacity: 0;
  }

  .main-menu__logo {
    img {
      height: 38px;
      width: 200px;
    }

    a {
      height: 100%;
      display: flex;
      align-items: center;

      &:nth-of-type(2) {
        //display: none;
      }
    }
  }

  .main-menu__bar {
    display: flex;
    justify-content: flex-end;

    &__extra {
      //position: relative;
      display: flex;
      gap: 26px;
      align-items: center;

      p {
        font-size: 16px;
        line-height: 16px;
        font-weight: 400;
        color: #ffffff;
        transition: color 0.5s ease;
      }

      padding-right: 72px;

      &__products {
        cursor: pointer;

        &__product-menu.show {
          //display: block;
        }

        &__product-menu {
          //display: none;
          height: 0;
          overflow: hidden;
          position: absolute;
          top: 103px;
          left: 0;
          width: 100%;
          background: white;

          .react-tabs {
            padding: 50px 230px;

            &__tab-list {
              border-bottom: 1px solid #C4C4C4;
              margin-bottom: 30px;

              li {
                margin-bottom: 20px;
              }
            }

            &__tab {
              border-left: .5px solid #C4C4C4;
              border-top: unset;
              font-size: 12px;
              font-weight: 500;
              line-height: 20px;
              color: rgb(0 40 57 / 60%);
              padding: 8px 30px;

              //&:last-child {
              //  border-right: .5px solid #C4C4C4;
              //}
            }

            &__tab--selected {
              background: #6EAFC4;
              color: white;
              border-radius: unset;
              position: relative;
              border: unset;
            }

            &__tab-panel {
              ul {
                li {
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 24px;
                  margin-bottom: 8px;
                  color: #002839;

                  &:last-child {
                    margin-bottom: 0;
                  }
                }
              }
            }
          }
        }

        p {
          border: 1px solid rgb(255 255 255 / 50%);
          border-radius: 50px;
          padding: 10px 24px;
          transition: border 0.5s ease;

        }

        &:hover {
          p {
            border: 1px solid rgb(255 255 255 / 100%);
            transition: border 0.5s ease;
          }
        }
      }

      &__quote {
        cursor: pointer;

        p {
          border: 1px solid rgb(255 255 255 / 50%);
          border-radius: 50px;
          padding: 10px 24px;
          transition: border 0.5s ease;
        }

        &:hover {
          p {
            border: 1px solid rgb(255 255 255 / 100%);
            transition: border 0.5s ease;
          }
        }
      }
    }

    &__menu {
      display: flex;
      align-items: center;
      gap: 26px;
      cursor: pointer;

      p {
        color: #FFFFFF;
        transition: color 0.5s ease;
      }

      ul {
        display: flex;
        height: 100%;

        li {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          cursor: pointer;

          // &:after {
          //   content: '';
          //   height: 100%;
          //   left: 0;
            //   background-color: ${whites};
          //   position: absolute;
          //   width: 0;
            //   transition: width .6s ${ease};
          // }

          a {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            height: 100%;
            width: 100%;
          }


          svg {
            position: relative;
            z-index: 3;

            path {
              transition: all .6s ease;
            }
          }

          //hover
          &:hover {
            &.slide:after, .slide:after {
              width: 100%;
            }

            path {
              //stroke: #212158;
              fill: #212158;
            }
          }
        }

        .hamburger {
          flex-direction: column;
          align-items: flex-end;
          width: 30px;

          span {
            height: 2px;
            width: 22px;
            background-color: ${whites};
            border-radius: 2px;
            display: block;
            position: relative;
            z-index: 2;
            transition: all .6s ${ease};

            &:nth-of-type(2) {
              margin: 5px 0;
              width: 30px;
            }

            &:nth-of-type(3) {
              width: 15px;
            }
          }

        }
      }

      &:hover {
        .hamburger {
          span {
            width: 15px;

            &:nth-of-type(2) {
              width: 22px;
            }

            &:nth-of-type(3) {
              width: 30px;
            }
          }
        }
      }
    }
  }

  .accordion {
    height: calc(100vh - 250px);
    overflow-y: scroll;
    ::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
    
    li:not(:last-child) {
      border-bottom: 1px solid rgb(255 255 255 / 50%);
      padding-bottom: 20px;
    }

    li:not(:first-child) {
      padding-top: 20px;
    }

    li {
      &.active{
        a{
          color: rgba(245, 245, 245, 1) !important;
        }
      }
      margin-right: 15px;
    }

    .accordion-header {
      border-bottom: 1px solid rgb(255 255 255 / 50%);
      margin-right: 15px;
    }

    .accordion-body {
      li {
        padding-top: 20px;
        &.active{
          a{
            color: rgba(245, 245, 245, 1) !important;
          }
        }
      }
    }
  }

  //slide menu 
  .slide-menu {
    position: fixed;
    height: 100vh;
    overflow: hidden;
    right: 0;
    top: 0px;
    z-index: 9999;
    background-color: #6EAFC4;

    //display: flex;
    align-items: center;

    transform: translateX(100%);
    flex-wrap: wrap;

    .col-md-8 {
      padding: 0;
      height: 100%;
    }

    .col-md-4 {
      padding: 0 0 0 100px;
      height: 100%;
      position: relative;
    }

    .social__icon {
      display: flex;
      gap: 20px;
      align-items: center;
    }

    &__icon {
      cursor: pointer;
      position: absolute;
      top: 40px!important;
      right: 0;
    }


    &__images {
      position: relative;
      padding-top: calc(768 / 700 * 100%);
      /* Initially set the image opacity to 0 to hide it */
      opacity: 0;
      transition: all 2s ease;
    }

    .hovered {
      opacity: 1;
      transition: all 2s ease; /* Add transition properties for opacity and transform */
    }


    &__close {
      position: absolute;
      top: 30px;
      right: 30px;
      cursor: pointer;
      z-index: 2;

      #Ellipse_637, line {
        transition: all .5s ease;
      }

      &:hover {
        line {
          stroke: ${text};
        }

        #Ellipse_637 {
          cy: -20;
        }

        #Group_23147 {
          cy: -10
        }
      }
    }


    a {
      font-size: 20px;
      line-height: 28px;
      color: rgba(245, 245, 245, .5) !important;

      &:hover {
        color: rgba(245, 245, 245, 1) !important;
      }
    }

    ul {
      width: 100%;
      padding-top: 140px;

      li {
        //&:not(:nth-last-child(1)) {
        //  margin-bottom: 20px;
        //}

        transform: translateY(20px);
        opacity: 0;

      }
    }

    .accordion-item {
      transform: translateY(20px);
      opacity: 0;
      background: unset;
      border: unset;

      .accordion-button {
        &:after {
          display: none;
        }
      }

      a {
        border: none
      }
      &.active{
        span{
          color: rgba(245, 245, 245, 1) !important;
        }
      }
    }

    .accordion-header {
      font-size: unset;

      button {
        background-color: transparent;
        padding: 20px 20px 0 0;
        box-shadow: none;
        border: none;
        font-size: 20px;
        line-height: 28px;
        color: rgba(245, 245, 245, 0.5) !important;
        font-family: ${title};
        margin-bottom: 20px;
        transition: color .6s ease;
        width: 100%;
        text-align: left;
        position: relative;
        display: flex;
        align-items: center;

        img {
          position: absolute;
          right: 0;
          transform: rotate(180deg);
        }


        &:hover {
          color: rgba(245, 245, 245, 1) !important;
        }

        &.collapsed {
          color: ${whites};

          img {
            transform: rotate(0deg);
          }
        }
      }
    }

    .social-component {
      position: absolute;
      bottom: 60px;
    }

    .accordion-body {
      margin-bottom: 20px;
      padding-left: 0;

      ul {
        padding-top: 0;
      }

      a {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
      }
    }
  }

  @media (min-width: 768px) {
    .goto-buttons {
      display: none;
    }
  }

  @media (max-width: 767px) {

    .accordion{
      overflow-y: unset;
      padding-bottom: 100px;
      height: 100%;
    }
    .social-component{
      display: none;
    }
    .main-menu__logo {
      width: 140px;
      height: 27px;
    }

    .slide-menu {
      display: block !important;
      padding: 0 15px 0 0 !important;
      width: 100% !important;

      ul {
        padding-top: 125px;
        //overflow-y: scroll;
      }

      .col-md-4 {
        padding-left: 15px;
      }

      &__icon {
        top: 30px;
      }
    }

    .main-menu-mb {
      padding: 0;
      display: flex;
      justify-content: flex-end;

      .main-menu__bar__menu {
        gap: 20px;
        text-transform: uppercase;
      }
    }

    .main-menu__logo {
      svg {
        width: 139px;
        height: 26px;
      }
    }

    .main-menu__bar {
      ul li {
        width: 55px;

        &:nth-of-type(2), &:nth-of-type(3) {
          display: none;
        }

        &.hamburger {
          padding-left: 19px;
          padding-right: 15px !important;
          width: 60px !important;
        }
      }
    }

    .slide-menu {
      padding-left: 30px;
      padding-right: 30px;
      padding-bottom: 30px;
      align-items: normal;
      padding-top: 100px;
      overflow-y: scroll;

      .goto-buttons {
        height: fit-content;

        img {
          width: 100%;
          display: block;

          &:nth-of-type(1) {
            margin-bottom: 20px;
          }
        }
      }
    }
  }


  //@media (max-width: 500px) {
  //  .slide-menu {
  //    display: block !important;
  //    width: 100%;
  //    padding-left: 15px;
  //    padding-right: 15px;
  //    padding-bottom: 15px;
  //    align-items: normal;
  //    padding-top: 100px;
  //
  //  }
  //}
  @media (min-width: 768px) and (max-width: 991px) {
    .tab-menu {
      padding: 0px 0px 0px 20px !important;
    }
  }

`;

export default MyComponent;
