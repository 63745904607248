import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const AnimatedIconMoon = () => {
    const [offset, setOffset] = useState(100);
    const containerRef = useRef(null);

    const updateOffset = () => {
        if (containerRef.current) {
            setOffset(containerRef.current.offsetLeft);
        }
    };

    useEffect(() => {
        updateOffset(); // Set initial offset

        const handleResize = () => {
            updateOffset(); // Update offset on window resize
        };

        window.addEventListener('resize', handleResize);

        return () => {
            // Cleanup event listener
            window.removeEventListener('resize', handleResize);
        };
    }, []); // Run useEffect only once on mount

    return (
        <StyledMessage offset={offset} className="sticky-menu-middle">
            {offset && (
                <div>
                    <a href="mailto:info@pacificblue-ac.com.au">
                        <div className="banner__body__svg">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="80"
                                 height="80" viewBox="0 0 80 80">
                                <defs>
                                    <filter id="Ellipse_214" x="0" y="0" width="80" height="80"
                                            filterUnits="userSpaceOnUse">
                                        <feOffset dy="5" input="SourceAlpha"/>
                                        <feGaussianBlur stdDeviation="5" result="blur"/>
                                        <feFlood flood-opacity="0.149"/>
                                        <feComposite operator="in" in2="blur"/>
                                        <feComposite in="SourceGraphic"/>
                                    </filter>
                                    <filter id="Ellipse_216" x="24" y="24" width="32" height="32"
                                            filterUnits="userSpaceOnUse">
                                        <feOffset dy="5" input="SourceAlpha"/>
                                        <feGaussianBlur stdDeviation="5" result="blur-2"/>
                                        <feFlood flood-opacity="0.149"/>
                                        <feComposite operator="in" in2="blur-2"/>
                                        <feComposite in="SourceGraphic"/>
                                    </filter>
                                </defs>
                                <g id="Message" transform="translate(15 10)">
                                    <g transform="matrix(1, 0, 0, 1, -15, -10)" filter="url(#Ellipse_214)">
                                        <circle id="Ellipse_214-2" data-name="Ellipse 214" cx="25" cy="25" r="25"
                                                transform="translate(15 10)" fill="#0384bb"/>
                                    </g>
                                    <g transform="matrix(1, 0, 0, 1, -15, -10)" filter="url(#Ellipse_216)">
                                        <circle id="Ellipse_216-2" data-name="Ellipse 216" cx="1" cy="1" r="1"
                                                transform="translate(39 34)" fill="#1e5c95" opacity="0"/>
                                    </g>
                                    <g id="Group_5731" data-name="Group 5731" transform="translate(247.239 -370.516)">
                                        <path id="Path_2194" data-name="Path 2194"
                                              d="M13.88,31.622H2.27A2.272,2.272,0,0,0,0,33.892V44.066a.588.588,0,0,0,.93.479l3.233-2.307a1.583,1.583,0,0,1,.924-.3H12.2a2.272,2.272,0,0,0,2.27-2.27V32.21A.588.588,0,0,0,13.88,31.622Zm-2.856,7.124H4.229a.588.588,0,0,1,0-1.176h6.795a.588.588,0,1,1,0,1.176Zm0-2.744H4.229a.588.588,0,0,1,0-1.176h6.795a.588.588,0,0,1,0,1.176Z"
                                              transform="translate(-232.275 355.097)" fill="#fff"/>
                                        <path id="Path_2195" data-name="Path 2195"
                                              d="M157.438,150.232v10.173a.588.588,0,0,1-.93.479l-3.233-2.306a1.583,1.583,0,0,0-.924-.3H145.24a2.273,2.273,0,0,1-2.27-2.27V154.9h6.593a3.45,3.45,0,0,0,3.446-3.446v-3.489h2.159A2.273,2.273,0,0,1,157.438,150.232Z"
                                              transform="translate(-369.64 243.318)" fill="#fff"/>
                                    </g>
                                </g>
                            </svg>
                        </div>
                    </a>
                </div>
            )}
        </StyledMessage>
    );
};

const StyledMessage = styled.div`
  .banner {
    &__body {
      &__svg {
        padding-right: ${props => (props.offset ? props.offset - 50 + 'px' : '100px')} !important;
      }
    }
  }
`;

export default AnimatedIconMoon;
