import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import Title from "../Title";
import React, {useEffect, useRef, useState} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import {Pagination, Navigation, EffectCreative, Autoplay} from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import {Link} from "react-router-dom";
import {Img} from "../Img";
import NavigationIcon from "../NavigationIcon";
import {body} from "../../styles/globalStyleVars";
import reactHtmlParser from "react-html-parser";
import Button from "../Button";

const RandomSliderV2 = ({data, dataTwo, conlist, title}) => {
    // slider data

    const [current, setCurrent] = useState('');

    const handleSliderCurrent = (swiper) => {
        if (swiper) {
            // Get the real current slide index starting from 1
            const realCurrentSlide = swiper.realIndex + 1;
            setCurrent(realCurrentSlide.toString());
        }
    };

    const [offset, setOffset] = useState(100)

    useEffect(() => {
        setOffset(document.querySelector('.container').offsetLeft)

        window.addEventListener('load', function () {
            setOffset(document.querySelector('.container').offsetLeft)

        })
        window.addEventListener('resize', function () {
            setOffset(document.querySelector('.container').offsetLeft)

        })
    }, [])

    // height control

    const sourceDivRef = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            const fullWidth = sourceDivRef.current.offsetWidth;
            const adjustedWidth = fullWidth - 50;
            document.documentElement.style.setProperty('--target-width', `${adjustedWidth}px`);
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    return (
        <StyledBlog className='amenities as-image-text-slider pb-200 ' offset={offset}>
            <Container>
                <Row>
                    <Col lg={12} className={'top'}>
                        <div className={'top-nav'}>
                            <div className={'top-nav__subtitle'}>
                                <p className={'split-up'}>{reactHtmlParser(dataTwo?.section_data?.subtitle)}</p>
                            </div>
                            <NavigationIcon next_id={'nextButton'} prev_id={'prevButton'}/>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={5} className={'mobile-hide'}>


                    </Col>
                    <Col className={'amenities__right'} lg={5}>
                        {data &&
                            <Swiper
                                Swiper
                                modules={[Autoplay, Pagination, Navigation, EffectCreative]}
                                loop={true}
                                // autoplay={{
                                //     delay: 12000,
                                //     disableOnInteraction: false,
                                // }}
                                // autoplay={false}
                                initialSlide={1}
                                pagination={{
                                    el: '.swiper-pagination',
                                    type: "fraction",
                                }}

                                navigation={{
                                    prevEl: '#prevButton',
                                    nextEl: '#nextButton',
                                }}
                                onBeforeInit={(swiper) => {
                                    swiper.params.navigation.prevEl = '#prevButton';
                                    swiper.params.navigation.nextEl = '#nextButton';
                                }}

                                speed={700}
                                onSlideChange={(s) => handleSliderCurrent()}

                            >

                                {data?.length > 0 && data?.map((i, index) => (
                                    <SwiperSlide key={index}>
                                        <h2 className={'split-up'}>{reactHtmlParser(i?.product_data?.subtitle)}</h2>
                                        <p className={'split-up'}>{reactHtmlParser(i?.product_data?.slide_desc)}</p>
                                    </SwiperSlide>
                                ))}

                            </Swiper>
                        }
                    </Col>

                    <Col className={'amenities__big'} lg={{offset: 1, span: 6}} ref={sourceDivRef}>
                        <div className="amenities__big__slider">
                            {data &&
                                <Swiper
                                    Swiper
                                    modules={[Autoplay, Pagination, Navigation, EffectCreative]}
                                    loop={true}
                                    // autoplay={{
                                    //     delay: 12000,
                                    //     disableOnInteraction: false,
                                    // }}

                                    // autoplay={false}
                                    slidesPerView={2}
                                    spaceBetween={30}
                                    initialSlide={1}
                                    pagination={false}
                                    navigation={{
                                        prevEl: '#prevButton',
                                        nextEl: '#nextButton',
                                    }}
                                    onBeforeInit={(swiper) => {
                                        swiper.params.navigation.prevEl = '#prevButton';
                                        swiper.params.navigation.nextEl = '#nextButton';
                                    }}

                                    speed={500}
                                    className={'image-slider'}
                                    onSlideChange={(s) => handleSliderCurrent()}
                                >
                                    {data?.length > 0 && data?.map((i, index) => (
                                        <SwiperSlide>
                                            <Link to={`/products/${i?.product_data?.slug}`}>
                                                <div className="single">
                                                    <div className="single__img">
                                                        <Img
                                                            src={i?.images?.list?.[0]?.full_path ? i?.images?.list?.[0]?.full_path : ''}/>
                                                    </div>
                                                </div>
                                            </Link>
                                        </SwiperSlide>
                                    ))}

                                </Swiper>
                            }
                        </div>

                    </Col>


                </Row>

                <div className={'button'}>
                    <Button src={'/products'} text={'View All'} background={'transparent'} color={'#ffffff'}
                            border={'1px solid white'} hoverBorder={'1px solid #6EAFC4'}/>
                </div>
            </Container>

        </StyledBlog>
    );
};

const StyledBlog = styled.section`
  position: relative;
  overflow: hidden;
  background-color: #EEEEEE;
  margin-bottom: -2px;
  z-index: 1;

  .button {
    position: absolute;
    bottom: 120px;
    @media (max-width: 991px) {
      position: unset;
      bottom: unset;
      padding-top: 60px;
    }
  }

  .top-nav {
    display: flex;
    justify-content: space-between;
    margin-bottom: 60px;
    padding-bottom: 20px;
    position: relative;

    &:after {
      content: '';
      bottom: 0;
      left: 0;
      position: absolute;
      padding-right: 15px;
      height: 1px;
      width: 100%;
      background: #C4C4C4;
    }

    &__subtitle {
      display: flex;
      align-items: center;
    }
  }


  &:before {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 56%;
    background: #6EAFC4;
    z-index: -1;
    content: "";
  }

  .container {
    position: relative;

    .col-lg-4 {
      padding-left: 15px;
    }

    .counter {
      p {
        font-size: 24px;
        font-weight: 400;
        line-height: 36px;
        color: #F7F3F0;
      }
    }

    .slider-nav {
      position: absolute;
      bottom: 0px;
      left: 15px;
      z-index: 3;

      .hovercenter {

        &:after {
          background-color: white;
        }
      }


      ul {
        display: flex;

        li {
          height: 48px;
          width: 48px;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: center;
          border-radius: 50%;
          cursor: pointer;
          border: 1px solid #FFFFFF;
          z-index: 9999999;

          &:nth-of-type(1) {
            margin-right: 15px;
          }

          svg {
            z-index: 2;
          }

          &:hover {

            svg {
              g {
                line {
                  stroke: #16322D;
                }
              }
            }
          }

        }

      }


    }
  }

  .amenities__big {
    position: relative;
    padding: 0;

    &__slider {
      position: relative;
      margin-right: -${props => props.offset || '150'}px !important;

      .shadow {
        position: absolute;
        bottom: -50px;
        right: calc(100% - 43px);
        z-index: 2;
        width: 120px;

        img {
          width: 100%;
          object-fit: cover;
        }
      }

      .shadow-right {
        position: absolute;
        bottom: -52px;
        right: -49px;
      }

      .single {
        overflow: hidden;

        &__img {
          padding-top: calc(600 / 570 * 100%);
          position: relative;


        }
      }


    }

    .swiper-slide-next {
      width: 30%;
    }

    .swiper-slide-active {
      margin-bottom: unset !important;

      .single {
        overflow: hidden;

        &__img {
          transition: all 0.4s ease;
          //
          //&:hover {
          //  transform: scale(.01);
          //  transition: all 0.4s ease-in-out;
          //}


        }
      }

      height: auto;


      width: calc(100% - 198px) !important;

      .single__image {
        width: 100%;
        height: 100%;

      }
    }

    .swiper {
      height: var(--target-width) !important;
      padding-right: calc(${props => props.offset - 85 || '100'}px) !important;
    }

    .swiper-slide {
      transition: all 0.7s ease-in-out;
      margin-bottom: 35%;
    }


  }

  .amenities__right {
    position: absolute;

    h2 {
      margin-bottom: 20px;
    }

    p {
      color: #002839;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
      white-space: normal;
    }

  }

  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }


  @media (max-width: 1500px) {

    .slider-text {
      h3 {
        font-size: 50px;
      }
    }
  }

  @media (max-width: 1200px) {

    .slider-text {
      h3 {
        font-size: 45px;
        margin-bottom: 50px !important;
      }
    }
  }


  @media (max-width: 991px) {

    .amenities__big .swiper {
      padding-right: 0 !important;
    }

    .amenities__big .swiper-slide {
      margin-bottom: unset !important;
    }

    .swiper-slide {

      p {
        color: #ffffff;
        padding-right: 15px;
      }
    }

    .swiper {
      height: unset !important;
    }

    .swiper-slide-next {
      height: 100% !important;
      width: 100% !important;
    }

    padding-bottom: 100px;

    &:before {
      height: 40%;
    }

    .container {
      padding-left: 15px !important;

      .col-lg-4 {
        padding-left: 15px;
      }
    }

    .single__img {
      padding-top: calc(600 / 570 * 100%) !important;
    }

    .mobile-flex {
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
      margin-bottom: 40px;
    }


    .amenities__right {
      position: unset;
      flex: 0 0 100%;
      max-width: 100%;
      margin-top: 40px;
    }

    .swiper-slide-next {
      height: 100% !important;
      width: 100% !important;
    }

    .amenities__big__slider {
      margin-right: 0 !important;

      .swiper-slide-active {
        height: unset !important;
        width: 100% !important;

        .single__image {
          width: 100%;
          height: 100%;

        }
      }
    }

    .amenities__right {
      margin-top: 0;

      .swiper-slide {
        p {
          color: ${body};
          margin-bottom: 40px;
        }
      }
    }

    .slider-nav {
      ul {
        li {
          border-color: #1C3700 !important;

          svg {
            g {
              line {
                stroke: #1C3700;
              }
            }
          }
        }
      }

    }
  }

  @media (max-width: 767px) {

    &:before {
      height: 45% !important;
    }

    .mobile-hide {
      display: none;
    }

    .top-nav {
      padding: 0 0 20px 0;
      flex-direction: column;
      gap: 30px;
      margin-bottom: 40px;
    }

    .slider-text {
      h2 {
        margin-bottom: 0 !important;
      }
    }

    .amenities__big__slider {
      padding: 0 15px;
    }
  }


  @media (max-width: 514px) {
    &:before {
      height: 55%;
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    .image-slider {
      .swiper-slide {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }


`;
export default React.memo(RandomSliderV2);
