import React from "react";
import styled from "styled-components";
import {useDispatch} from "react-redux";
import {Col, Container, Row} from "react-bootstrap";
import {Img} from "../Img";
import Title from "../Title";
import Button from "../Button";
import reactHtmlParser from "react-html-parser";

const Overview = ({data}) => {
    const dispatch = useDispatch();

    return (
        <StyeldSection data-scroll-section className="text_img_block pt-150 pb-150">
            <Container className="reveal-up">
                <Row className="">
                    <Col md={5} className="text_img_block__left">
                        <div className="text_img_block__left__img">
                            <Img src={data?.images?.list?.[0]?.full_path}/>

                        </div>
                    </Col>
                    <Col md={{span: 6, offset: 1}} className={'text_img_block__right'}>
                        <Title margin={'0 0 50px 0'} text={data?.section_data?.subtitle}/>

                            <p className={'fade-up'}>{reactHtmlParser(data?.section_data?.description)}</p>
                    </Col>
                </Row>
            </Container>
        </StyeldSection>
    );
};

const StyeldSection = styled.section`
  background: #F2F2F2;

  .text_img_block__left__img {
    padding-top: calc(530 / 500 * 100%);
    position: relative;
    height: 100%;
    @media (max-width: 1024px) {
      height: auto;
    }
  }

  .text_img_block__right {
    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
      margin-bottom: 0;
    }
  }

  @media (max-width: 767px) {
    .row {
      flex-direction: column-reverse;
    }

    .text_img_block__right {
      margin-bottom: 60px;
    }
  }

`;

export default Overview;
