import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {get,post} from "../../network/axiosServices";


const initialState = {
    loading: false,
    posts: [],
    error: '',
    success:'',
    info: [],
    infoLoading: false,
    infoError : '',
    opacity: 0, // Add opacity field
}

export const fetchHome = createAsyncThunk("fetchHome", (params, {dispatch}) => {
    return get(params);
});
export const homepostForm = createAsyncThunk("homeForm", (params) => {
    return post(params);
});


const postSlice = createSlice({
    name: 'home',
    initialState,
    reducers: {
        setOpacity: (state, action) => {
            state.opacity = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchHome.pending, (state) => {
            state.loading = true
            state.posts = []
            state.error = ''
        })
        builder.addCase(fetchHome.fulfilled, (state, action) => {
            state.loading = false
            state.posts = action.payload
            state.error = ''

        })
        builder.addCase(fetchHome.rejected, (state, action) => {
            state.loading = false
            state.posts = []
            state.error = action.error
        })
        builder.addCase(homepostForm.pending, (state) => {
            state.loading = true;
            state.success = [];
            state.error = "";
        });
        builder.addCase(homepostForm.fulfilled, (state, action) => {
            state.loading = false;
            state.success = action.payload.message;
            state.error = "";

        });
        builder.addCase(homepostForm.rejected, (state, action) => {
            state.loading = false;
            state.success = '';
            state.error = action.error;

        });
    }
})
export default postSlice.reducer
