import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Col, Container, Row} from 'react-bootstrap';

// Internal Component
import Title from "../Title";
import {Img} from "../Img";
import reactHtmlParser from "react-html-parser";

// Image Import

const OurExpertise = ({data}) => {

    console.log('OurExpertise', data)


    const [BgHeight, setBgHeight] = useState(false);
    const ref = useRef(null);
    const refMinus = useRef(null);

    useEffect(() => {
        let height = refMinus.current.offsetHeight / 2;
        height = ref.current.offsetHeight - height;

        if (window.innerWidth < 767) {
            setBgHeight(height - 80);

        } else {
            setBgHeight(height - 150);

        }


    }, []);
    return (
        <StyledListsV4 className={'pt-150 pb-150'} ref={ref} BgHeight={BgHeight}>
            <Container>
                <Row className={'mission-vision-hide'} ref={refMinus}>

                </Row>
                <Row>
                    <div className="our_values">
                        <Col md={12}>
                            <h3 className={'title'}>
                                {data?.section_data?.subtitle}
                            </h3>
                        </Col>

                    </div>

                    <div className="d-flex values">
                        <Col md={6}>
                            <div className="content_wrapper fade-up">
                                <ul>
                                    {reactHtmlParser(data?.section_data?.description)}
                                </ul>
                            </div>
                        </Col>
                        <Col md={6} className="values__image fade-up">
                            <div className="image_wrapper Loader">
                                <Img src={data?.images?.list?.[0]?.full_path}/>

                            </div>
                        </Col>
                    </div>
                </Row>
            </Container>
        </StyledListsV4>
    );
};

const StyledListsV4 = styled.section`

  background: #FFFFFF;

  position: relative;


  &:after {
    background: ${props => props.bgColor ? props.bgColor : '#E9E9E9'};
    //content: "";
    left: 0;
    right: 0;
    width: 100%;
    bottom: 0;
    position: absolute;
    height: ${props => props.BgHeight}px;

  }

  //if want to show mission and vision then uncomment this
  .mission-vision-hide {
    display: none;
  }

  .content_wrapper {
    //background:linear-gradient(140deg, rgba(173,0,0,1) 0%, rgba(255,0,0,1) 100%);
    background: linear-gradient(135deg, rgb(173, 0, 0) 0%, rgb(255, 0, 0) 100%);
    height: 100%;
    padding: 100px 60px 75px;

    h3 {
      color: #FFFFFF;
      font-weight: 600;
      margin: 0 0 40px;
      text-transform: uppercase;
      font-size: 30px;

    }

    p {
      font-weight: 500;
      color: #FFFFFF;
      font-size: 18px;
      line-height: 24px;
    }
  }

  .title {
    margin-bottom: 60px!important;
  }

  .container {
    position: relative;
    z-index: 1;
  }

  .our_values {
    width: 100%;

    h3 {
      margin-bottom: 80px;
      padding-bottom: 20px;
      font-weight: 400;
      position: relative;

      &:after {
        content: '';
        bottom: 0;
        left: 0;
        right: 15px;
        position: absolute;
        background: rgb(0 40 57 / 30%);
        width: 100%;
        height: 1px;
      }
    }

  }

  .values {
    width: 100%;

    .content_wrapper {
      background: transparent;
      padding: 0;
    }


    .image_wrapper {
      position: relative;
      padding-top: calc(450 / 570 * 100%);

      @media (min-width: 1550px) {
        width: 100%;
      }
    }

    &__image {
      padding-left: 70px;
      @media (min-width: 1550px) {
        display: flex;

      }
      @media (max-width: 767px) {
        padding-left: 0;
        margin-bottom: 40px;
      }
    }

    p {
      color: #222222;
      font-size: 20px;
      font-weight: 500;
      line-height: 30px;
      margin: 0 0 60px;
      @media (max-width: 767px) {
        margin: 0px 0 40px;


      }
    }

    ul {
      padding-left: 0;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: 0 0 60px;

      li {

        position: relative;
        counter-increment: count;
        padding: 0 0px 20px 0px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.3);
        margin: 0 0 30px;
        width: 100%;
        font-weight: 400;
        line-height: 24px;
        font-size: 16px;
        display: flex;
        gap: 20px;

        p {
          width: 25px;
          height: 25px;
          color: white;
          background: #002839;
          text-align: center;
          border-radius: 50px;
          margin-bottom: unset;
          font-size: 12px;
          line-height: 18px;
          font-weight: 500;
          padding: 4px 8px;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      @media (max-width: 767px) {
        margin: 0 0 40px;
        &:last-child {
          margin-bottom: 0;
        }

        li {
          flex: 0 0 100%;

          &:last-child {
            margin-bottom: 0;
          }
        }

      }
    }
  }


  @media (max-width: 767px) {
    .title {
      margin-bottom: 40px!important;
    }

    .content_wrapper {
      padding: 40px 0px 60px 0px !important;
    }

    .mission_vision_single {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .values {
      flex-direction: column;

      .image_wrapper {
        padding-top: calc(350 / 375 * 100%);

      }
    }
  }

`;

export default OurExpertise;
