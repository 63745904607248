import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import Button from "../Button";

import {Img} from "../Img";
import reactHtmlParser from "react-html-parser";
import SimpleBar from "simplebar-react";
import Modal from "react-bootstrap/Modal";
import ModalVideo from "react-modal-video";
import {gsap} from "gsap";
import ScrollTrigger from 'gsap/ScrollTrigger';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import {Swiper, SwiperSlide} from "swiper/react";
import {Mousewheel, Navigation, Pagination} from "swiper";
import {LightgalleryItem} from "react-lightgallery";
import 'swiper/css';
import 'swiper/css/navigation';
import "swiper/css/pagination";
import 'swiper/css/scrollbar';
import SocialIcon from "../SocialIcon";
import SocialIconDetailsPage from "../SocialIconDetailsPage";
import VideoModal from "../Product/VideoModal";
import NavigationIcon from "../NavigationIcon";
import {useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton} from "react-share";

const MediaDetails = ({data}) => {
    let [open, setOpen] = useState(false);
    const location = useLocation();
    const [shareUrl, setShareUrl] = useState('')

    useEffect(() => {
        setShareUrl(window.location.href)
    }, [])
    const [width, setWidth] = useState(0)
    useEffect(() => {
        setWidth(window.innerWidth)
    }, [])


    let getPost = useSelector((state) => state);
    // for text
    const textData = data?.filter(i => i?.section_data?.short_desc === 'text')
    const textArray = textData?.[0]?.posts?.list;

    // for table
    const tableData = data?.filter(i => i?.section_data?.short_desc === 'table')
    const tableArray = tableData?.[0]?.posts?.list;

    // for blockquote
    const blockquoteData = data?.filter(i => i?.section_data?.short_desc === 'blockquote')

    // for images
    const imagesData = data?.filter(i => i?.section_data?.short_desc === 'image')

    // for list
    const listData = data?.filter(i => i?.section_data?.short_desc === 'list')

    // for video
    const videoData = data?.filter(i => i?.section_data?.short_desc === 'video')
    const pdf = data?.filter(i => i?.section_data?.short_desc === 'pdf')

    let handelOpen = (open, id) => {
        setOpen(open);
        setShow(true)
    };


    let [videoId, setVideo] = useState('');


    const [show, setShow] = useState(false);
    const [popupId, setPopupId] = useState()

    const handleClose = () => setShow(false);
    const handleShow = (e) => {
        setShow(true)
        setPopupId(e)
        setVideo(true)
    }

    const handleCloseModal = () => {
        setOpen(false);
        handleClose();
    };

    const next = () => {
        document.querySelector('.portfolio-details .mySwiper .swiper-button-next').click();

    }

    const prev = () => {
        document.querySelector('.portfolio-details .mySwiper .swiper-button-prev').click();
    }


    // sticky social icon
    gsap.registerPlugin(ScrollTrigger);


    useLayoutEffect(() => {
        const col4Element = document.querySelector('.detail-page');
        gsap.to(col4Element, {
            scrollTrigger: {
                trigger: col4Element,
                start: 'top +100',
                end: 'bottom +300',
                pin: true,
                pinSpacing: false
            }
        });

    }, [location.pathname]);

    return (
        <StyledComponent className='portfolio-details pt-150 pb-150'>
            <Container>
                <Row>
                    <Col lg={2} className={'detail-page'}>
                        <div className={'detail-page__left'}>
                            <p className={'detail-page__left__text'}>Share:</p>
                            <div>
                                <div className={'social__icon'}>
                                    <div className={'social__icon__icons'}>
                                        <FacebookShareButton url={shareUrl}>
                                            <div className={'social__icon__icons__facebook'}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25"
                                                     viewBox="0 0 25 25">
                                                    <g id="Group_18597" data-name="Group 18597"
                                                       transform="translate(-98 -848)">
                                                        <g id="Rectangle_1998" data-name="Rectangle 1998"
                                                           transform="translate(98 848)" fill="none" stroke="#002839"
                                                           stroke-width="1">
                                                            <rect width="25" height="25" rx="12.5" stroke="none"/>
                                                            <rect x="0.5" y="0.5" width="24" height="24" rx="12"
                                                                  fill="none"/>
                                                        </g>
                                                        <path id="Path_26" data-name="Path 26"
                                                              d="M1012.361,3918.425v-.9a1.965,1.965,0,0,1,.019-.315.612.612,0,0,1,.086-.219.351.351,0,0,1,.216-.149,1.551,1.551,0,0,1,.4-.041h.9V3915h-1.441a2.344,2.344,0,0,0-1.8.594,2.5,2.5,0,0,0-.546,1.75v1.086h-1.08v1.8h1.079v5.233h2.166v-5.233h1.442l.19-1.8Z"
                                                              transform="translate(-901.116 -3059.731)" fill="#002839"/>
                                                    </g>
                                                    <g id="Group_18598" data-name="Group 18597"
                                                       transform="translate(-98 -848)">
                                                        <g id="Rectangle_1999" data-name="Rectangle 1998"
                                                           transform="translate(98 848)" fill="none" stroke="#002839"
                                                           stroke-width="1">
                                                            <rect width="25" height="25" rx="12.5" stroke="none"/>
                                                            <rect x="0.5" y="0.5" width="24" height="24" rx="12"
                                                                  fill="none"/>
                                                        </g>
                                                        <path id="Path_27" data-name="Path 26"
                                                              d="M1012.361,3918.425v-.9a1.965,1.965,0,0,1,.019-.315.612.612,0,0,1,.086-.219.351.351,0,0,1,.216-.149,1.551,1.551,0,0,1,.4-.041h.9V3915h-1.441a2.344,2.344,0,0,0-1.8.594,2.5,2.5,0,0,0-.546,1.75v1.086h-1.08v1.8h1.079v5.233h2.166v-5.233h1.442l.19-1.8Z"
                                                              transform="translate(-901.116 -3059.731)" fill="#002839"/>
                                                    </g>
                                                </svg>
                                            </div>
                                        </FacebookShareButton>
                                    </div>

                                    <div className={'social__icon__icons'}>
                                        <TwitterShareButton url={shareUrl}>
                                            <div className={'social__icon__icons__twitter'}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25"
                                                     viewBox="0 0 25 25">
                                                    <g id="Group_18596" data-name="Group 18596"
                                                       transform="translate(-98 -893)">
                                                        <g id="Rectangle_1998" data-name="Rectangle 1998"
                                                           transform="translate(98 893)" fill="none" stroke="#002839"
                                                           stroke-width="1">
                                                            <rect width="25" height="25" rx="12.5" stroke="none"/>
                                                            <rect x="0.5" y="0.5" width="24" height="24" rx="12"
                                                                  fill="none"/>
                                                        </g>
                                                        <path id="Path_5915" data-name="Path 5915"
                                                              d="M453.489,20.125a6.955,6.955,0,0,0,7-7q0-.16-.007-.318a5.011,5.011,0,0,0,1.228-1.274,4.912,4.912,0,0,1-1.413.387,2.47,2.47,0,0,0,1.082-1.362,4.933,4.933,0,0,1-1.563.6,2.464,2.464,0,0,0-4.195,2.245,6.988,6.988,0,0,1-5.073-2.571,2.463,2.463,0,0,0,.762,3.286A2.444,2.444,0,0,1,450.2,13.8c0,.01,0,.021,0,.031a2.462,2.462,0,0,0,1.975,2.413,2.459,2.459,0,0,1-1.111.042,2.464,2.464,0,0,0,2.3,1.71,4.938,4.938,0,0,1-3.057,1.054,5,5,0,0,1-.587-.034,6.968,6.968,0,0,0,3.773,1.106"
                                                              transform="translate(-344.716 890.625)" fill="#002839"/>
                                                    </g>
                                                    <g id="Group_18595" data-name="Group 18596"
                                                       transform="translate(-98 -893)">
                                                        <g id="Rectangle_1998" data-name="Rectangle 1998"
                                                           transform="translate(98 893)" fill="none" stroke="#002839"
                                                           stroke-width="1">
                                                            <rect width="25" height="25" rx="12.5" stroke="none"/>
                                                            <rect x="0.5" y="0.5" width="24" height="24" rx="12"
                                                                  fill="none"/>
                                                        </g>
                                                        <path id="Path_5915" data-name="Path 5915"
                                                              d="M453.489,20.125a6.955,6.955,0,0,0,7-7q0-.16-.007-.318a5.011,5.011,0,0,0,1.228-1.274,4.912,4.912,0,0,1-1.413.387,2.47,2.47,0,0,0,1.082-1.362,4.933,4.933,0,0,1-1.563.6,2.464,2.464,0,0,0-4.195,2.245,6.988,6.988,0,0,1-5.073-2.571,2.463,2.463,0,0,0,.762,3.286A2.444,2.444,0,0,1,450.2,13.8c0,.01,0,.021,0,.031a2.462,2.462,0,0,0,1.975,2.413,2.459,2.459,0,0,1-1.111.042,2.464,2.464,0,0,0,2.3,1.71,4.938,4.938,0,0,1-3.057,1.054,5,5,0,0,1-.587-.034,6.968,6.968,0,0,0,3.773,1.106"
                                                              transform="translate(-344.716 890.625)" fill="#002839"/>
                                                    </g>
                                                </svg>
                                            </div>
                                        </TwitterShareButton>
                                    </div>

                                    <div className={'social__icon__icons'}>
                                        <div className={'social__icon__icons__insta'}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25"
                                                 viewBox="0 0 25 25">
                                                <g id="Group_18595" data-name="Group 18595"
                                                   transform="translate(-98 -938)">
                                                    <g id="Rectangle_1998" data-name="Rectangle 1998"
                                                       transform="translate(98 938)" fill="none" stroke="#002839"
                                                       stroke-width="1">
                                                        <rect width="25" height="25" rx="12.5" stroke="none"/>
                                                        <rect x="0.5" y="0.5" width="24" height="24" rx="12"
                                                              fill="none"/>
                                                    </g>
                                                    <g id="Group_18368" data-name="Group 18368"
                                                       transform="translate(-8230 -228)">
                                                        <path id="Path_5912" data-name="Path 5912"
                                                              d="M5.593,1.008c1.493,0,1.67.006,2.26.033a3.093,3.093,0,0,1,1.038.193,1.733,1.733,0,0,1,.643.418,1.733,1.733,0,0,1,.418.643,3.1,3.1,0,0,1,.193,1.038c.027.59.033.767.033,2.26s-.006,1.67-.033,2.26a3.1,3.1,0,0,1-.193,1.038A1.852,1.852,0,0,1,8.891,9.953a3.093,3.093,0,0,1-1.038.193c-.59.027-.767.033-2.26.033s-1.67-.006-2.26-.033a3.093,3.093,0,0,1-1.038-.193,1.733,1.733,0,0,1-.643-.418,1.733,1.733,0,0,1-.418-.643A3.093,3.093,0,0,1,1.04,7.853c-.027-.59-.033-.767-.033-2.26s.006-1.67.033-2.26a3.093,3.093,0,0,1,.193-1.038,1.733,1.733,0,0,1,.418-.643,1.733,1.733,0,0,1,.643-.418A3.093,3.093,0,0,1,3.333,1.04c.59-.027.767-.033,2.26-.033M5.593,0C4.074,0,3.883.006,3.287.034a4.106,4.106,0,0,0-1.358.26A2.741,2.741,0,0,0,.939.939a2.741,2.741,0,0,0-.645.991,4.106,4.106,0,0,0-.26,1.358C.006,3.883,0,4.074,0,5.593S.006,7.3.034,7.9a4.106,4.106,0,0,0,.26,1.358,2.741,2.741,0,0,0,.645.991,2.743,2.743,0,0,0,.991.645,4.106,4.106,0,0,0,1.358.26c.6.027.787.034,2.306.034S7.3,11.179,7.9,11.152a4.106,4.106,0,0,0,1.358-.26,2.86,2.86,0,0,0,1.636-1.636,4.106,4.106,0,0,0,.26-1.358c.027-.6.034-.787.034-2.306s-.006-1.709-.034-2.306a4.106,4.106,0,0,0-.26-1.358,2.743,2.743,0,0,0-.645-.991A2.741,2.741,0,0,0,9.256.294,4.106,4.106,0,0,0,7.9.034C7.3.006,7.112,0,5.593,0"
                                                              transform="translate(8335 1173)" fill="#002839"/>
                                                        <path id="Path_5913" data-name="Path 5913"
                                                              d="M23.548,20.676a2.872,2.872,0,1,0,2.872,2.872,2.872,2.872,0,0,0-2.872-2.872m0,4.736a1.864,1.864,0,1,1,1.864-1.864,1.864,1.864,0,0,1-1.864,1.864"
                                                              transform="translate(8317.045 1155.045)" fill="#002839"/>
                                                        <path id="Path_5914" data-name="Path 5914"
                                                              d="M61.429,15.384a.671.671,0,1,1-.671-.671.671.671,0,0,1,.671.671"
                                                              transform="translate(8282.82 1160.224)" fill="#002839"/>
                                                    </g>
                                                </g>
                                                <g id="Group_18594" data-name="Group 18595"
                                                   transform="translate(-98 -938)">
                                                    <g id="Rectangle_1990" data-name="Rectangle 1998"
                                                       transform="translate(98 938)" fill="none" stroke="#002839"
                                                       stroke-width="1">
                                                        <rect width="25" height="25" rx="12.5" stroke="none"/>
                                                        <rect x="0.5" y="0.5" width="24" height="24" rx="12"
                                                              fill="none"/>
                                                    </g>
                                                    <g id="Group_18368" data-name="Group 18368"
                                                       transform="translate(-8230 -228)">
                                                        <path id="Path_5912" data-name="Path 5912"
                                                              d="M5.593,1.008c1.493,0,1.67.006,2.26.033a3.093,3.093,0,0,1,1.038.193,1.733,1.733,0,0,1,.643.418,1.733,1.733,0,0,1,.418.643,3.1,3.1,0,0,1,.193,1.038c.027.59.033.767.033,2.26s-.006,1.67-.033,2.26a3.1,3.1,0,0,1-.193,1.038A1.852,1.852,0,0,1,8.891,9.953a3.093,3.093,0,0,1-1.038.193c-.59.027-.767.033-2.26.033s-1.67-.006-2.26-.033a3.093,3.093,0,0,1-1.038-.193,1.733,1.733,0,0,1-.643-.418,1.733,1.733,0,0,1-.418-.643A3.093,3.093,0,0,1,1.04,7.853c-.027-.59-.033-.767-.033-2.26s.006-1.67.033-2.26a3.093,3.093,0,0,1,.193-1.038,1.733,1.733,0,0,1,.418-.643,1.733,1.733,0,0,1,.643-.418A3.093,3.093,0,0,1,3.333,1.04c.59-.027.767-.033,2.26-.033M5.593,0C4.074,0,3.883.006,3.287.034a4.106,4.106,0,0,0-1.358.26A2.741,2.741,0,0,0,.939.939a2.741,2.741,0,0,0-.645.991,4.106,4.106,0,0,0-.26,1.358C.006,3.883,0,4.074,0,5.593S.006,7.3.034,7.9a4.106,4.106,0,0,0,.26,1.358,2.741,2.741,0,0,0,.645.991,2.743,2.743,0,0,0,.991.645,4.106,4.106,0,0,0,1.358.26c.6.027.787.034,2.306.034S7.3,11.179,7.9,11.152a4.106,4.106,0,0,0,1.358-.26,2.86,2.86,0,0,0,1.636-1.636,4.106,4.106,0,0,0,.26-1.358c.027-.6.034-.787.034-2.306s-.006-1.709-.034-2.306a4.106,4.106,0,0,0-.26-1.358,2.743,2.743,0,0,0-.645-.991A2.741,2.741,0,0,0,9.256.294,4.106,4.106,0,0,0,7.9.034C7.3.006,7.112,0,5.593,0"
                                                              transform="translate(8335 1173)" fill="#002839"/>
                                                        <path id="Path_5913" data-name="Path 5913"
                                                              d="M23.548,20.676a2.872,2.872,0,1,0,2.872,2.872,2.872,2.872,0,0,0-2.872-2.872m0,4.736a1.864,1.864,0,1,1,1.864-1.864,1.864,1.864,0,0,1-1.864,1.864"
                                                              transform="translate(8317.045 1155.045)" fill="#002839"/>
                                                        <path id="Path_5914" data-name="Path 5914"
                                                              d="M61.429,15.384a.671.671,0,1,1-.671-.671.671.671,0,0,1,.671.671"
                                                              transform="translate(8282.82 1160.224)" fill="#002839"/>
                                                    </g>
                                                </g>
                                            </svg>


                                        </div>
                                    </div>

                                    <div className={'social__icon__icons'}>
                                        <LinkedinShareButton url={shareUrl}>
                                        <div className={'social__icon__icons__youtube linkedin'}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25"
                                                 viewBox="0 0 25 25">
                                                <g id="Group_18594" data-name="Group 18594"
                                                   transform="translate(-98 -983)">
                                                    <g id="Rectangle_1998" data-name="Rectangle 1998"
                                                       transform="translate(98 983)" fill="none" stroke="#002839"
                                                       stroke-width="1">
                                                        <rect width="25" height="25" rx="12.5" stroke="none"/>
                                                        <rect x="0.5" y="0.5" width="24" height="24" rx="12"
                                                              fill="none"/>
                                                    </g>
                                                    <path id="LinkedIn"
                                                          d="M1096.5,3921.554v3.313h-1.942v-3.091c0-.776-.281-1.306-.984-1.306a1.061,1.061,0,0,0-1,.7,1.3,1.3,0,0,0-.065.469v3.226h-1.943s.026-5.234,0-5.777h1.943v.82l-.013.018h.013v-.018a1.932,1.932,0,0,1,1.751-.955C1095.539,3918.955,1096.5,3919.781,1096.5,3921.554Zm-7.964-5.249a1,1,0,1,0-.026,2h.013a1,1,0,1,0,.013-2Zm-.984,8.561h1.943v-5.777h-1.943Z"
                                                          transform="translate(-981.635 -2925.55)" fill="#002839"/>
                                                </g>
                                                <g id="Group_18589" data-name="Group 18594"
                                                   transform="translate(-98 -983)">
                                                    <g id="Rectangle_1999" data-name="Rectangle 1998"
                                                       transform="translate(98 983)" fill="none" stroke="#002839"
                                                       stroke-width="1">
                                                        <rect width="25" height="25" rx="12.5" stroke="none"/>
                                                        <rect x="0.5" y="0.5" width="24" height="24" rx="12"
                                                              fill="none"/>
                                                    </g>
                                                    <path id="LinkedIn"
                                                          d="M1096.5,3921.554v3.313h-1.942v-3.091c0-.776-.281-1.306-.984-1.306a1.061,1.061,0,0,0-1,.7,1.3,1.3,0,0,0-.065.469v3.226h-1.943s.026-5.234,0-5.777h1.943v.82l-.013.018h.013v-.018a1.932,1.932,0,0,1,1.751-.955C1095.539,3918.955,1096.5,3919.781,1096.5,3921.554Zm-7.964-5.249a1,1,0,1,0-.026,2h.013a1,1,0,1,0,.013-2Zm-.984,8.561h1.943v-5.777h-1.943Z"
                                                          transform="translate(-981.635 -2925.55)" fill="#002839"/>
                                                </g>
                                            </svg>
                                        </div>
                                        </LinkedinShareButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>

                    <Col lg={9} className={'left-content'}>
                        {pdf?.length > 0 &&
                            <Row>
                                <Col>
                                    <h5>{reactHtmlParser(pdf?.[0]?.section_data?.description)}</h5>
                                    <Button src={pdf?.[0]?.files?.list?.[0]?.full_path} text={'Download PDF'}
                                            background={'#EEEEEE'} color={'#002839'} border={'1px solid #002839'}
                                            target={'_blank'} hoverBackground={'#002839'}
                                            hoverBorder={'1px solid #002839'} margin={'40px 0'}/>
                                </Col>
                            </Row>
                        }

                        <Row className={'upper-text'}>
                            <Col md={12}>
                                {textArray &&
                                    textArray?.length > 0 && textArray?.map(i => (
                                        <>
                                            <h5>{reactHtmlParser(i?.data?.subtitle)}</h5>
                                            <p>{reactHtmlParser(i?.data?.description)}</p>
                                        </>
                                    ))
                                }
                                {tableArray &&
                                    <>
                                        {tableArray?.length > 0 && tableArray?.map(i => (
                                            <>
                                                {reactHtmlParser(i?.data?.description)}
                                            </>
                                        ))
                                        }
                                    </>
                                }

                                {blockquoteData &&
                                    <blockquote cite="">
                                        {reactHtmlParser(blockquoteData[0]?.section_data?.description)}
                                    </blockquote>
                                }
                                {imagesData?.length > 0 &&
                                    <div className={'img-wrapper-text'}>
                                        <div className="img-wrapper-two mb-10">
                                            <Img src={imagesData[0]?.images?.list[0]?.full_path}/>
                                        </div>
                                        <h6>{reactHtmlParser(imagesData[0]?.section_data?.description)}</h6>
                                    </div>
                                }

                                {listData &&
                                    <>
                                        <ul className={'list'}>
                                            {reactHtmlParser(listData[0]?.section_data?.description)}
                                        </ul>
                                    </>
                                }


                            </Col>

                            {videoData?.length > 0 &&

                                <Col md={12} onClick={() => handelOpen(true, videoData[0]?.section_data?.subtitle)}
                                     key={0} className='video'>
                                    <div className='video-wrapper'>
                                        <Img src={videoData[0]?.images?.list[0]?.full_path}/>

                                        <div className="video-icon">
                                            <div className="icon-inner">
                                                <svg width="60" height="60" viewBox="0 0 60 60">
                                                    <g id="Group_14000" data-name="Group 14000"
                                                       transform="translate(0.49)">
                                                        <g
                                                            id="Ellipse_381"
                                                            data-name="Ellipse 381"
                                                            transform="translate(-0.49)"
                                                            fill="#FFFFFF"
                                                            stroke="none"
                                                            stroke-width="1"
                                                        >
                                                            <circle cx="30" cy="30" r="30" stroke="none"></circle>
                                                            <circle className="video-hover" cx="30" cy="30" r="30.5"
                                                                    fill="#E50019"></circle>
                                                        </g>
                                                        <path
                                                            id="Polygon_2"
                                                            data-name="Polygon 2"
                                                            d="M8.143,1.429a1,1,0,0,1,1.715,0l7.234,12.056A1,1,0,0,1,16.234,15H1.766a1,1,0,0,1-.857-1.514Z"
                                                            transform="translate(39.51 21) rotate(90)"
                                                            fill="#E50019"
                                                        ></path>
                                                    </g>
                                                </svg>
                                            </div>
                                        </div>

                                    </div>
                                </Col>

                            }
                        </Row>

                    </Col>

                </Row>
            </Container>

            <VideoModal show={show} video={true} direct_video={'wT3cpXL7HiE'}
                        handleClose={handleClose}/>
        </StyledComponent>


    );
};

const StyledComponent = styled.section`
  background: #F6F9F8;

  .img {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
  }

  .contributors {
    position: relative;
    margin-bottom: 60px;

    &:after {
      content: '';
      height: 1px;
      width: calc (100%-30px);
      bottom: 0;
      left: 15px;
      right: 15px;
      position: absolute;
      background: #1E1E2E;
    }

    h6 {
      margin-bottom: 5px !important;
    }

    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      margin-bottom: 30px !important;
    }
  }

  .contributors {
    .col-md-4:nth-last-child(-n+1) {
      p {
        margin-bottom: 60px !important;
      }
    }
  }

  .upper-text {
    @media (max-width: 767px) {
      padding-top: 60px;
    }

    .slider_overlap {
      .single_testimonial {
        position: relative;
        padding-top: calc(520 / 870 * 100%);
      }

      .slider-deatils {
        display: flex;
        justify-content: space-between;
        padding-top: 30px;
        padding-bottom: 60px;

        p {
          font-size: 16px;
          font-weight: 700;
          line-height: 20px;
          margin-bottom: 0;
          display: flex;
          align-items: center;
        }
      }

      .swiper-button-next, .swiper-button-prev {
        display: none;
      }

      .navigation_button {
        svg {
          width: 40px;
        }
      }

      @media (max-width: 767px) {
        .gallery-svg__section {
          padding-bottom: 0;

          ul {
            padding-top: 40px;
            justify-content: flex-start !important;
          }
        }
      }

    }

    .mb-10 {
      margin-bottom: 10px !important;
    }

    p {
      margin-bottom: 60px;
    }

    h3 {
      margin-bottom: 20px;
    }

    .info {
      display: flex;
      margin-bottom: 40px;

      li {
        display: inline-block;
        position: relative;
        padding-right: 20px;
        margin-right: 20px;
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        color: #1E1E2E;

        &:after {
          margin: 0;
          height: 100%;
          width: 1px;
          background: #707070;
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          content: '';
        }

        &:nth-last-child(1) {
          margin-right: 0px;

          &:after {
            content: unset;
          }
        }
      }
    }

    .img-wrapper {
      position: relative;
      padding-top: calc(432 / 870 * 100%);
      margin-bottom: 60px;

      &.img-half {
        padding-top: calc(250 / 420 * 100%);
      }
    }

    .img-wrapper-text {
      margin-bottom: 60px;
    }

    .img-wrapper-two {
      position: relative;
      padding-top: calc(520 / 870 * 100%);
      margin-bottom: 20px !important;
    }

    h5 {
      font-size: 20px;
      line-height: 28px;
      font-weight: 500;
      margin-bottom: 20px;
    }

    h6 {
      margin-bottom: 20px;
    }

    .opacity-50 {
      opacity: 50%;
    }

    .opacity-70 {
      opacity: 70%;
    }

    .list {
      margin-bottom: 40px;

      li {
        position: relative;
        padding: 20px 0 20px 20px;
        margin-bottom: 20px;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        color: #002839;
        border-bottom: 1px solid #002839;
        counter-increment: count;

        &:after {
          content: '';
          position: absolute;
          height: 10px;
          width: 10px;
          padding: 3.4px 0px 0px 9px;
          margin: 20px 0;
          font-size: 12px;
          line-height: 20px;
          font-weight: 500;
          border: none;
          background-color: #002839;
          border-radius: 50%;
          //color: #070524;
          top: 0;
          left: 0;
        }

        &:nth-child(1) {
          padding-top: 0;

          &:after {
            margin-top: 5px;
          }
        }

        //&:nth-last-child(1) {
        //  padding-bottom: 0;
        //  border-bottom: 0;
        //
        //}
      }
    }

    table {
      margin-bottom: 40px;

      tr {
        border-bottom: 1px solid rgba(50, 50, 50, 0.2);


        &:nth-child(1) {
          border-bottom: 1px solid rgba(50, 50, 50, 1);
        }

        &:nth-last-child(1) {
          border-bottom: 0;

          td {
            padding-bottom: 0;
          }
        }

        th {
          font-size: 16px;
          line-height: 24px;
          font-weight: 500;
          color: #002839;
          padding: 0 0 20px 0;
        }

        td {
          font-size: 16px;
          line-height: 24px;
          font-weight: 400;
          padding: 20px 0;
          color: #002839;
        }
      }
    }

    blockquote {
      font-size: 20px;
      line-height: 24px;
      font-weight: 300;
      margin-bottom: 20px;
      padding-left: 20px;
      color: #323232;
      border-left: 10px solid #002839;
      border-radius: 10px;
    }
  }

  @media (max-width: 767px) {

    .portfolio-details__social {
      display: flex;
      justify-content: space-between;

      ul {
        display: flex;
        gap: 20px;
      }
    }

    .detail-page {
      display: none;
    }

    .left-content {
      .upper-text {
        padding-top: 0;
      }
    }

    table {
      th {
        width: 50%;
        padding: 0 20px 20px 0 !important;
      }
    }
  }
  @media (max-width: 991px) and (min-width: 768px) {
    .detail-page {
      padding-bottom: 60px;
    }

    .detail-page {
      display: none;
    }
  }

  .video {
    .video-wrapper {
      position: relative;
      overflow: hidden;
      padding-top: calc(520 / 870 * 100%);
      cursor: pointer;

      .video-icon {
        overflow: unset !important;
        position: absolute;
        z-index: 8;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: fit-content;
        height: fit-content;
        margin: auto;

        svg {
          overflow: hidden;
          border-radius: 50%;

          .video-hover {
            cx: -90px;
            opacity: 0;
            transition: all 0.8s cubic-bezier(0.76, 0, 0.24, 1);
          }

          path {
            transition: all 0.8s cubic-bezier(0.76, 0, 0.24, 1);
          }
        }
      }

      &:hover {
        .video-icon {
          svg {
            .video-hover {
              cx: 30px;
              opacity: 1;
              transition: all 0.8s cubic-bezier(0.76, 0, 0.24, 1);
            }

            path {
              fill: #fff;
              transition: all 0.8s cubic-bezier(0.76, 0, 0.24, 1);
            }
          }
        }
      }
    }
  }


  .detail-page__left__text {
    font-size: 16px;
    line-height: 20px;
    padding-bottom: 20px;
  }


  .social {
    width: 100%;
    position: absolute;
    bottom: 40px;

    &__icon {
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      gap: 20px;

      &__icons:not(:last-child) {
        padding-right: 20px;
      }

      &__icons {
        &__facebook {
          svg {
            cursor: pointer;
            border-radius: 50px;

            #Group_18598 {
              #Rectangle_1999 {
                fill: #002839;
                opacity: 1;
                transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
                transform: translate(98px, 878px) !important;
              }

              #Path_27 {
                fill: #002839;
                transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
              }
            }

            &:hover {
              #Group_18598 {
                #Rectangle_1999 {
                  fill: #002839;
                  opacity: 1;
                  transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
                  transform: translate(98px, 848px) !important;
                  //path{
                  //  fill:yellow;
                  //}
                }

                #Path_27 {
                  fill: white;
                  transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
                }
              }
            }
          }


        }

        &__twitter {
          svg {
            cursor: pointer;
            border-radius: 50px;

            #Group_18595 {
              #Rectangle_1998 {
                fill: #002839;
                opacity: 1;
                transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
                transform: translate(98px, 923px) !important;
              }

              #Path_5915 {
                fill: #002839;
                transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
              }
            }

            &:hover {
              #Group_18595 {
                #Rectangle_1998 {
                  transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
                  transform: translate(98px, 893px) !important;
                }

                #Path_5915 {
                  fill: white;
                  transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
                }
              }
            }
          }
        }

        &__insta {
          svg {
            cursor: pointer;
            border-radius: 50px;

            #Group_18594 {
              #Rectangle_1990 {
                fill: #002839;
                transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
                transform: translate(98px, 965px) !important;
              }

              #Group_18368 {
                path {
                  fill: #002839;
                  transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
                }
              }
            }

            &:hover {
              #Group_18594 {
                #Rectangle_1990 {
                  transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
                  transform: translate(98px, 938px) !important;
                }

                #Group_18368 {
                  path {
                    fill: white;
                    transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
                  }
                }
              }
            }
          }
        }

        &__youtube {
          svg {
            cursor: pointer;
            border-radius: 50px;

            #Group_18589 {
              #Rectangle_1999 {
                fill: #002839;
                transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
                transform: translate(98px, 1010px) !important;
              }

              #LinkedIn {
                fill: #002839;
                transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
              }
            }

            &:hover {
              #Group_18589 {
                #Rectangle_1999 {
                  transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
                  transform: translate(98px, 983px) !important;
                }

                #LinkedIn {
                  fill: white;
                  transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
                }
              }
            }
          }
        }
      }
    }
  }



`;

export default MediaDetails;
