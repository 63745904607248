import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {fetchPosts, fetchPostDetail} from "../../api/redux/post";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import styled from "styled-components";

import InnerBanner from "../../components/InnerBanner";

import {Helmet, HelmetProvider} from "react-helmet-async";
import AddressTab from "../../components/Contact-us/AddressTab";
import QueryForm from "../../components/Home/QueryForm";
import {ApiParam as ApiParamKey} from "../../api/network/apiParams";
import {fetchAbout} from "../../api/redux/about";
import {fetchContact} from "../../api/redux/contact";
import {fetchHome} from "../../api/redux/home";
import QueryFormContact from "../../components/Contact-us/QueryFormContact";


const ContactUs = () => {

    const dispatch = useDispatch()
    const getData = useSelector(state => state.contactReducer)
    const getDataHome = useSelector(state => state.homeReducer)

    // api call
    useEffect(() => {
        let apiUrl = apiEndPoints.SECTIONS
        let api_params = {
            [ApiParamKey.type]: 'slug',
            [ApiParamKey.value]: 'contact-us',
            [ApiParamKey.get_section]: 'yes',
            [ApiParamKey.image]: 'yes',
            [ApiParamKey.post]: 'yes',
            [ApiParamKey.file]: 'no',
        }
        dispatch(fetchContact([apiUrl, api_params]))


    }, [])

    useEffect(() => {
        let apiUrl = apiEndPoints.SECTIONS
        let api_params = {
            [ApiParamKey.type]: 'slug',
            [ApiParamKey.value]: 'home',
            [ApiParamKey.get_section]: 'yes',
            [ApiParamKey.image]: 'yes',
            [ApiParamKey.post]: 'yes',
            [ApiParamKey.file]: 'no',
        }
        dispatch(fetchHome([apiUrl, api_params]))


    }, [])

    // filter data
    const contactBanner = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'contact-banner');
    const contactDetails = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'contact-details');
    const query = getDataHome?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'query');
    const page_data = getData?.posts?.data?.page_data;

    return (
        <HelmetProvider>
            <Helmet>
                <title>{`${page_data?.title ? page_data?.title + ' | ' : ''}`}Pacific Blue</title>

                {
                    page_data?.meta_title &&
                    <meta name="meta:title" content={page_data?.meta_title}/>

                }
                {
                    page_data?.meta_description &&
                    <meta name="description" content={page_data?.meta_description}/>

                }
                {
                    page_data?.og_title &&
                    <meta property="og:title" content={page_data?.og_title}/>

                }
                {
                    page_data?.og_description &&
                    <meta property="og:description" content={page_data?.og_description}/>

                }
            </Helmet>
            <StyledSection>
                <InnerBanner text={contactBanner?.section_data?.subtitle} img={contactBanner?.images?.list[0]?.full_path}/>
                {contactDetails &&
                    <AddressTab data={contactDetails}/>
                }
                {
                    query &&
                    <QueryFormContact data={query}/>
                }
            </StyledSection>
        </HelmetProvider>
    );
};

const StyledSection = styled.section`
`;

export default ContactUs;
