import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import styled from "styled-components";
import InnerBanner from "../../components/InnerBanner";
import About from "../../components/AboutUs/About";
import MissionVision from "../../components/AboutUs/MissionVision";
import OurValues from "../../components/AboutUs/OurValues";
import TeamOfLeaders from "../../components/AboutUs/TeamOfLeaders";
import BoardOfDirector from "../../components/AboutUs/BoardOfDirector";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {ApiParam as ApiParamKey} from "../../api/network/apiParams";
import {fetchAbout} from "../../api/redux/about";


const Home = () => {

    const dispatch = useDispatch()
    const getData = useSelector(state => state.aboutReducer)

    // api call
    useEffect(() => {
        let apiUrl = apiEndPoints.SECTIONS
        let api_params = {
            [ApiParamKey.type]: 'slug',
            [ApiParamKey.value]: 'about',
            [ApiParamKey.get_section]: 'yes',
            [ApiParamKey.image]: 'yes',
            [ApiParamKey.post]: 'yes',
            [ApiParamKey.file]: 'no',
        }
        dispatch(fetchAbout([apiUrl, api_params]))

    }, [])

    // filter data
    const Banner = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'about-banner');
    const aboutBody = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'about-overview');
    const aboutMissionVision = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'mission-vision-objective');
    const aboutValues = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'our-values');
    const aboutDirector = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'director');
    const aboutTeamOfLeader = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'team-of-leaders');
    const page_data = getData?.posts?.data?.page_data;

    return (
        <HelmetProvider>
            <Helmet>
                <title>{`${page_data?.title ? page_data?.title + ' | ' : ''}`}Pacific Blue</title>

                {
                    page_data?.meta_title &&
                    <meta name="meta:title" content={page_data?.meta_title}/>

                }
                {
                    page_data?.meta_description &&
                    <meta name="description" content={page_data?.meta_description}/>

                }
                {
                    page_data?.og_title &&
                    <meta property="og:title" content={page_data?.og_title}/>

                }
                {
                    page_data?.og_description &&
                    <meta property="og:description" content={page_data?.og_description}/>

                }
            </Helmet>
            <StyledSection>
                {
                    Banner &&
                    <InnerBanner text={Banner?.section_data?.subtitle} img={Banner?.images?.list[0]?.full_path}/>
                }
                {
                    aboutBody &&
                    <About data={aboutBody}/>
                }
                {
                    aboutMissionVision &&
                    <MissionVision data={aboutMissionVision}/>
                }
                {
                    aboutValues &&
                    <OurValues data={aboutValues}/>
                }
                {
                    aboutDirector &&
                    <BoardOfDirector data={aboutDirector}/>
                }
                {/*{*/}
                {/*    aboutTeamOfLeader &&*/}
                {/*    <TeamOfLeaders data={aboutTeamOfLeader}/>*/}
                {/*}*/}
            </StyledSection>
        </HelmetProvider>
    );
};

const StyledSection = styled.section`
  position: relative;
`;

export default Home;
