import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";

import {Img} from "../Img";
import Button from "../Button";
import ReactHtmlParser from "react-html-parser";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({des, designation, img, name, data, textOne, textTwo}) => {

    return (
        <StyledComponent className={' horizontal-scroll pt-100 pb-200'}>
            <Container className="profile">

                <Row>
                    <Col lg={{offset: 1, span: 5}} md={'12'} className="profile__body">
                        <div className={'profile__body__inner'}>
                            <div className="profile__body__name">
                                <h5 className={'split-up'}>Board of Director</h5>
                                <h2 className={'split-up'}>{data?.section_data?.short_desc}</h2>
                            </div>
                            <p className={''}>{reactHtmlParser(data?.section_data?.description)}</p>
                        </div>
                    </Col>
                    <Col lg={'6'} className={'profile-left reveal'}>
                        <div className="profile__image ">
                            <Img src={data?.images?.list[0]?.full_path}/>
                        </div>
                    </Col>
                </Row>

            </Container>

        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: #F2F2F2;
  position: relative;

  .profile {

    &__body {
      &__name {
        padding-bottom: 20px;
        h5{
          margin-bottom: 40px;
          padding-bottom: 12px;
          border-bottom: 1px solid rgb(0 40 57 / 20%);
        }
      }

      &__inner {
        padding-bottom: 40px;
      }
    }

    &__image {
      position: relative;
      padding-top: calc(570 / 570 * 100%);
    }
  }

  .profile-left {
    //padding-right: 70px;
  }

  .row:nth-child(odd) {
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  @media (max-width: 767px) {
    .row {
      flex-direction: row-reverse !important;
    }
  }


  @media (max-width: 767px) {
    .profile-left {
      padding-right: 15px;
    }

    .profile {
      padding-bottom: unset;

      &__body {
        padding-bottom: unset;

        &__name {
          padding-bottom: 40px;
        }
      }
    }
  }
  @media (min-width: 768px) and (max-width: 991px){
    .row:nth-child(odd) {
      flex-direction: column-reverse;
    }
    .profile__body{
      padding-top: 150px;
    }
  }
  

`;

export default React.memo(MyComponent);
