import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Img} from "./Img";
import {Link} from "react-router-dom";


const MyComponent = ({
                         img,
                         desc,
                         link,
                         title
                     }) => {

    return (
        <StyledComponent>
            <div className="box-image__single">
                <Link to={`/products/${link}`}/>
                <div className="box-image__single__image">
                    <Img src={img}/>
                </div>
                <div className="box-image__single__text">
                    <h5>{title}</h5>
                    <p>{desc}</p>
                </div>
            </div>
        </StyledComponent>
    );
};
const StyledComponent = styled.div`
  position: relative;

  .box-image__single {
    margin-top: 40px;
    //border-radius: 10px;
    overflow: hidden;
    padding: 30px;
    background: #FFFFFF;
    
    a {
      position: absolute;
      height: 100%;
      width: 100%;
      inset: 0;
      z-index: 2;
      cursor: pointer;
    }

    //&:hover {
    //  .global-image {
    //    transition: all 0.6s cubic-bezier(0.4, 0, 0, 1);
    //    transform: scale(1.06);
    //  }
    //  .box-image__single__downlaod__btn{
    //    svg{
    //      #Ellipse_636{
    //        r: 30px;
    //      }
    //    }
    //  }
    //}


    &__image {
      position: relative;
      padding-top: calc(223 / 261 * 100%);
      overflow: hidden;
      //border-radius: 10px;
      


    }

    &__text {

      margin: 20px 0 0 0;
      padding: 0 0 0 0;

      h5 {
        color: #002839;
        font-weight: 400;
        height: 48px;
        overflow: hidden;
      }

      p {
        color: #002839;
        margin: 10px 0 0 0;
        //font-size: 24px;
        //line-height: 32px;
        font-weight: 400;
        height: 48px;
        overflow: hidden;

      }


    }

  }


  @media (min-width: 1550px) {
    .box-image__single{
      border-radius: 20px;
    }
  }
  


`;

export default MyComponent;