import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";

import Button from "../Button";

import CountUp from 'react-countup';
import VisibilitySensor from "react-visibility-sensor";
import {ImgBg} from "../ImgBg";
import reactHtmlParser from "react-html-parser";
import {ImgPreload} from "../ImgPreload";



const AboutPartex = ({data}) => {
    return (
        <StyledComponent className=' pt-200 pb-200'>
            <ImgPreload dataSpeed={.9} src={'images/static/about-bg.jpg'}/>
            <Container>
                <Row>
                    <Col sm={9}>
                        <h2 className={'split-up'}>{reactHtmlParser(data?.section_data?.short_desc)}</h2>
                    </Col>

                    <Col sm={{offset: 5, span: 6}}>
                        <p className={'split-up'}>{reactHtmlParser(data?.section_data?.description)}
                        </p>
                        <Button src={'/about'} text={'About Us'} background={'#EEEEEE'} color={'#002839'} border={'1px solid #002839'} hoverBackground={'#002839'} hoverBorder={'1px solid #002839'} margin={'40px 0 0 0'}/>
                    </Col>
                    <Row/>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background: #F6F9F8;
  position: relative;
  overflow: hidden;
  

  .upper-title {
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    width: 50%;
    margin: 0 0 20px 0;
  }

  h2 {
    color: #1E1E2E;
    margin: 0 0 40px 0;

    span {
      color: #6EAFC4;
    }
  }

  p:not(:last-child) {
    color: #1E1E2E;
  }

  .counter-item {
    h2 {
      padding-bottom: 20px;
      color: #E72E4B;
    }

    p {
      font-weight: 700;
      margin: 0;
    }

    &:nth-child(1) {
      margin-bottom: 40px;
    }
  }

  @media (max-width: 767px) {
    
    padding-bottom: 180px;
    padding-top: 180px;

    p {
      margin: 0 0 40px 0;
    }

    .upper-title {
      width: 100%;
    }

    .counter-item {
      &:nth-child(n+1) {
        margin-bottom: 40px;
      }
    }
  }
  .container{
    position: relative;
  }

`;

export default AboutPartex;
