import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {fetchProductPostDetail} from "../../api/redux/products";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import styled from "styled-components";
import ProductDetails from "../../components/Product/ProductDetails";
import QueryForm from "../../components/Home/QueryForm";
import {ApiParam as ApiParamKey} from "../../api/network/apiParams";

import {useLocation, useParams} from "react-router-dom";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {fetchHome} from "../../api/redux/home";


const ProductDetailsIndex = () => {

    const dispath = useDispatch()
   const location =useLocation();

    const getDataHome = useSelector(state => state.homeReducer)

    const getData = useSelector(state => state.productReducer)
    const {slug} = useParams();

    // api call
    useEffect(() => {
        let apiUrlSection = apiEndPoints.PRODUCTS_DATA
        let api_params_sections = {
            [ApiParamKey.type]: 'slug',
            [ApiParamKey.value]: `${slug}`,
            [ApiParamKey.image]: 'yes',
            [ApiParamKey.post]: 'yes',
            [ApiParamKey.file]: 'no',
        }
        dispath(fetchProductPostDetail([apiUrlSection, api_params_sections]))
    }, [location.pathname])

    useEffect(() => {
        let apiUrl = apiEndPoints.SECTIONS
        let api_params = {
            [ApiParamKey.type]: 'slug',
            [ApiParamKey.value]: 'home',
            [ApiParamKey.get_section]: 'yes',
            [ApiParamKey.image]: 'yes',
            [ApiParamKey.post]: 'yes',
            [ApiParamKey.file]: 'no',
        }
        dispath(fetchHome([apiUrl, api_params]))


    }, [])

    const Details = getData?.detail?.data;
    const page_data = getData?.detail?.data?.product_data;
    const query = getDataHome?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'query');

    return (
        <HelmetProvider>
            <Helmet>
                <title>{`${page_data?.subtitle ? page_data?.subtitle + ' | ' : ''}`}Pacific Blue</title>

                {
                    page_data?.meta_title &&
                    <meta name="meta:title" content={page_data?.meta_title}/>

                }
                {
                    page_data?.meta_description &&
                    <meta name="description" content={page_data?.meta_description}/>

                }
                {
                    page_data?.og_title &&
                    <meta property="og:title" content={page_data?.og_title}/>

                }
                {
                    page_data?.og_description &&
                    <meta property="og:description" content={page_data?.og_description}/>

                }
            </Helmet>
            <StyledSection>
                <ProductDetails data={Details}/>
                <QueryForm data={query}/>
            </StyledSection>
        </HelmetProvider>
    )
        ;
};

const StyledSection = styled.section`
`;

export default ProductDetailsIndex;
