import React from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {FaFacebookF, FaInstagram, FaLinkedinIn, FaTwitter, FaYoutube} from 'react-icons/fa';
import {hover} from "../styles/globalStyleVars";
import {Img} from "./Img";
import SocialIcon from "./SocialIcon";


const Footer = ({data}) => {
    const currentYear = new Date().getFullYear(); // Get the current year
    return (<StyledFooter className='footer pt-100'>
        <Container className='footer__top'>
            <Row>
                <Col className='footer-col' sm={3}>

                    <div className="group logo">
                        <h4>Contact</h4>
                        <p><a href={'https://maps.app.goo.gl/hHjHdrBTvkRmnezC6'} target="{&quot;_target&quot;}">
                            6 Cutleaf Crescent, Tarneit, Victoria 3029, Australia</a></p>
                        <p><a href="tel:+61450665186">+6145 0665 186</a></p>
                        <p>Postal Address: PO Box 2195
                            Werribee, Victoria 3030, Australia
                        </p>
                        <p><a href={"mailto:info@pacificbluegroup"}>info@pacificbluegroup.com</a></p>
                    </div>

                </Col>

                <Col className='footer-col' md={{span: 2, offset: 2}}>
                    <div className="group">
                        <h4>Services</h4>
                        <ul>

                            <li><Link to={'/home-appliance-manufacturing-consultancy'}>Home appliance manufacturing consultancy</Link></li>
                            <li><Link to={'/home-appliance-product-development'}>Home appliance product development</Link></li>
                            <li><Link to={'/home-appliance-factory-design-commissioning'}>Home appliance factory design and commissioning</Link></li>
                        </ul>
                    </div>

                </Col>


                <Col className='footer-col' md={2}>
                    <div className="group">
                        <h4>Quick nevigation</h4>
                        <ul>
                            <li><Link to={'/'}>Home</Link></li>
                            <li><Link to={'/about'}>About us</Link></li>
                            <li><Link to={'/media-center'}>Media Center</Link></li>
                            <li><Link to={'/products'}>All Categories</Link></li>
                            <li><Link to={'/contact-us'}>Contact Us</Link></li>
                        </ul>
                    </div>

                </Col>

                {window.innerWidth > 767 ?
                    <>
                        <Col sm={3} className='footer-col social-menus'>
                            <SocialIcon/>
                        </Col>

                        <Col sm={12} className={'footer-logo'}>
                            <Link to={'/'}>
                                <img src={'/images/static/logo.svg'}/>
                            </Link>
                        </Col>
                    </>
                    :
                    <>
                        <Col sm={12} className={'footer-logo'}>
                            <Link to={'/'}>
                                <img src={'/images/static/logo.svg'}/>
                            </Link>
                        </Col>

                        <Col sm={3} className='footer-col social-menus'>
                            <SocialIcon/>
                        </Col>
                    </>
                }

            </Row>
        </Container>

        <Container className='footer__bottom'>
            <Row>
                <Col md={6} sm={12}>
                    <p>© {currentYear} Pacific blue. All Rights Reserved.</p>
                </Col>
                <Col md={6} sm={12}>
                    <p className={'bottom-right'}><a href={'https://dcastalia.com/'} target={"_blank"}>Designed & Developed by Dcastalia</a></p>
                </Col>
            </Row>
        </Container>
    </StyledFooter>);
};
const StyledFooter = styled.section`
  background-color: #6EAFC4;
  padding-bottom: 60px;

  .footer__top {
    position: relative;
    padding-bottom: 60px;

    .footer-logo {
      margin-bottom: 60px;

      img {
        width: 200px;
        height: 38px;
      }
    }

    &:after {
      content: '';
      position: absolute;
      height: 1px;
      left: 15px;
      right: 15px;
      background-color: rgba(255, 255, 255, 0.20);
      bottom: 0;
    }

    .group {
      margin-bottom: 40px;

      h4 {
        color: rgb(255 255 255 / 60%);
        margin: 0 0 40px 0;
        font-size: 12px;
        line-height: 14px;
        text-transform: capitalize;
        font-weight: 400;
      }

      p {
        cursor: pointer;
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
        color: #FFFFFF;
        transition: color 0.3s ease;

        &:hover {
          color: rgb(255 255 255 / 50%);
          transition: color 0.3s ease;
        }
      }

      a {
        margin-bottom: 10px;
        display: flex;
        color: #FFFFFF;
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
        //text-transform: capitalize;
        transition: color 0.3s ease;

        &:hover {
          color: rgb(255 255 255 / 50%) !important;
          transition: color 0.3s ease;
        }
      }

      &.emails {
        a {
          text-transform: none;
        }
      }

    }

    .logo {
      p:not(:last-child) {
        margin-bottom: 20px;
      }

      img {
        margin-bottom: 40px;
      }
    }

    .social-menus {
      .hover:after {
        background-color: ${hover};
      }

      ul {
        display: flex;
        gap: 20px;
        justify-content: flex-end;

        li {
          height: 25px;
          width: 25px;
          border-radius: 50%;
          background-color: rgb(34, 31, 31);
          margin-bottom: 20px;

          svg {
            color: #ffffff;
            font-size: 11px;
          }

          a {
            position: relative;
            display: flex;
            z-index: 2;
            height: 100%;
            width: 100%;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }

  .footer__bottom {
    padding-top: 45px;

    p {
      font-size: 14px;
      line-height: 16px;
      font-weight: 400;
      color: rgb(255 255 255 / 60%);
    }

    .col-md-6:last-child {
      p {
        text-align: end;
      }
    }

    .bottom-right {
      a{
        color: rgba(255, 255, 255, 0.6);
        &:hover{
          color: rgba(255, 255, 255, 1)!important;
        }
      }
    }
  }


  @media (max-width: 850px) {
    .footer-col {

      min-width: 50%;
      margin: 0;

      &.social-menus {
        justify-content: flex-start !important;

        ul {
          display: flex;

          li {
            margin-right: 10px;
          }
        }
      }
    }
  }


  @media (min-width: 768px) and (max-width: 991px) {
    .social__icon {
      justify-content: flex-start;
    }
  }

  @media (max-width: 767px) {
    padding-bottom: 40px !important;
    .footer__top {
      padding-bottom: 20px;

      .footer-logo {
        margin-bottom: 60px;

        img {
          width: 140px;
          height: 26px;
        }
      }
    }

    .footer__bottom {
      padding-top: 20px;

      .row {
        .col-sm-12 {
          margin-bottom: 7px;
        }

        .col-sm-12:last-child {
          p {
            text-align: start;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {


    .social__icon {
      justify-content: flex-start !important;
    }

    .group {
      margin-bottom: 60px !important;
    }

    .social-menus {
      margin-bottom: 20px;
    }

    .footer-col:nth-of-type(1) {
      max-width: 100%;
      min-width: 100%;
    }

    .footer-col:nth-of-type(2), .footer-col:nth-of-type(3) {
      max-width: 50%;
      min-width: 50%;
    }


    padding-top: 60px ;
    .footer-col {
      min-width: 100%;
    }

    .logo {
      width: 80%;
    }
  }




`;


export default Footer;