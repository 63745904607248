import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import Slider from 'react-slick';  // Import Slider from react-slick
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {apiEndPoints} from '../../api/network/apiEndPoints';
import {ApiParam as ApiParamKey} from '../../api/network/apiParams';
import {fetchProductsChild} from '../../api/redux/products';
import {hover} from "../../styles/globalStyleVars";

const MyComponent = () => {
    const dispatch = useDispatch();

    const getData = useSelector((state) => state.productReducer);
    const [activeClass, setActiveClass] = useState('Air Conditioner');

// api call
    useEffect(() => {
        let apiUrl = apiEndPoints.PRODUCTS;
        let api_params = {
            [ApiParamKey.page_id]: 10,
            [ApiParamKey.sections]: 'yes',
            [ApiParamKey.image]: 'yes',
            [ApiParamKey.post]: 'yes',
            [ApiParamKey.file]: 'no',
            [ApiParamKey.category]: '',
        };
        dispatch(fetchProductsChild([apiUrl, api_params]));
    }, []);

    const productsData = getData?.child;
    const category = productsData?.filter?.category;

    const categoryFilterone = productsData?.filter?.category || [];
    const categoryFiltertwo = productsData?.data || [];
    const finalCategory = [];
    const titleSet = new Set(); // Set to keep track of unique titles

    categoryFilterone.forEach(itemOne => {
        categoryFiltertwo.forEach(itemTwo => {
            if (itemOne.title === itemTwo.product_data.category_title && !titleSet.has(itemOne.title)) {
                finalCategory.push({
                    title: itemOne.title,
                    id: itemOne.id // Assuming both items have an id property
                });
                titleSet.add(itemOne.title); // Add the title to the set to prevent duplicates
            }
        });
    });


    const [categoryid, setCategoryId] = useState(22);
    const handleClick = (i) => {
        // Apply the filters based on the selected category, sub-category, and brand
        setCategoryId(i?.id);
        setActiveClass(i?.title)
    };

    const filterData = productsData?.data?.filter(
        (i) => i?.product_data?.category_id === categoryid
    );

    // Filter titles when originalData changes
    const titles = filterData?.map((item) => ({
        title: item?.product_data?.subtitle,
        link: item?.product_data?.slug,
    }));

    const renderItems = (items) => {
        const columns = [];
        const itemsPerSlide = 5; // Number of items per slide
        const slidesToShow = 8; // Number of slides visible at a time

        for (let i = 0; i < items.length; i += itemsPerSlide) {
            columns.push(
                <Col key={i}>
                    <ul>
                        {items.slice(i, i + itemsPerSlide).map((item, index) => (
                            <Link to={`/products/${item?.link}`}>
                                <li>{item?.title}</li>
                            </Link>
                        ))}
                    </ul>
                </Col>
            );
        }

        return columns;
    };


    // Settings for the slick slider


    const NextArrow = (props) => {
        const {className, style, onClick} = props;
        return (
            <div
                className={className}
                style={{...style, display: 'block'}}
                onClick={onClick}
            >
                Next
            </div>
        );
    };

// Custom component for the previous arrow
    const PrevArrow = (props) => {
        const {className, style, onClick} = props;
        return (
            <div
                className={className}
                style={{...style, display: 'block'}}
                onClick={onClick}
            >
                Prev
            </div>
        );
    };
// Settings for the slick slider
    const sliderSettings = {
        infinite: false,
        speed: 500,
        slidesToShow: 6, // Number of slides visible at a time
        slidesToScroll: 4, // Number of slides to scroll at a time
        nextArrow: <NextArrow/>, // Custom component for next arrow
        prevArrow: <PrevArrow/>, // Custom component for previous arrow
    };

// Custom component for the next arrow

    return (
        <StyledComponent>
            <Tabs>
                {finalCategory &&
                    <Slider {...sliderSettings}>
                        {finalCategory?.length > 0 &&
                            finalCategory?.map((i, index) => (
                                <Tab className={`react-tabs__tab ${activeClass === i?.title ? 'active' : ''}`}
                                     key={index} onClick={() => handleClick(i)}>
                                    {i?.title}
                                </Tab>
                            ))}
                    </Slider>
                }


                {category?.length > 0 &&
                    category?.map((i, index) => (
                        <TabPanel key={index}>
                            <Container fluid>
                                <Row>{renderItems(titles)}</Row>
                            </Container>
                        </TabPanel>
                    ))}
            </Tabs>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  .container-fluid {
    padding: unset;

    .row {
      width: 80%;
    }
  }

  .slick-slider {
    margin-bottom: 30px;
  }

  .slick-slide {
    li {
      display: inline-flex !important;
      text-align: center;
      border-bottom: 0.5px solid rgb(196, 196, 196);
      height: 53px;
      justify-content: center;
      align-items: center;
    }

    .active {
      background: #6EAFC4;
      color: #fff !important;
      border-right: unset;
    }
  }

  .container-fluid {
    padding: 0px 15px !important;
  }

  .react-tabs__tab-panel {

    li {
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      color: rgba(0, 40, 57, 0.8) !important;
      transition: 0.5s ease color;

      &:hover {
        color: rgb(110, 175, 196) !important;
        transition: 0.5s ease color;
      }
    }
  }

  .slick-prev {
    left: -35px;

    &:before {
      color: #002839;
    }
  }

  .slick-next {
    right: -35px;

    &:before {
      color: #002839;
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    .react-tabs {
      padding: 50px !important;
    }
  }
`;

export default MyComponent;
