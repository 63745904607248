import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col, Modal} from "react-bootstrap";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, Navigation, EffectFade} from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import reactHtmlParser from "react-html-parser";
import Title from "../Title";
import {Img} from "../Img";
import NavigationIcon from "../NavigationIcon";
import SwiperCore, {Pagination} from 'swiper';

SwiperCore.use([Pagination]);
const Testimonial = ({data}) => {

    console.log('Testimonial', data)
    // filter data
    const slider = data?.posts?.list;

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
    }

    const [current, setCurrent] = useState('1')

    const handleSlideChange = (swiper) => {
        setCurrent(swiper.realIndex + 1);
    };


    return (
        <StyledComponent className={'testimonial pt-150 pb-150'}>
            <Container>
                <Row className={'testimonial__top'}>
                    <Col sm={12} md={6}>
                        <h3 className={'title'}>
                            {reactHtmlParser(data?.section_data?.subtitle)}
                        </h3>

                    </Col>

                    {window.innerWidth > 991 ?
                        <Col md={6} className={'nav'}>
                            <div className={'navigation'}>
                                <NavigationIcon prev_id={'slider_prev'} next_id={'slider_next'}/>
                                <p className={'length'}><span>{current}</span>/<span>{slider?.length}</span></p>
                            </div>
                        </Col>
                        :
                        <Col md={6} className={'nav-mobile'}>
                            <div className={'navigation-mobile'}>
                                <NavigationIcon prev_id={'slider_prev'} next_id={'slider_next'}/>
                                <p className={'length'}><span>{current}</span>/<span>{4}</span></p>
                            </div>
                        </Col>

                    }

                </Row>


                <div className={'testimonial__slider'}>
                    {slider?.length &&
                        <Swiper className="mySwiper"
                                loop={true}
                                spaceBetween={30}
                                slidesPerView={1}
                                allowSlideNext={true}
                                allowSlidePrev={true}
                                allowTouchMove={true}
                                speed={900}

                                navigation={{
                                    prevEl: '#slider_prev',
                                    nextEl: '#slider_next',
                                }}
                                modules={[Autoplay, Pagination, Navigation]}
                                onSlideChange={(swiper) => handleSlideChange(swiper)}
                                pagination={{
                                    el: '.swiper-pagination',
                                    clickable: true,
                                    renderBullet: function (index, className) {
                                        return `<span class="${className}">${index + 1}</span>`;
                                    },
                                }}

                            // autoplay={false}

                            // autoplay={{
                            //     delay: 5000,
                            //     disableOnInteraction: false,
                            // }}
                        >
                            {slider?.length > 0 && slider?.map((i, index) => (

                                <SwiperSlide>
                                    <div className="testimonial__slider__single flex-wrap d-flex">
                                        <Col sm={12} md={5} className={'p-0'}>
                                            <div className="testimonial__slider__single__img" onClick={handleShow}>
                                                <Img src={i?.images?.[0]?.full_path}/>
                                            </div>
                                        </Col>

                                        <Col sm={12} md={{span: 6, offset: 1}}>
                                            <div className="testimonial__slider__single__content">
                                                <h4>{i?.data?.subtile}</h4>
                                                <img src="/images/static/testimonial.svg" alt=""/>
                                                <p>{reactHtmlParser(i?.data?.description)}</p>
                                            </div>
                                        </Col>

                                    </div>
                                </SwiperSlide>
                            ))}

                        </Swiper>
                    }
                </div>

            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: #F2F2F2;
  overflow: hidden;

  .container {
    position: relative;

    .nav {
      position: absolute;
      bottom: 0;
      left: 50%;
      z-index: 10;

      .navigation {
        margin: 0 15px;
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: end;

        p {
          font-size: 24px;
          line-height: 36px;
          font-weight: 400;
          color: rgb(0 40 57 / 80%);
        }
      }
    }

    .nav-mobile {
      margin: 40px 0;

      .navigation-mobile {
        display: flex;
        justify-content: space-between;
        align-items: end;
      }

      @media (min-width: 768px) and (max-width: 991px) {
        margin: 15px 0 0;
      }
    }
  }

  .testimonial__top {

    .mobile-nav {
      margin: 40px 0;
    }

    ul {
      display: flex;
    }

    @media (min-width: 992px) {
      .mobile-nav {
        display: none;
      }
    }
  }

  .testimonial__slider {
    margin-top: 60px;

    &__single {
      &__img {
        position: relative;
        padding-top: calc(400 / 400 * 100%);
        cursor: pointer;
        overflow: hidden;

        .video-btn {
          position: absolute;
          width: fit-content;
          height: fit-content;
          inset: 0 0 0 0;
          margin: auto;
        }
      }

      &__content {
        position: relative;

        img {
          margin-bottom: 35px;
        }

        h4 {
          margin-bottom: 40px;
          font-size: 24px;
          line-height: 32px;
        }

        p {

        }

        &__bottom {
          margin-top: 60px;
        }
      }
    }
  }


  .swiper {

    .desktop-nav {
      display: flex;
      justify-content: center;
      left: 75px;
      bottom: 50px;
      position: relative;
    }

    @media (max-width: 991px) {
      .desktop-nav {
        display: none;
      }
    }
  }


  @media (max-width: 768px) {
    .video-btn {
      svg {
        height: 50px;
        width: 50px;
      }
    }
  }

  @media (max-width: 767px) {


    .testimonial__slider {
      margin-top: 0px;

      &__single__content {
        margin-top: 40px;
        margin-left: -15px;
        margin-right: -15px;

        img {
          left: 0px;
        }

        &__bottom {
          margin-top: 40px;
        }
      }
    }


  }
`;

export default Testimonial;
