import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation} from "swiper";
import {BsPlus} from 'react-icons/bs';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import {Link} from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import {Img} from "../Img";
import ExperienceIconComponent from "../ExperienceIconComponent";
import reactHtmlParser from "react-html-parser";
// import {Img} from "../Img";

const ExperienceSlider = ({
                              title,
                              id,
                              padding,
                              getAfterHeight,
                              bg,
                              img,
                              full,
                              project,
                              nolnk,
                              remove,
                              data,
                              dataTwo,
                              after_bg
                          }) => {

    // slider data
    const services = data?.posts?.list;
    const [getAfter, setgetAfter] = useState(90)
    const [offset, setOffset] = useState(90)

    useEffect(() => {

        // ScrollTrigger.refresh();
        gsap.registerPlugin(ScrollTrigger);


        window.addEventListener('load', function () {
            setOffset(document.querySelector(' .container').offsetLeft)

        })
        window.addEventListener('resize', function () {
            setOffset(document.querySelector(' .container').offsetLeft)

        })
        setOffset(document.querySelector(' .container').offsetLeft)
    }, [data])


    return (
        <StyledStrength getAfterHeight={getAfter} remove={remove} id={`${id ? id : 'Strength'}`} offset={offset}
                        className={`pionneering  ${padding ? padding : 'pt-150 pb-150'}  `}>
            <Container>
                <Row>
                    <Col md={6}>
                        <div className="title">
                            {
                                title ? <h3 className={'fade-up split-up'}>{ReactHtmlParser(title)}</h3> :
                                    <h3 className={'split-up split-up'}>{reactHtmlParser(data?.section_data?.subtitle)}</h3>
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container className='about-section__bottom'>
                <Row>
                    <Col lg={4} className='about-section__bottom__single p-0'>
                        <Link to={dataTwo[0]?.page_data?.slug}>
                            <div className="about-section__bottom__single__inner">
                                <div className="about-section__bottom__single__inner__img">
                                    <Img
                                        src={dataTwo[0]?.images?.list[0]?.full_path ? dataTwo[0]?.images?.list[0]?.full_path : ''}/>
                                </div>

                                <h5 className={'split-up'}>{reactHtmlParser(dataTwo[0]?.page_data?.subtitle)}</h5>
                                <p className={'split-up'}>{reactHtmlParser(dataTwo[0]?.page_data?.short_desc)}</p>

                                <div className="icon">
                                    <ExperienceIconComponent/>
                                </div>
                            </div>
                        </Link>
                    </Col>

                    <Col lg={4} className='about-section__bottom__single p-0' data-lag={0.1}>
                        <Link to={dataTwo[1]?.page_data?.slug}>

                            <div className="about-section__bottom__single__inner">
                                <div className="about-section__bottom__single__inner__img">
                                    <Img
                                        src={dataTwo[1]?.images?.list[0]?.full_path ? dataTwo[1]?.images?.list[0]?.full_path : ''}/>
                                </div>
                                <h5 className={'split-up'}>{reactHtmlParser(dataTwo[1]?.page_data?.subtitle)}</h5>
                                <p className={'split-up'}>{reactHtmlParser(dataTwo[1]?.page_data?.short_desc)}</p>
                                <div className="icon">
                                    <ExperienceIconComponent/>
                                </div>
                            </div>
                        </Link>
                    </Col>


                    <Col lg={4} className='about-section__bottom__single p-0'>
                        <Link to={dataTwo[2]?.page_data?.slug}>
                            <div className="about-section__bottom__single__inner">
                                <div>
                                    <div className="about-section__bottom__single__inner__img">
                                        <Img
                                            src={dataTwo[2]?.images?.list[0]?.full_path ? dataTwo[2]?.images?.list[0]?.full_path : ''}/>
                                    </div>
                                    <h5 className={'split-up'}>{reactHtmlParser(dataTwo[2]?.page_data?.subtitle)}</h5>
                                    <p className={'split-up'}>{reactHtmlParser(dataTwo[2]?.page_data?.short_desc)}</p>
                                </div>
                                <div className="icon">
                                    <ExperienceIconComponent/>
                                </div>
                            </div>
                        </Link>
                    </Col>
                </Row>
            </Container>
        </StyledStrength>
    );
};

const StyledStrength = styled.section`
  background: #E8E8E8;
  position: relative;
  overflow: hidden;


  .title {
    padding-bottom: 60px;
    h3{
      span{
        color: #6EAFC4;
      }
    }
  }

  .about-section__bottom {
    //margin-top: -50px;
    position: relative;


    @media (max-width: 767px) {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }

    //&__single:not(:last-child) {
    //  margin-right: 30px !important;
    //}

    &__single {
      max-width: 100%;
      padding: 0 15px !important;

      &__inner {
        position: relative;
        padding-top: calc(450 / 370 * 100%);
        overflow: hidden;
        //border-right: 1px solid #FFF;

        &:after {
          content: '';
          background-color: #262625;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          height: 100%;
          width: 100%;
          transition: .7s cubic-bezier(0.4, 0, 0, 1);
          opacity: 30%;
          //mix-blend-mode: multiply;
        }

        a {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0px;
          z-index: 5;
        }

        &__img {
            &:after {
              content: '';
              position: absolute;
              height: 100%;
              width: 100%;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background: linear-gradient(to top, rgba(0, 0, 0, 0.6) 0%, transparent 100%);
              mix-blend-mode: multiply;
            }

        }

        p {
          position: absolute;
          left: 30px;
          right: 30px;
          bottom: 50px;
          color: #ffffff;
          font-size: 14px;
          line-height: 20px;
          font-weight: 400;
          transform: translateY(100%);
          transition: all .5s cubic-bezier(0.4, 0, 0, 1);
          opacity: 0;
        }

        .img-top {
          position: absolute;
          top: 40px;
          left: 30px;
          z-index: 2;
          opacity: 1;
          transition: .3s cubic-bezier(0.4, 0, 0, 1);
          height: 60px;
        }

        .circle {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          height: 50px;
          width: 50px;
          border: 1px solid #fff;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 2;
          opacity: 1;
          transition: .3s cubic-bezier(0.4, 0, 0, 1);

          svg {
            color: #ffffff;
            font-size: 25px;
            transition: .3s cubic-bezier(0.4, 0, 0, 1);
          }
        }

        h5 {
          position: absolute;
          bottom: 30px;
          left: 30px;
          right: 30px;
          font-size: 18px;
          line-height: 24px;
          font-weight: 400;
          color: #F9F9F9;
          z-index: 2;
          transition: all .5s cubic-bezier(0.4, 0, 0, 1);

        }

        .icon {
          position: absolute;
          top: 30px;
          right: 30px;
          z-index: 1;
          transition: all .8s cubic-bezier(0.4, 0, 0, 1);
          transform: rotate(0deg);

          svg {
            li {
              transition: all .8s cubic-bezier(0.4, 0, 0, 1);

            }
          }

        }

        span {
          position: absolute;
          z-index: 1;
          top: 30px;
          left: 30px;
          font-size: 60px;
          font-weight: 500;
          line-height: 60px;
          color: #F9F9F9;
          transition: all .5s cubic-bezier(0.4, 0, 0, 1);

        }

      }

      &:hover {
        .about-section__bottom__single__inner {
          &:after {
            height: 0;
          }

          .icon {
            transform: rotate(45deg);

            svg {
              line {
                stroke: #D80028;
                fill: #D80028;
              }
            }
          }

          span {
            top: -10px;
            opacity: 0;
          }

          h5 {
            transform: translateY(-130px);
            color: #ffffff !important;
          }

          p {
            transform: none;
            opacity: 1;
            color: #ffffff;
          }


          &__img:after {
            opacity: 1;
            transition-delay: 0s;
          }

          .img-top {
            transform: translateY(-20px);
            opacity: 0;
          }

          .circle {
            transform: scale(1.9);
            opacity: 0;

            svg {
              transform: rotate(50deg);
            }
          }

        }

      }
    }

    &__single:not(:last-child) {
      @media (max-width: 991px) {
        margin-bottom: 30px;
      }
    }
  }


  @media (max-width: 991px) {
    .about-section__bottom__single {
      padding: 0 !important;

      &__inner {
        &:after {
          opacity: 0 !important;
        }

        h5 {
          transform: translateY(-130px) !important;
        }

        p {
          transform: translateY(0) !important;
          opacity: 1 !important;
        }
      }
    }
  }
  @media (max-width: 767px) {
    .about-section__bottom__single {
      padding: 25px !important;
    }
  }

`;

export default ExperienceSlider;