import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {fetchMediaPosts} from "../../api/redux/mediacenter";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import styled from "styled-components";
import InnerBanner from "../../components/InnerBanner";
import {Helmet, HelmetProvider} from "react-helmet-async";
import MediaCenterListing from "../../components/MediaCenter/MediaCenterListing";
import {ApiParam as ApiParamKey} from "../../api/network/apiParams";
import {fetchMediaChild} from "../../api/redux/mediacenter";


const MediaCenter = () => {

    const dispath = useDispatch()
    const getData = useSelector(state=> state.mediaReducer)

    // api call
    useEffect(() => {
        let apiUrl = apiEndPoints.MEDIA
        let apiUrlSection = apiEndPoints.SECTIONS

        let api_params = {
            [ApiParamKey.page_id]: 11,
            [ApiParamKey.sections]: 'yes',
            [ApiParamKey.image]: 'yes',
            [ApiParamKey.post]: 'yes',
            [ApiParamKey.file]: 'no',
        }
        let api_params_sections = {
            [ApiParamKey.type]: 'slug',
            [ApiParamKey.value]: 'media-center',
            [ApiParamKey.get_section]: 'yes',
            [ApiParamKey.image]: 'yes',
            [ApiParamKey.post]: 'yes',
            [ApiParamKey.file]: 'no',
        }

        dispath(fetchMediaChild([apiUrl, api_params]))
        dispath(fetchMediaPosts([apiUrlSection, api_params_sections]))
    }, [])

    // filter data
    const Banner = getData?.posts?.data;
    const MediaData = getData?.child;
    const page_data = getData?.posts?.data?.page_data;

    return (
        <HelmetProvider>
            <Helmet>
                <title>{`${page_data?.title ? page_data?.title + ' | ' : ''}`}Pacific Blue</title>

                {
                    page_data?.meta_title &&
                    <meta name="meta:title" content={page_data?.meta_title}/>

                }
                {
                    page_data?.meta_description &&
                    <meta name="description" content={page_data?.meta_description}/>

                }
                {
                    page_data?.og_title &&
                    <meta property="og:title" content={page_data?.og_title}/>

                }
                {
                    page_data?.og_description &&
                    <meta property="og:description" content={page_data?.og_description}/>

                }
            </Helmet>
            <StyledSection>
                {
                    Banner &&
                    <InnerBanner text={Banner?.page_data?.title} img={Banner?.page_images?.list[0]?.full_path}/>
                }
                {
                    MediaData &&
                <MediaCenterListing data={MediaData}/>
                }
            </StyledSection>
        </HelmetProvider>
    );
};

const StyledSection = styled.section`
`;

export default MediaCenter;
