import React from 'react';
import styled from "styled-components";

import {Col, Container, Row} from "react-bootstrap";
import Title from "./Title";
import reactHtmlParser from "react-html-parser";
import VisibilitySensor from "react-visibility-sensor";
import {ImgInnerBanner} from "./ImgInnerBanner";

const InnerBanner = ({img, text,sectionReveal, title}) => {

    return (
        <StyledInnerBanner className='InnerBanner'>
                <ImgInnerBanner speed={15} banner={true} src={img}/>
            <Container>
              <Row>
                  <Col md={{offset: 5, span: 7}}>
                      <h1 className={`anim-active fade-up`}>{reactHtmlParser(text)}</h1>
                  </Col>
              </Row>
            </Container>
        </StyledInnerBanner>
    );
};

const StyledInnerBanner = styled.section`
  padding-top: calc(500 / 1366 * 100%);
  position: relative;
  background-color: #DDD;
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    background-color: rgba(0, 0, 0, 0.25);
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }

  .container {
    position: absolute;
    //height: 100%;
    //top: 0;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 60px;
    z-index: 2;
  }
  h1 {
    color: #FFFFFF;
    font-size: 40px;
    font-weight: 500;
    line-height: 48px;
  } 
  @media (min-width: 767px) {
    .title {
      width: 50%;
    }
  }

  @media (max-width: 767px) {
    //padding-top: 0;
    padding-top: calc(560 / 414 * 100%);
    .container {
      bottom: 56px;
    }
    h1 {
      text-align: left;
    }

    .title {
      margin-bottom: 40px !important;
    }
  }
`;

export default InnerBanner;
